import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PlanningComponent } from 'projects/speaker-platform/src/app/organisms/planning/planning.component';
import { AtsappService } from '@common/services/atsapp.service';
import { UserService } from '@common/services/user.service';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-speaker-planning',
  standalone: true,
  imports: [CommonModule, PlanningComponent],
  templateUrl: './speaker-planning.component.html',
  styleUrls: ['./speaker-planning.component.scss'],
})
export class SpeakerPlanningComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private atsappService: AtsappService,
    private userService: UserService,
    private toast: ToastService
  ) {}

  destroy$: Subject<boolean> = new Subject<boolean>();
  speakerId: number = 0;
  isAdmin: boolean = false;
  currentSpeaker: any = {};
  userDataLoaded: boolean = false;

  ngOnInit(): void {

    const speakerIdFromURL = this.activatedRoute.snapshot.paramMap.get('id');
    if (speakerIdFromURL || !this.userService.user) {
      this.speakerId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
      this.isAdmin = true;

      this.atsappService
        .getSpeakers(false)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (speakers: any) =>
            (this.currentSpeaker = speakers.filter(
              (spk: any) => spk.id === this.speakerId
            )[0])
        );
        this.initSpeakerDataFetch();
      return;
    }

    this.userService.userDataLoaded
      .pipe(takeUntil(this.destroy$))
      .subscribe((loaded: boolean) => {
        if (loaded) {
          this.speakerId = this.userService.userData.speakerId;
          this.initSpeakerDataFetch();
        }
      });

          if (this.userService.userData?.speakerId !== -1) {
            this.userDataLoaded = true;
            this.speakerId = this.userService.userData?.speakerId;
            this.initSpeakerDataFetch();
          }
  }

  initSpeakerDataFetch() {
    this.userDataLoaded = true;
    this.atsappService
      .getSpeakerById(this.speakerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => (this.currentSpeaker = res),
        error: (error) =>
          this.toast.show(
            'Le chargement de vos informations à échoué pour la raison suivante: ' +
              error,
            'danger'
          ),
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  goToGeneralPlanning() {
    this.router.navigate(['/admin/planning']);
  }
}
