import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractSeancesComponent } from 'projects/speaker-platform/src/app/common/abstract/abstract-seances.component';
import { AtsappService } from '@common/services/atsapp.service';
import { SeancesListComponent } from 'projects/speaker-platform/src/app/organisms/seances-list/seances-list.component';
import { Seance } from 'projects/speaker-platform/src/app/types/seance';
import { FileDropComponent } from 'projects/speaker-platform/src/app/molecules/file-drop/file-drop.component';
import { takeUntil } from 'rxjs';
import { PriseVoix } from 'projects/speaker-platform/src/app/types/prisesVoix';
import { DialogComponent } from 'projects/speaker-platform/src/app/molecules/dialog/dialog.component';
import { PostStatesComponent } from 'projects/speaker-platform/src/app/molecules/post-states/post-states.component';
import { UserService } from '@common/services/user.service';
import { LoaderComponent } from '@common/components/loader/loader.component';
import {
  NgbCollapseModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ChoixCod } from '../../types/tablettes';
import { CcLibelleService } from '@common/services/helpers/cc-libelle-service.service';
import { TruncatePipe } from '../../pipes/truncate.pipe';

@Component({
  selector: 'app-seances-upload',
  standalone: true,
  imports: [
    CommonModule,
    SeancesListComponent,
    FileDropComponent,
    DialogComponent,
    PostStatesComponent,
    LoaderComponent,
    NgbCollapseModule,
    TruncatePipe,
    NgbTooltipModule,
  ],
  templateUrl: './seances-upload.component.html',
  styleUrls: ['./seances-upload.component.scss'],
})
export class SeancesUploadComponent
  extends AbstractSeancesComponent
  implements OnInit, OnDestroy
{
  constructor(
    atsappService: AtsappService,
    protected userService: UserService,
    protected ccLibelleService: CcLibelleService
  ) {
    super(atsappService);
  }

  selectedSeance: Seance | undefined;
  seanceToPost!: any;
  seanceFile: any;

  seancePVs: PriseVoix[] = [];
  seancePartialPVs: PriseVoix[] = [];

  standbyPvs: number = 0;

  uploadDialiogVisible: boolean = false;

  isSeanceLoading: boolean = false;

  phasesVocales!: ChoixCod[];

  uploadTotalSize: number = 0;
  uploadCurrentSizeUploaded: number = 0;

  isPosting: boolean = false;
  isPostingOver: boolean = false;
  postError: { errorCode: number; message: string } | null = null;

  isPartialDeliveryCollapsed: boolean = true;

  ngOnInit(): void {
    this.atsappService
      .getChoixCod('PHV')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.phasesVocales = res;
        },
      });

    this.userService.userDataLoaded.subscribe((loaded: boolean) => {
      loaded && this.initSeancesUpload();
      return;
    });

    if (this.userService.user.speakerId !== -1) {
      this.initSeancesUpload();
    }
  }

  initSeancesUpload() {
    this.selectedSeance = undefined;
    if (!this.userService.user) {
      return;
    }
    if (
      !this.userService.user.isAdmin ||
      this.userService.userRoles.has('ROLE_COMEDIENNE_INTERNE')
    ) {
      this.getOngoingSeancesBySpeaker(this.userService.user.speakerId);
    } else {
      console.error('Aucun speakerId spécifié');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onSelectSeance(seance: Seance): void {
    this.selectedSeance = seance;
    this.isSeanceLoading = true;
    this.standbyPvs = 0;
    this.atsappService
      .getPvsBySeance(seance.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.seancePVs = res.seancePVs.filter((pv: PriseVoix) => {
            if (pv.ZMS_STDBYPV1 === 'X') {
              this.standbyPvs += 1;
            }
            return (
              (!pv.ZMS_PVDATERETOUR1 ||
                pv.ZMS_PVDATERETOUR1 === '' ||
                new Date(pv.ZMS_PVDATERETOUR1).getFullYear() === 1900) &&
              pv.ZMS_STDBYPV1 !== 'X'
            );
          });
          // Deep copy without references
          this.seancePartialPVs = [...this.seancePVs];
        },
        error: (error) => {
          this.isSeanceLoading = false;
        },
        complete: () => {
          this.isSeanceLoading = false;

          this.seancePartialPVs.forEach((pv: PriseVoix) => {
            if (pv.ZMS_STDBYPV1 === 'X') {
              this.addOrRemovePvs(false, pv.ZMS_PVCODE1, pv);
            }
          });
          this.buildPostObject(this.seancePartialPVs);
        },
      });
  }

  openUploadDialog() {
    this.postError = null;
    this.isPosting = false;
    this.isPostingOver = false;
    this.uploadDialiogVisible = true;
  }

  getSeanceFile(event: any) {
    this.seanceFile = event.target.files[0];
  }

  addOrRemovePvs(add: boolean, reference: string, priseVoix: PriseVoix) {
    const index = this.seancePartialPVs.findIndex(
      (pv: PriseVoix) => pv.ZMS_PVCODE1 === reference
    );
    if (!add) {
      this.seancePartialPVs.splice(index, 1);
    } else {
      this.seancePartialPVs.push(priseVoix);
    }

    this.buildPostObject(this.seancePartialPVs);
  }

  addOrRemoveAllPvs(add: boolean) {
    this.seancePVs.forEach((pv: PriseVoix) => {
      if ((this.pvChecked(pv.ZMS_PVCODE1) && add) || pv.ZMS_STDBYPV1 === 'X') {
        return;
      }
      this.addOrRemovePvs(add, pv.ZMS_PVCODE1, pv);
    });
  }

  pvChecked(code: string) {
    return (
      this.seancePartialPVs.findIndex(
        (pv: PriseVoix) => pv.ZMS_PVCODE1 === code
      ) !== -1 && true
    );
  }

  buildPostObject(prisesVoix: PriseVoix[]) {
    if (!this.userService.user) {
      return;
    }
    const pvArray = [];
    for (let pv of prisesVoix) {
      pvArray.push({
        RANGPV: pv.RANGPV,
        ZMS_NUMERO: pv.ZMS_NUMERO,
        ZMS_LIVRAISON: pv.ZMS_LIVRAISON,
        ZMS_MESSAGE: pv.ZMS_MESSAGE,
        ZMS_DATEPVDIST1: pv.ZMS_DATEPVDIST1,
      });
    }

    const body = {
      CODE_COMEDIENNE: this.userService.user.codespeak,
      PRISES_VOIX: [...pvArray],
      // file trigramme + increment
    };
    this.seanceToPost = body;
  }

  generatePV() {
    this.uploadTotalSize = 0;
    this.uploadCurrentSizeUploaded = 0;
    this.isPosting = true;
    const formData = new FormData();
    formData.append('data', JSON.stringify(this.seanceToPost));
    formData.append('file', this.seanceFile as File);
    this.atsappService
      .generatePV(formData, this.selectedSeance && this.selectedSeance.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (event) => {
          if (event?.type === 1 && event.total) {
            this.uploadCurrentSizeUploaded = (event.loaded * 100) / event.total;
          }
        },
        error: (error) => {
          this.playErrorSound();
          this.postError = {
            errorCode: error.error.code,
            message: error.error.message,
          };
          this.isPosting = false;
        },
        complete: () => {
          this.postError = null;
          this.isPostingOver = true;
          this.isPosting = false;
          this.playSuccessSound();
          this.initSeancesUpload();
        },
      });
  }
}
