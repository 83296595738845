import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeeklyPlanningPreviewComponent } from 'projects/speaker-platform/src/app/molecules/weekly-planning-preview/weekly-planning-preview.component';
import { AtsappService } from '@common/services/atsapp.service';
import { UserService } from '@common/services/user.service';
import {
  areIntervalsOverlapping,
  eachDayOfInterval,
  endOfWeek,
  format,
  isSameMonth,
  startOfWeek,
} from 'date-fns';
import { PlanningEvent } from '../../types/planning-event';
import { Seance } from '../../types/seance';
import { Router } from '@angular/router';
import { SeanceStatuses } from '../../types/seanceStatuses.enum';
import { PriseVoix } from '../../types/prisesVoix';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, WeeklyPlanningPreviewComponent],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private atsappService: AtsappService,
    protected userService: UserService,
    private router: Router
  ) {}

  weekStartDate: Date = new Date();
  weekEndDate: Date = new Date();
  weekInterval!: Interval;
  weekStarsAndEndsInSameMonth: boolean = false;
  eventsInWeek: PlanningEvent[] = [];
  ongoingSeances: Seance[] = [];

  seanceStatuses = SeanceStatuses;

  fetchEventsComplete: boolean = false;
  format = format;
  eachDayOfInterval = eachDayOfInterval;

  ngOnInit(): void {
    this.weekStartDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    this.weekEndDate = endOfWeek(new Date(), { weekStartsOn: 1 });
    this.weekStarsAndEndsInSameMonth = isSameMonth(
      this.weekStartDate,
      this.weekEndDate
    );
    this.weekInterval = { start: this.weekStartDate, end: this.weekEndDate };
    if (this.userService.userData) {
      if (this.userService.userData.isAdmin) {
        this.router.navigate(['/admin/dashboard']);
        return;
      }

      this.userService.userDataLoaded.subscribe((loaded: boolean) => {
        this.initDashboard();
        return;
      });

      if (this.userService.userData.speakerId !== -1) {
        this.initDashboard();
      }
    }
  }

  initDashboard() {
    this.atsappService
      .getPlanningEventsByComedienne(this.userService.userData.speakerId)
      .subscribe({
        next: (res: any) => {
          res.forEach((event: PlanningEvent) => {
            const eventInterval: Interval = {
              start: new Date(event.date_debut).setHours(0, 0),
              end: new Date(event.date_fin).setHours(0, 0),
            };
            areIntervalsOverlapping(eventInterval, this.weekInterval, {
              inclusive: true,
            }) && this.eventsInWeek.push(event);
          });
        },
        complete: () => (this.fetchEventsComplete = true),
      });

    this.atsappService
      .getSeanceBySpeaker(this.userService.userData.speakerId)
      .subscribe({
        next: (res: any) => {
          for (let seance of res) {
            if (seance.statut === this.seanceStatuses.SENT && seance.mode === 'VOCAL') {
              this.getStandbyCount(seance);
            }
          }
        },
      });
  }

  getStandbyCount(seance: Seance) {
    const _seance = seance;
    this.atsappService.getPvsBySeance(seance.id).subscribe({
      next: (res: any) => {
        _seance._stdby = res.seancePVs.filter(
          (s: PriseVoix) => s.ZMS_STDBYPV1 === 'X'
        ).length;
        this.ongoingSeances.push(seance);
        this.ongoingSeances.sort((a, b) => a.id - b.id);
      },
    });
  }
}
