<div class="createplanningevent" [formGroup]="this.planningEventForm">
  <app-dialog
    #postDialog
    [title]="this.title"
    [visibility]="this.planningEventDialogVisible"
    (closeDialogEvent)="this.closePlanningEventDialog()"
  >
    <div class="container" *ngIf="this.userService.userData">
      <div class="row align-items-center">
        <div class="col-6">
          <div class="datepicker form-floating">
            <input
              *ngIf="this.userService.userData.isAdmin else adminFromDate"
              type="text"
              class="form-control"
              placeholder=""
              ngbDatepicker
              #d="ngbDatepicker"
              formControlName="planningEventFromDate"
              (dateSelect)="this.identifyOverlaps()"
              (click)="d.toggle()"
            />
            <ng-template #adminFromDate>
              <input
                type="text"
                class="form-control"
                placeholder=""
                ngbDatepicker
                #d="ngbDatepicker"
                formControlName="planningEventFromDate"
                (dateSelect)="this.identifyOverlaps()"
                (click)="d.toggle()"
                [minDate]="this.today"
              />
            </ng-template>
            <label for="planning-event-from-date" class="form-label">
              {{
                this.reason === this.planningEventReasons.LEAVE ||
                this.reason === this.planningEventReasons.SICK_LEAVE ||
                this.reason === this.planningEventReasons.NO_SEANCE ||
                this.reason === this.planningEventReasons.SEANCE
                  ? "Du"
                  : "Le"
              }}
            </label>
          </div>
        </div>
        <div class="col-6 d-flex justify-content-center">
          <div
            style="flex: 1"
            *ngIf="
              this.reason === this.planningEventReasons.LEAVE ||
              this.reason === this.planningEventReasons.SICK_LEAVE ||
              this.reason === this.planningEventReasons.NO_SEANCE ||
              this.reason === this.planningEventReasons.SEANCE
            "
          >
            <div
              class="datepicker form-floating"
              *ngIf="this.planningEventFromDate !== null"
            >
              <input
                type="text"
                class="form-control"
                placeholder=""
                ngbDatepicker
                #e="ngbDatepicker"
                formControlName="planningEventToDate"
                (click)="e.toggle()"
                (dateSelect)="this.identifyOverlaps()"
                [minDate]="
                  this.planningEventFromDate.value !== null
                    ? this.planningEventFromDate.value
                    : this.selectedDay
                "
              />
              <label for="planning-event-to-date" class="form-label">Au</label>
            </div>
          </div>
          <div *ngIf="this.reason === this.planningEventReasons.ON_SITE">
            <ngb-timepicker formControlName="planningEventHour" />
          </div>
        </div>
      </div>
    </div>
    <div dialog-footer>
      <div class="d-flex justify-content-between align-items-center px-3">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="emailNotify"
            formControlName="emailNotify"
          />
          <label class="form-check-label" for="emailNotify"
            >Notifier par e-mail</label
          >
        </div>

        <div>
          <button
            class="btn btn-danger me-2"
            (click)="
              this.postDialog.closeDialog(); this.closePlanningEventDialog()
            "
          >
            Annuler
          </button>
          <button
            class="btn btn-success"
            (click)="this.createPlanningEvent()"
            [disabled]="this.isLoading || this.hasInconsistentDate"
          >
            <span class="d-flex align-items-center">
              Valider
              <app-loader color="white" class="ms-3" *ngIf="this.isLoading" />
            </span>
          </button>
        </div>
      </div>
    </div>
  </app-dialog>
</div>
