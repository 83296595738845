import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-card-with-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card-with-header.component.html',
  styleUrls: ['./card-with-header.component.scss']
})
export class CardWithHeaderComponent {
  @Input() title: string = ''
  @Input() color: string = ''
  @Input() headerOnly: boolean = false;
}
