<div class="speakerplanning container page">
  <div
    *ngIf="this.isAdmin"
    class="mb-4 d-flex align-items-center justify-content-between"
  >
    <button class="btn" (click)="this.goToGeneralPlanning()">
      <i class="fa-solid fa-chevron-left me-4"></i> Retour au planning général
    </button>
    <div>
      <h5>
        Planning de {{ this.currentSpeaker.prenom }}
        {{ this.currentSpeaker.nom }}
      </h5>
    </div>
  </div>
  <!-- <div *ngIf="this.currentSpeaker.disponibilite"> -->
  <app-planning
    *ngIf="this.userDataLoaded"
    [speakerId]="this.speakerId"
    [speakerDefaultSeanceDays]="
      this.currentSpeaker.disponibilite
        ? this.currentSpeaker.disponibilite.split(',')
        : []
    "
  />
  <!-- </div> -->
</div>
