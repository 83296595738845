import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function availabilityValidator(checkList: any[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const isAvailable = !checkList.includes(value);

    return !isAvailable ? { availability: true } : null;
  };
}
