import { Subject, takeUntil } from 'rxjs';
import { AtsappService } from '@common/services/atsapp.service';
import { DateTransformService } from 'projects/speaker-platform/src/app/services/helpers/date-transform.service';
import { FiltersProps } from 'projects/speaker-platform/src/app/types/filters';
import { Speaker } from 'projects/speaker-platform/src/app/types/speaker';
import { ChoixCod } from 'projects/speaker-platform/src/app/types/tablettes';
import { CcLibelleService } from '../../services/helpers/cc-libelle-service.service';

export abstract class AbstractTextComponent {
  constructor(
    protected atsappService: AtsappService,
    private dateTransformService: DateTransformService,
    protected ccLibelleService: CcLibelleService
  ) {}

  phasesVocalesList: ChoixCod[] = [];
  typesMessageList: ChoixCod[] = [];
  languesList: ChoixCod[] = [];
  standbyTypes: ChoixCod[] = [];
  speakersList: Speaker[] = [];
  textsToPatchIdList: string[] = [];
  textsToPatchList: any[] = [];
  isLoading: boolean = false;

  successSound: HTMLAudioElement = new Audio('assets/success.mp3');
  errorSound: HTMLAudioElement = new Audio('assets/error.mp3');

  destroy$: Subject<boolean> = new Subject<boolean>();

  playSuccessSound() {
    this.successSound.play();
  }

  playErrorSound() {
    this.errorSound.play();
  }

  getPhasesVocale(): void {
    this.atsappService
      .getChoixCod('PHV')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.phasesVocalesList = res;
      });
  }

  getTypesMessage(): void {
    this.atsappService
      .getChoixCod('TMS')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.typesMessageList = res;
      });
  }

  getLangues(): void {
    this.atsappService
      .getChoixCod('LGU')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        // Français / Anglais / Allemand / Italien / Espagnol en premier dans la liste
        const firsts = ['FRF', 'ANG', 'ALL', 'ITA', 'ESP'];

        firsts.forEach((code: string) => {
          const index = res.findIndex((cc: ChoixCod) => cc.cc_code === code);
          this.languesList.push(res[index]);
        });

        // Ajouter le reste des langues non listées
        this.languesList.push(
          ...res.filter((o: ChoixCod) => !firsts.includes(o.cc_code))
        );
      });
  }

  getSpeakers() {
    this.atsappService
      .getSpeakers(false)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.speakersList = res;
      });
  }

  getTranslator() {
    this.atsappService
      .getSpeakers(false)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.speakersList = res.filter((spk: Speaker) =>  spk.traductrice);
      });
  }

  getStandbyTypes() {
    this.atsappService.getChoixCod('ZBY').subscribe({
      next: (res: any) => {
        this.standbyTypes = res.filter(
          (zby: ChoixCod) => zby.cc_abrege === 'PRISEVOIX'
        );
      },
    });
  }

  itemShouldBeChecked(id: string): boolean {
    return this.textsToPatchIdList.indexOf(id) > -1 ? true : false;
  }

  getCodes(ccLibelle: string, ccList: ChoixCod[]): string {
    return this.ccLibelleService.getCcCode(ccLibelle, ccList);
  }

  getLibelles(ccCode: string, ccList: ChoixCod[]) {
    return this.ccLibelleService.getCcLibelle(ccCode, ccList);
  }

  addToSendList(
    checked: boolean,
    value: string,
    smartSelectEnabled: boolean,
    prisesVoix: any,
    keys: string[] = []
  ): void {
    if (smartSelectEnabled) {
      if (checked) {
        const data = value.split('-');
        const textsToAdd = prisesVoix.filter(
          (pv: any) => pv.ZMS_NUMERO === data[0] && pv.CLIENT_FINAL === data[4]
        );
        textsToAdd.forEach((pv: any) => {
          const value =
            pv.ZMS_NUMERO +
            '-' +
            pv.ZMS_LIVRAISON +
            '-' +
            pv.ZMS_MESSAGE +
            '-' +
            pv.RANGPV +
            '-' +
            pv.CLIENT_FINAL;
          if (!this.textsToPatchIdList.includes(value)) {
            this.textsToPatchIdList.push(value);
          }
          this.buildPostBody(value, true, prisesVoix, keys);
        });
      } else {
        const index = this.textsToPatchIdList.indexOf(value);
        if (index > -1) {
          this.textsToPatchIdList.splice(index, 1);
          this.buildPostBody(value, false, prisesVoix, keys);
        }
      }

      return;
    }

    if (checked) {
      if (!this.textsToPatchIdList.includes(value)) {
        this.textsToPatchIdList.push(value);
      }
      this.buildPostBody(value, true, prisesVoix, keys);
    } else {
      const index = this.textsToPatchIdList.indexOf(value);
      if (index > -1) {
        this.textsToPatchIdList.splice(index, 1);
        this.buildPostBody(value, false, prisesVoix, keys);
      }
    }
  }

  buildPostBody(
    value: string,
    shouldAdd: boolean,
    prisesVoix: any,
    keys: string[]
  ): void {
    let searchParams = value.split('-');
    prisesVoix.filter((pv: any) => {
      if (
        pv.ZMS_NUMERO === searchParams[0] &&
        pv.ZMS_LIVRAISON === searchParams[1] &&
        pv.ZMS_MESSAGE === searchParams[2] &&
        pv.RANGPV === searchParams[3]
      ) {
        if (shouldAdd) {
          const objectToPush = {};
          keys.forEach((key: string) => {
            Object.defineProperties(objectToPush, {
              [key]: {
                value: pv[key],
                enumerable: true,
                writable: true,
              },
            });
          });
          this.textsToPatchList.push(objectToPush);
        } else {
          const index: number = this.textsToPatchList.findIndex((pv: any) => {
            return (
              pv.ZMS_NUMERO === searchParams[0] &&
              pv.ZMS_LIVRAISON === searchParams[1] &&
              pv.ZMS_MESSAGE === searchParams[2] &&
              pv.RANGPV === searchParams[3]
            );
          });
          this.textsToPatchList.splice(index, 1);
        }
      }
    });
  }

  selectAllTexts(checked: boolean, prisesVoix: any[], keys: string[]): void {
    prisesVoix.forEach((pv: any) => {
      this.addToSendList(
        checked,
        pv.ZMS_NUMERO +
          '-' +
          pv.ZMS_LIVRAISON +
          '-' +
          pv.ZMS_MESSAGE +
          '-' +
          pv.RANGPV +
          '-' +
          pv.CLIENT_FINAL,
        false,
        prisesVoix,
        keys
      );
    });
  }

  // updateFilters(
  //   payload: { filter: string; value: any },
  //   filters: FiltersProps[]
  // ) {
  //   for (const f of filters) {
  //     if (payload.filter === f.filter) {
  //       f.value =
  //         payload.filter === 'ZMS_DATELIVRAISON'
  //           ? this.dateTransformService.transformDate(payload.value)
  //           : payload.value;
  //     }
  //   }
  //   this.resetTextsToPatchList();
  // }

  // resetFilters(filters: FiltersProps[], callback: any) {
  //   filters.forEach((filter: FiltersProps) => {
  //     filter.value = '';
  //   });

  //   this.resetTextsToPatchList();
  //   callback;
  // }

  resetTextsToPatchList() {
    this.textsToPatchIdList = [];
    this.textsToPatchList = [];
  }

  colorFromTiers(tiers: number) {
    tiers >>>= 0;
    const b = tiers & 0xff,
      g = (tiers & 0xff00) >>> 8,
      r = (tiers & 0xff0000) >>> 16,
      a = ((tiers & 0xff000000) >>> 24) / 255;
    return 'rgba(' + [r, g, b, a].join(',') + ')';
  }
}
