<div class="loader">
  <span
    [style]="this.color ? 'background-color:' + this.color : ''"
    class="line line-1"
  ></span>
  <span
    [style]="this.color ? 'background-color:' + this.color : ''"
    class="line line-2"
  ></span>
  <span
    [style]="this.color ? 'background-color:' + this.color : ''"
    class="line line-3"
  ></span>
  <span
    [style]="this.color ? 'background-color:' + this.color : ''"
    class="line line-4"
  ></span>
  <span
    [style]="this.color ? 'background-color:' + this.color : ''"
    class="line line-5"
  ></span>
  <span
    [style]="this.color ? 'background-color:' + this.color : ''"
    class="line line-6"
  ></span>
  <span
    [style]="this.color ? 'background-color:' + this.color : ''"
    class="line line-7"
  ></span>
  <span
    [style]="this.color ? 'background-color:' + this.color : ''"
    class="line line-8"
  ></span>
  <span
    [style]="this.color ? 'background-color:' + this.color : ''"
    class="line line-9"
  ></span>
</div>
