import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Seance } from 'projects/speaker-platform/src/app/types/seance';

@Component({
  selector: 'app-seances-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './seances-list.component.html',
  styleUrls: ['./seances-list.component.scss'],
})
export class SeancesListComponent implements OnChanges {
  @Input() seances: Seance[] = [];
  @Input() selectedSeance!: number | undefined;
  @Output() selectSeanceEvent = new EventEmitter<Seance>();

  seancesVocal: Seance[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['seances']) {
      this.seancesVocal = this.seances.filter(
        (seance: Seance) => seance.mode === 'VOCAL'
      );
    }
  }

  onSelectSeance(seance: Seance): void {
    this.selectSeanceEvent.emit(seance);
  }
}
