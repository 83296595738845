<div class="globalplanning container page">
  <div class="row">
    <div class="col-3">
      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <button class="btn" (click)="this.getWeek(-1)">
            <i class="fa-solid fa-chevron-left"></i>
          </button>
          <div class="mx-3">Semaine {{ this.currentWeek }}</div>
          <button class="btn" (click)="this.getWeek(1)">
            <i class="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              placeholder=""
              [(ngModel)]="this.filterSpeakersListQuery"
              (keyup)="this.findSpeaker(); "
            />
            <label class="form-label" for="">Recherche par nom / prénom</label>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <div class="form-floating">
            <select
              [(ngModel)]="this.filterSpeakerLang"
              class="form-select"
              name=""
              id=""
              (change)="this.findSpeaker();"
            >
              <option value="">Choix</option>
              <option *ngFor="let langue of this.lgu" [value]="langue.cc_code">
                {{ langue.cc_libelle }}
              </option>
            </select>
            <label class="form-label" for="">Langue</label>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3">
          <div class="form-check">
            <input
              (change)="this.findSpeaker();"
              [(ngModel)]="this.filterSpeakerListGenre"
              type="radio"
              class="form-check-input"
              name="genre-filter-input"
              id="genre-filter-input-all"
              value=""
              checked
            />
            <label for="genre-filter-input-all">Tous</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-check">
            <input
              (change)="this.findSpeaker();"
              [(ngModel)]="this.filterSpeakerListGenre"
              type="radio"
              class="form-check-input"
              name="genre-filter-input"
              id="genre-filter-input-fem"
              value="002"
            />
            <label for="genre-filter-input-fem">Féminin</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-check">
            <input
              (change)="this.findSpeaker();"
              [(ngModel)]="this.filterSpeakerListGenre"
              type="radio"
              class="form-check-input"
              name="genre-filter-input"
              id="genre-filter-input-masc"
              value="001"
            />
            <label for="genre-filter-input-masc">Masculin</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 position-relative">
          <div
            class="globalplanning__speakerslist"
            [style]="{ height: this.speakersListHeight + 'px' }"
          >
            <app-speakers-list
              [speakersList]="this.filteredSpeakersList"
              (selectEvent)="this.goToSpeakerPlanning($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-9">
      <div class="row">
        <div class="col-12">
          <div #planningWeek class="globalplanning__week">
            <div *ngIf="planningLoaded" >
              <div class="globalplanning__week__day" *ngFor="let day of this.daysInWeek">
                <div class="globalplanning__week__day__header">
                  {{ format(day, "dd MMMM yyyy") }}
                </div>
                <div class="globalplanning__week__day__details">
                  <div class="globalplanning__week__day__details__item">
                    <h6 class="text-center mb-3">En séance</h6>
                    <div class="text-center mb-3"></div>
                    <ul>
                      <div class="subLegend">Disponible :</div>
                      <ng-container
                        *ngFor="let d of this.filteredAvailableSpeakersDefault"
                      >
                        <li
                          *ngIf="
                          format(day, 'EE', { locale: localeEnUs }) === d.day
                        "
                        >
                          {{ d.speakerLabel }}
                        </li>
                      </ng-container>

                      <div class="subLegend mt-2">Mise en disponibilité :</div>
                      <ng-container *ngFor="let planningEvent of this.filteredPlanningEvents">
                        <li
                          class="d-flex align-items-center justify-content-between"
                          *ngIf="
                          this.hasPlanningEventToday(planningEvent, day) &&
                          planningEvent.raison ===
                            this.planningEventReasons.SEANCE
                        "
                        >
                          {{ planningEvent.comedienne_id.prenom }}
                          {{ planningEvent.comedienne_id.nom }}
                          {{ planningEvent.comedienne_id.langue2 !== "" && planningEvent.comedienne_id.langue2 !== " "
                        && planningEvent.comedienne_id.langue2 !== null ? "(" + planningEvent.comedienne_id.langue1 + " / " +planningEvent.comedienne_id.langue2 + ")": "(" + planningEvent.comedienne_id.langue1 + ")"}}
                        </li>
                      </ng-container>

                      <div class="subLegend mt-2">Séance :</div>
                      <ng-container *ngFor="let planningEvent of this.filteredPlanningEvents">
                        <li
                          class="d-flex align-items-center justify-content-between"
                          *ngIf="this.hasPlanningEventToday(planningEvent, day) &&
                          planningEvent.raison === this.planningEventReasons.ON_SITE">
                        <span>
                          {{ planningEvent.comedienne_id.prenom }}
                          {{ planningEvent.comedienne_id.nom }}
                          {{ planningEvent.comedienne_id.langue2 !== "" && planningEvent.comedienne_id.langue2 !== " "
                        && planningEvent.comedienne_id.langue2 !== null ? "(" + planningEvent.comedienne_id.langue1 + " / " +planningEvent.comedienne_id.langue2 + ")": "(" + planningEvent.comedienne_id.langue1 + ")"}}
                        </span>
                          <span class="badge text-bg-info">
                          <i class="fa-solid fa-building-user"></i>
                            {{
                              format(
                                parseISO(planningEvent.date_debut),
                                "HH'h'mm"
                              )
                            }}
                        </span>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                  <div class="globalplanning__week__day__details__item">
                    <h6 class="text-center mb-3">Congés</h6>
                    <ul>
                      <ng-container
                        *ngFor="let planningEvent of this.filteredPlanningEvents"
                      >
                        <li
                          *ngIf="
                          this.hasPlanningEventToday(planningEvent, day) &&
                          planningEvent.raison ===
                            this.planningEventReasons.LEAVE
                        "
                        >
                          {{ planningEvent.comedienne_id.prenom }}
                          {{ planningEvent.comedienne_id.nom }}
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                  <div class="globalplanning__week__day__details__item">
                    <h6 class="text-center mb-3">Maladie</h6>
                    <ul>
                      <ng-container
                        *ngFor="let planningEvent of this.filteredPlanningEvents"
                      >
                        <li
                          *ngIf="
                          this.hasPlanningEventToday(planningEvent, day) &&
                          planningEvent.raison ===
                            this.planningEventReasons.SICK_LEAVE
                        "
                        >
                          {{ planningEvent.comedienne_id.prenom }}
                          {{ planningEvent.comedienne_id.nom }}
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!planningLoaded">
              <app-loader></app-loader>
            </div>
          </div>
        </div>
      </div>
    </div >
  </div>
</div>
