import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextListItemComponent } from 'projects/speaker-platform/src/app/molecules/text-list-item/text-list-item.component';
import { TextsTodo } from 'projects/speaker-platform/src/app/types/texts-todo.enum';
import { ChoixCod } from 'projects/speaker-platform/src/app/types/tablettes';

@Component({
  selector: 'app-texts-list',
  standalone: true,
  imports: [CommonModule, TextListItemComponent],
  templateUrl: './texts-list.component.html',
  styleUrls: ['./texts-list.component.scss'],
})
export class TextsListComponent {
  @Input() textsList: any[] = [];
  @Input() todo: TextsTodo = TextsTodo.RECORD;
  @Input() phasesVocales: ChoixCod[] = [];
  @Input() standbyTypes: ChoixCod[] = [];

  @Output() showStandbyDialogEvent = new EventEmitter();
  @Output() showCommentDialogEvent = new EventEmitter();
  @Output() showTranslateDialogEvent = new EventEmitter();

  currentItemShowing: number = -1;

  showDetails(index: number) {
    this.currentItemShowing = index;
  }

  onShowStandbyDialog(pv: any): void {
    this.showStandbyDialogEvent.emit(pv);
  }

  onShowCommentDialog(pv: any): void {
    this.showCommentDialogEvent.emit(pv);
  }

  onShowTranslateDialog(pv: any, translatedText: string): void {
    this.showTranslateDialogEvent.emit({pv, translatedText});
  }
}
