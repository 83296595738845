import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextsListComponent } from 'projects/speaker-platform/src/app/organisms/texts-list/texts-list.component';
import { TextsTodo } from 'projects/speaker-platform/src/app/types/texts-todo.enum';
import { AbstractTextComponent } from 'projects/speaker-platform/src/app/common/abstract/abstract-texts.component';
import { AtsappService } from '@common/services/atsapp.service';
import { DateTransformService } from 'projects/speaker-platform/src/app/services/helpers/date-transform.service';
import { Seance } from 'projects/speaker-platform/src/app/types/seance';
import { DialogComponent } from 'projects/speaker-platform/src/app/molecules/dialog/dialog.component';
import { SeanceStatuses } from 'projects/speaker-platform/src/app/types/seanceStatuses.enum';
import { TranslationStatuses } from 'projects/speaker-platform/src/app/types/translationStatuses.enum';
import { PostStatesComponent } from 'projects/speaker-platform/src/app/molecules/post-states/post-states.component';
import { UserService } from '@common/services/user.service';
import { takeUntil } from 'rxjs';
import { ToastService } from '../../services/toast.service';
import { CcLibelleService } from '../../services/helpers/cc-libelle-service.service';

@Component({
  selector: 'app-texts-to-translate',
  standalone: true,
  imports: [
    CommonModule,
    TextsListComponent,
    DialogComponent,
    PostStatesComponent,
  ],
  templateUrl: './texts-to-translate.component.html',
  styleUrls: ['./texts-to-translate.component.scss'],
})
export class TextsToTranslateComponent
  extends AbstractTextComponent
  implements OnInit, OnDestroy
{
  constructor(
    atsappService: AtsappService,
    dateTransformService: DateTransformService,
    protected userService: UserService,
    private toast: ToastService,
    ccLibelleService: CcLibelleService
  ) {
    super(atsappService, dateTransformService, ccLibelleService);
  }

  textsTodo = TextsTodo;
  assignedTranslations: any;
  seances: Seance[] = [];
  postTranslationBody: any;

  isPosting: boolean = false;
  isPostingOver: boolean = false;
  postError: { errorCode: number; message: string } | null = null;

  translateDialogVisible: boolean = false;

  ngOnInit(): void {
    this.userService.userDataLoaded.subscribe((loaded) => {
      loaded && this.getSeances();
    });
    if (this.userService.user.speakerId !== -1) {
      this.getSeances();
    }
    this.getPhasesVocale();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getSeances() {
    if (!this.userService.user) {
      return;
    }
    this.atsappService
      .getSeanceBySpeaker(this.userService.user.speakerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          const filteredSeances = res.filter(
            (seance: Seance) =>
              seance.mode === 'TRADUCTION' &&
              seance.statut === SeanceStatuses.SENT
          );
          this.seances = filteredSeances;
          if (filteredSeances[0]) {
            this.assignedTranslations = this.getPvsBySeance(
              filteredSeances[0].id
            );
          } else {
            this.assignedTranslations = null;
          }
        },
        error: (error) =>
          this.toast.show(
            'Le chargement des séances à échoué pour la raison suivante: ' +
              error,
            'danger'
          ),
      });
  }

  getPvsBySeance(id: number) {
    this.atsappService
      .getTranslationsBySeance(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) =>
          (this.assignedTranslations = res.seanceTranslations.filter(
            (translation: any) =>
              translation.ETAT_TRADUCTION ===
              TranslationStatuses.PENDING_TRANSLATION
          )),
        error: (error) =>
          this.toast.show(
            'Le chargement des prises voix à échoué pour la raison suivante: ' +
              error.error.message,
            'danger'
          ),
      });
  }

  onShowTranslateDialog(translation: any, translatedText: string) {
    if (!this.userService.user) {
      return;
    }
    this.postError = null;
    this.isPosting = false;
    this.isPostingOver = false;

    this.translateDialogVisible = true;

    this.postTranslationBody = {
      CODE_COMEDIENNE: this.userService.user.codespeak,
      TRANSLATION: {
        RANGPV: translation.RANGPV,
        ZMS_NUMERO: translation.ZMS_NUMERO,
        ZMS_LIVRAISON: translation.ZMS_LIVRAISON,
        ZMS_MESSAGE: translation.ZMS_MESSAGE,
        TRANSLATED_TEXT: translatedText,
      },
    };
  }

  postTranslation() {
    this.atsappService.sendTranslatedText(this.postTranslationBody).subscribe({
      error: (error) => {
        this.playErrorSound();
        this.postError = {
          errorCode: error.error.code,
          message: error.error.message,
        };
        this.isPosting = false;
      },
      complete: () => {
        this.postError = null;
        this.isPostingOver = true;
        this.isPosting = false;
        this.playSuccessSound();
        this.getSeances();
      },
    });
  }
}
