<div class="dashboard container">
  <div class="row">
    <div class="col-7">
      <h2>
        Cette semaine
        <span
          >du
          {{
            this.format(
              this.weekStartDate,
              this.weekStarsAndEndsInSameMonth ? "dd" : "dd MMMM"
            )
          }}
          au {{ this.format(this.weekEndDate, "dd MMMM yyyy") }}</span
        >
      </h2>
      <app-weekly-planning-preview
        *ngIf="this.fetchEventsComplete"
        [week]="this.eachDayOfInterval(this.weekInterval)"
        [planningEvents]="this.eventsInWeek"
      />
    </div>
    <div class="col-5">
      <h2>Séances en cours</h2>
      <div class="dashboard__ongoingseances papercard">
        <div *ngIf="this.ongoingSeances.length === 0">
          Aucune séance reçue pour le moment
        </div>
        <div class="dashboard__ongoingseances__item lh-lg" *ngFor="let seance of this.ongoingSeances">
          Séance {{ seance.id }} - {{ seance.mode === 'TRADUCTION' ? 'Traductions' : 'Prises voix' }} <br />
          Attribuée le
          {{ seance.date_creation | date : "dd/MM/yyyy" }} <br />
          {{ seance.compteur_done }} textes {{ seance.mode === 'TRADUCTION' ? 'traduits' : 'livrés' }} sur
          {{ seance.compteur_todo }}
          <div *ngIf="seance.mode === 'VOCAL' && seance._stdby && seance._stdby > 0">
            <i class="fa-solid fa-triangle-exclamation me-1"></i>
            {{ seance._stdby }} texte(s) en standby
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
