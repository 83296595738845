import {Component, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'projects/speaker-platform/src/app/services/auth.service';
import { LoaderComponent } from '@common/components/loader/loader.component';
import {tokenGetter} from "../../../main";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LoaderComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  constructor(
    protected router: Router,
    private authService: AuthService,
    private fb: FormBuilder,
  ) {}
  invalidLoginOrPassword: boolean = false;
  isLoading: boolean = false;
  isSubmited: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  loginForm = this.fb.group({
    login: ['', Validators.required],
    password: ['', Validators.required],
  });

  ngOnInit(): void {
    const token = tokenGetter()
    if (tokenGetter()) this.router.navigate(['dashboard'])
  }
  onLogin(): void {
    this.isLoading = true;
    this.isSubmited = true;
    if (!this.loginInput?.value || !this.passwordInput?.value) {
      this.isLoading = false;
      return;
    }
    this.authService
      .login(this.loginInput?.value, this.passwordInput?.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.isLoading = false;
        if (res.message === 'success') {
          localStorage.removeItem('jwt');
          localStorage.setItem('jwt', res.token);
          this.router.navigate(['/dashboard']);
          setTimeout(() => {
            window.location.reload();
          });
          return;
        }

        this.invalidLoginOrPassword = true;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get loginInput() {
    return this.loginForm.get('login');
  }

  get passwordInput() {
    return this.loginForm.get('password');
  }
}
