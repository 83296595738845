import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoaderComponent } from '@common/components/loader/loader.component';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-reset-request',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, LoaderComponent],
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class PasswordResetRequestComponent {
  constructor(private fb: FormBuilder, private authService: AuthService, protected router: Router) {}
  isLoading: boolean = false;
  isSubmited: boolean = false;
  userUnknown: boolean = false;
  isSuccess: boolean = false;
  loginForm = this.fb.group({
    login: ['', Validators.required],
  });


  requestReset() {
    this.userUnknown = false;
    this.isLoading = true;
    if (this.loginForm.controls['login'].value) {
      this.authService.resetPasswordRequest(this.loginForm.controls['login'].value).subscribe({
        next: (res: any) => {
          if (res) {
            this.isSuccess = true
          }
        },
        error: (err) => {
          this.isLoading = false
          this.userUnknown = true
          this.isSuccess = false
        },
        complete: () => this.isLoading = false
      })
    }
  }


  get loginInput() {
    return this.loginForm.get('login');
  }
}
