import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '@common/components/loader/loader.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-post-states',
  standalone: true,
  imports: [CommonModule, LoaderComponent, NgbProgressbarModule],
  templateUrl: './post-states.component.html',
  styleUrls: ['./post-states.component.scss'],
})
export class PostStatesComponent {
  @Input() isPosting: boolean = false;
  @Input() isPostingOver: boolean = false;
  @Input() postError: { errorCode: number; message: string } | null = null;
  @Input() readyMessage: string = '';
  @Input() postingMessage: string = '';
  @Input() completeMessage: string = '';
  @Input() definedProgress: boolean = false;
  @Input() progress: number = 0;
}
