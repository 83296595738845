<div class="textstotranslate page">
  <div *ngIf="this.seances.length > 0 else noSeances" class="d-flex align-items-center justify-content-between mb-4">
    <div class="form-floating">
      <select class="select form-select" placeholder="">
        <option
          *ngFor="let seance of this.seances"
          value=""
          (click)="this.getPvsBySeance(seance.id)"
        >
          Traductions du
          {{ seance.date_creation | date : "dd/MM/YYYY" }}
        </option>
      </select>
      <label for="">Séance</label>
    </div>
  </div>
  <ng-template #noSeances>
    <div>
      Aucun texte à traduire pour le moment
    </div>
  </ng-template>
  <app-texts-list
    [textsList]="this.assignedTranslations"
    [todo]="this.textsTodo.TRANSLATE"
    (showTranslateDialogEvent)="this.onShowTranslateDialog($event.pv, $event.translatedText)"
  />

  <app-dialog
    #postDialog
    title="Envoyer la traduction"
    [visibility]="this.translateDialogVisible"
    (closeDialogEvent)="this.translateDialogVisible = $event"
  >

  <app-post-states
      [isPosting]="this.isPosting"
      [isPostingOver]="this.isPostingOver"
      [postError]="this.postError"
      readyMessage="Vous allez envoyer une traduction"
      postingMessage="La traduction est en cours d'envoi"
      completeMessage="La traduction a été envoyée avec succès"
    />

  <div dialog-footer>
      <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.postDialog.closeDialog()"
        >
          Annuler
        </button>
        <button
          class="btn btn-success"
          (click)="this.postTranslation()"
        >
          Valider
        </button>
      </div>
      <div *ngIf="this.isPostingOver">
        <button
          class="btn btn-success me-2"
          (click)="this.postDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
      <div *ngIf="this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.postDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
    </div>
  </app-dialog>
</div>
