import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextsMenuComponent } from 'projects/speaker-platform/src/app/molecules/texts-menu/texts-menu.component';
import { AbstractTextComponent } from '../../../common/abstract/abstract-texts.component';
import { AtsappService } from '@common/services/atsapp.service';
import { TextsListFiltersComponent } from 'projects/speaker-platform/src/app/organisms/admin/texts-list-filters/texts-list-filters.component';
import { TextsListTaxonomy } from 'projects/speaker-platform/src/app/types/texts-list-taxonomy.enum';
import { FiltersProps } from 'projects/speaker-platform/src/app/types/filters';
import { takeUntil } from 'rxjs';
import { DateTransformService } from 'projects/speaker-platform/src/app/services/helpers/date-transform.service';
import { TextsTodo } from 'projects/speaker-platform/src/app/types/texts-todo.enum';
import { PaginationComponent } from 'projects/speaker-platform/src/app/molecules/pagination/pagination.component';
import { DialogComponent } from 'projects/speaker-platform/src/app/molecules/dialog/dialog.component';
import { LoaderComponent } from '@common/components/loader/loader.component';
import { SpeakerService } from 'projects/speaker-platform/src/app/services/helpers/speaker.service';
import { PostStatesComponent } from 'projects/speaker-platform/src/app/molecules/post-states/post-states.component';
import { Speaker } from '../../../types/speaker';
import { CcLibelleService } from '../../../services/helpers/cc-libelle-service.service';
import {NgbModal, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import { TruncatePipe } from '../../../pipes/truncate.pipe';
import {TextFocusComponent} from "../../../organisms/text-focus/text-focus.component";
import {CommonModalService} from "../../../services/helpers/common-modal.service";

@Component({
  selector: 'app-texts-to-translate',
  standalone: true,
  imports: [
    CommonModule,
    TextsMenuComponent,
    TextsListFiltersComponent,
    PaginationComponent,
    DialogComponent,
    LoaderComponent,
    PostStatesComponent,
    NgbTooltip,
    TruncatePipe,
    TextFocusComponent,
  ],
  templateUrl: './texts-to-translate.component.html',
  styleUrls: ['../../../organisms/admin/texts-list/texts-list.component.scss'],
})
export class TextsToTranslateComponent
  extends AbstractTextComponent
  implements OnInit, AfterViewChecked
{
  constructor(
    atsappService: AtsappService,
    dateTransformService: DateTransformService,
    protected speakerService: SpeakerService,
    ccLibelleService: CcLibelleService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private commonModalService: CommonModalService
  ) {
    super(atsappService, dateTransformService, ccLibelleService);
  }

  @ViewChild('successAudio')
  successAudio!: ElementRef;

  @ViewChild('textsListHeader') textsListHeader!: ElementRef;

  smartSelectEnabled: boolean = false;
  selectedSpeaker!: Speaker;
  textsToTranslateList: any[] = [];
  textsListTaxonomy = TextsListTaxonomy;
  textsTodo = TextsTodo;

  showValidationDialog: boolean = false;
  isPosting: boolean = false;
  isPostingOver: boolean = false;
  postError: { errorCode: number; message: string } | null = null;

  page: number = 1;
  limit: number = 10;
  collectionSize: number = 0;

  filters: any[] = [];
  textsListHeaderHeight: string = '';

  postObjectKeys = ['RANGPV', 'ZMS_NUMERO', 'ZMS_LIVRAISON', 'ZMS_MESSAGE'];

  textsForPreview: any[]= [];

  showFocusModalToTranslate: boolean = false;

  ngOnInit(): void {
    this.getPhasesVocale();
    this.getTypesMessage();
    this.getLangues();
    this.getTranslator();
  }

  ngAfterViewChecked(): void {
    this.textsListHeaderHeight =
      this.textsListHeader.nativeElement.clientHeight + 'px';
    this.cdr.detectChanges();
  }

  getTextsToTranslateList(filters: any = this.filters) {
    this.isLoading = true;
    this.filters = filters;
    this.atsappService
      .getTextToTranslate(this.filters, this.page, this.limit)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.textsToTranslateList = res.textToTranslate;
          this.collectionSize = parseInt(res.total);
          this.isLoading = false;
        },
        error: (err) => console.error(err),
        complete: () => this.resetTextsToPatchList(),
      });
  }

  openConfirmationDialog() {
    this.postError = null;
    this.isPosting = false;
    this.isPostingOver = false;
    this.selectedSpeaker !== undefined &&
      (this.showValidationDialog = !this.showValidationDialog);
  }

  sendTexts(notify: boolean = false) {
    // Object to POST
    const body = {
      email: notify,
      CODE_COMEDIENNE: this.selectedSpeaker.codespeak,
      TRANSLATIONS: this.textsToPatchList,
    };

    this.atsappService
      .assignTranslationsToSpeaker(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.playErrorSound();
          this.postError = {
            errorCode: error.error.code,
            message: error.error.message,
          };
          this.isPosting = false;
        },
        complete: () => {
          this.postError = null;
          this.isPosting = false;
          this.isPostingOver = true;
          this.playSuccessSound();
          this.resetTextsToPatchList();
        },
      });
  }

  filterSelectedText() : any[] {
    this.textsForPreview =[]
    return this.textsToTranslateList.filter(allTranslateText => {
      return this.textsToPatchIdList.some(textToSee => {
        const partialIdText = textToSee.split('-')
        return partialIdText[0] === allTranslateText.ZMS_NUMERO && partialIdText[1] === allTranslateText.ZMS_LIVRAISON && partialIdText[2] === allTranslateText.ZMS_MESSAGE && partialIdText[3]=== allTranslateText.RANGPV && partialIdText[4] ===allTranslateText.CLIENT_FINAL
      })
    });
  }

  showSelectedText(content : TemplateRef<any>) {
    this.textsForPreview = this.filterSelectedText()
    console.log(this.textsForPreview)
    this.modalService.open(content, { size: 'xl', scrollable: true , centered: true})
  }

  showFocusToTranslateText(text: any) {
    this.commonModalService.showModalFocus.subscribe((show) => {
      this.showFocusModalToTranslate = show;
    })
    this.commonModalService.openFocusText(text)
  }

  onSmartSelectEnable() {}
}
