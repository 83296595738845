<div class="dailystatus">
    <div class="dailystatus__day">{{this.day}}</div>
    <div class="dailystatus__status dailystatus__status--{{this.status}}">
        <i *ngIf="this.status === 'SEANCE'" ngbTooltip="En séance" class="fa-solid fa-microphone"></i>
        <i *ngIf="this.status === 'NO_SEANCE' || this.status === 'PUBLIC_HOLIDAY'" ngbTooltip="Pas de séance prévue" class="fa-solid fa-ban"></i>
        <i *ngIf="this.status === 'ABSENCE'" ngbTooltip="Absence" class="fa-solid fa-umbrella-beach"></i>
        <i *ngIf="this.status === 'MALADE'" ngbTooltip="Maladie" class="fa-solid fa-kit-medical"></i>
        <i *ngIf="this.status === 'STUDIO'" ngbTooltip="Au studio" class="fa-solid fa-building-user"></i>
    </div>
</div>
