import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  parse,
  startOfToday,
  startOfWeek,
  isWithinInterval,
  isAfter,
  isBefore,
  isWeekend,
} from 'date-fns';
import { fr, enUS } from 'date-fns/locale';
import { AtsappService } from '@common/services/atsapp.service';
import parseISO from 'date-fns/parseISO';
import { PlanningEventReasons } from 'projects/speaker-platform/src/app/types/planning-event-reasons.enum';
import { CardWithHeaderComponent } from 'projects/speaker-platform/src/app/atoms/card-with-header/card-with-header.component';
import { ActionButtonComponent } from 'projects/speaker-platform/src/app/atoms/action-button/action-button.component';
import { DialogComponent } from 'projects/speaker-platform/src/app/molecules/dialog/dialog.component';
import { CreatePlanningEventComponent } from 'projects/speaker-platform/src/app/molecules/create-planning-event/create-planning-event.component';
import { PlanningEvent } from 'projects/speaker-platform/src/app/types/planning-event';
import { ToastService } from 'projects/speaker-platform/src/app/services/toast.service';
import { LoaderComponent } from '@common/components/loader/loader.component';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from '@common/services/user.service';
import { ExternalService } from '@common/services/external.service';

@Component({
  selector: 'app-planning',
  standalone: true,
  imports: [
    CommonModule,
    CardWithHeaderComponent,
    ActionButtonComponent,
    DialogComponent,
    CreatePlanningEventComponent,
    LoaderComponent,
  ],
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss'],
})
export class PlanningComponent implements OnInit, OnDestroy {
  constructor(
    private atsappService: AtsappService,
    private toastService: ToastService,
    private userService: UserService,
    private externalService: ExternalService
  ) {}

  @Input() speakerId: number = 0;
  @Input() speakerDefaultSeanceDays: string[] = [];
  eventReasons = PlanningEventReasons;
  format = format;
  isWeekend = isWeekend;
  parseISO = parseISO;
  days: string[] = ['L', 'Ma', 'Me', 'J', 'V', 'S', 'D'];
  today: Date = startOfToday();
  locale: Locale = fr;
  localeEN: Locale = enUS;
  currentMonth: string = format(this.today, 'MMMM yyyy', { locale: fr });
  firstDayOfMonth: Date = parse(this.currentMonth, 'MMMM yyyy', new Date(), {
    locale: fr,
  });
  daysInMonth: Date[] = eachDayOfInterval({
    start: startOfWeek(this.firstDayOfMonth, { weekStartsOn: 1, locale: fr }),
    end: endOfWeek(endOfMonth(this.firstDayOfMonth), {
      weekStartsOn: 1,
      locale: fr,
    }),
  });
  planningEvent: PlanningEvent[] = [];

  currentPlanningEvent: any = {};
  currentPlanningEventParams: { title: string; color: string; label: string } =
    { title: '', color: '', label: '' };

  selectedDay: Date = this.today;

  planningEventDialogVisible: boolean = false;
  createPlanningEventReason: PlanningEventReasons = PlanningEventReasons.LEAVE;
  patchPlanningEvent: boolean = false;
  confirmPlanningEventDeletion: boolean = false;

  isLoading: boolean = false;
  isPlanningEventsLoading: boolean = false;

  cantEditOrDelete: boolean = false;
  cantCreate: boolean = false;

  availableToday: boolean = false;

  publicHolidays: Map<string, string> = new Map();

  destroy$: Subject<boolean> = new Subject<boolean>();

  canEditPlanning: boolean = true;

  ngOnInit(): void {
    this.getPlanningEventsByComedienne();
    this.getPublicHolidays();

    if (
      this.userService.userData &&
      this.userService.userRoles.has('ROLE_USER_INTERNE')
    ) {
      this.canEditPlanning = false;
    }

    if (this.userService.userRoles.has('ROLE_COMEDIENNE_INTERNE') || this.userService.userRoles.has('ROLE_COMEDIENNE_ADMIN') ) {
      this.canEditPlanning = true;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getPublicHolidays() {
    this.externalService.getPublicHolidays().subscribe({
      next: (res) => {
        // Set values in this.publicHolidays Map without lundi de pentecôte
        for (let [k, v] of Object.entries(res)) {
          if (v !== 'Lundi de Pentecôte') {
            this.publicHolidays.set(k, v)
          }
        }
      },
    });
  }

  getPlanningEventsByComedienne() {
    this.isPlanningEventsLoading = true;
    this.atsappService
      .getPlanningEventsByComedienne(this.speakerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.planningEvent = res;
          this.selectDay(this.selectedDay);
          this.isPlanningEventsLoading = false;
        },
        error: (error) => {
          this.toastService.show(
            'Le chargement des événements du planning a échoué pour la raison suivante : ' +
              error.error.message,
            'danger'
          );
          this.isPlanningEventsLoading = false;
        },
      });
  }

  updateDaysInMonth(): void {
    this.daysInMonth = eachDayOfInterval({
      start: startOfWeek(this.firstDayOfMonth, { weekStartsOn: 1, locale: fr }),
      end: endOfWeek(endOfMonth(this.firstDayOfMonth), { weekStartsOn: 1 }),
    });
    // this.getPublicHolidays();
  }

  getNextMonth() {
    const firstDayOfNextMonth = add(this.firstDayOfMonth, { months: 1 });
    this.currentMonth = format(firstDayOfNextMonth, 'MMMM yyyy', {
      locale: fr,
    });
    this.firstDayOfMonth = parse(this.currentMonth, 'MMMM yyyy', new Date(), {
      locale: fr,
    });
    this.updateDaysInMonth();
  }

  getPrevMonth() {
    const firstDayOfNextMonth = add(this.firstDayOfMonth, { months: -1 });
    this.currentMonth = format(firstDayOfNextMonth, 'MMMM yyyy', {
      locale: fr,
    });
    this.firstDayOfMonth = parse(this.currentMonth, 'MMMM yyyy', new Date(), {
      locale: fr,
    });
    this.updateDaysInMonth();
  }

  findPlanningEvent(day: Date): any | undefined {
    let r: any = {};

    this.planningEvent.forEach((abs: PlanningEvent) => {
      if (
        // On ignore les heures ici pour éviter les erreurs de comparaison
        isWithinInterval(new Date(day), {
          start: new Date(abs.date_debut).setHours(0, 0, 0),
          end: new Date(abs.date_fin).setHours(0, 0, 0),
        })
      ) {
        r = abs;
      }
    });
    return Object.keys(r).length > 0 ? r : undefined;
  }

  selectDay(day: Date) {
    this.confirmPlanningEventDeletion = false;
    this.selectedDay = day;
    this.cantEditOrDelete = false;
    this.cantCreate = false;

    if (
      isBefore(this.selectedDay, this.today) &&
      !this.userService.userData.isAdmin
    ) {
      this.cantCreate = true;
    }

    for (let pe of this.planningEvent) {
      if (
        isWithinInterval(new Date(day), {
          start: new Date(pe.date_debut).setHours(0, 0),
          end: new Date(pe.date_fin).setHours(0, 0),
        })
      ) {
        this.currentPlanningEvent =
          pe.raison === this.eventReasons.NO_SEANCE ? null : pe;
        if (
          isAfter(this.today, new Date(pe.date_debut).setHours(0, 0)) &&
          !this.userService?.userData?.isAdmin
        ) {
          this.cantEditOrDelete = true;
        }

        if (this.currentPlanningEvent) {
          this.setCurrentPlanningEventParams();
        }
        if (
          pe.raison === this.eventReasons.SEANCE ||
          pe.raison === this.eventReasons.ON_SITE
        ) {
          this.availableToday = true;
        } else {
          this.availableToday = false;
        }
        return;
      }
    }

    if (
      this.speakerDefaultSeanceDays.includes(
        format(day, 'EEE', { locale: enUS })
      ) &&
      !this.publicHolidays.has(format(day, 'yyyy-MM-dd'))
    ) {
      this.availableToday = true;
      this.currentPlanningEvent = {
        date_debut: format(this.selectedDay, 'yyyy-MM-dd hh:mm:ss'),
        date_fin: format(this.selectedDay, 'yyyy-MM-dd hh:mm:ss'),
        id: -999,
        raison: this.eventReasons.SEANCE,
      };
      this.setCurrentPlanningEventParams();
    } else if (this.publicHolidays.has(format(day, 'yyyy-MM-dd'))) {
      this.availableToday = false;
      this.currentPlanningEvent = {
        date_debut: format(this.selectedDay, 'yyyy-MM-dd hh:mm:ss'),
        date_fin: format(this.selectedDay, 'yyyy-MM-dd hh:mm:ss'),
        id: -998,
        raison: this.eventReasons.PUBLIC_HOLIDAY,
      };
      this.setCurrentPlanningEventParams();
    } else {
      this.currentPlanningEvent = null;
      this.availableToday = false;
    }
  }

  setCurrentPlanningEventParams() {
    if (this.currentPlanningEvent.raison === this.eventReasons.ON_SITE) {
      this.currentPlanningEventParams = {
        title: `Au studio le ${format(
          parseISO(this.currentPlanningEvent.date_debut),
          'dd MMMM yyyy',
          {
            locale: this.locale,
          }
        )} à ${format(
          parseISO(this.currentPlanningEvent.date_debut),
          "HH'h'mm",
          {
            locale: this.locale,
          }
        )}`,
        color: 'info',
        label: 'le déplacement',
      };
    } else if (this.currentPlanningEvent.raison === this.eventReasons.SEANCE) {
      this.currentPlanningEventParams = {
        title: 'En séance',
        color: 'success',
        label: 'la disponibilité',
      };
    } else if (
      this.currentPlanningEvent.raison === this.eventReasons.SICK_LEAVE
    ) {
      this.currentPlanningEventParams = {
        title: `Maladie prévue jusqu'au ${format(
          parseISO(this.currentPlanningEvent.date_fin),
          'dd MMMM yyyy',
          {
            locale: this.locale,
          }
        )}`,
        color: 'danger',
        label: "l'arrêt",
      };
    } else if (
      this.currentPlanningEvent.raison === this.eventReasons.PUBLIC_HOLIDAY
    ) {
      this.currentPlanningEventParams = {
        title:
          'Jour férié - ' +
          this.publicHolidays.get(
            format(parseISO(this.currentPlanningEvent.date_debut), 'yyyy-MM-dd')
          ),
        color: 'disabled',
        label: '',
      };
    } else {
      this.currentPlanningEventParams = {
        title: `Absence jusqu'au ${format(
          parseISO(this.currentPlanningEvent.date_fin),
          'dd MMMM yyyy',
          {
            locale: this.locale,
          }
        )}`,
        color: 'warning',
        label: "l'absence",
      };
    }
  }

  onCreatePlanningEvent(reason: PlanningEventReasons, patch: boolean = false) {
    this.createPlanningEventReason = reason;
    this.patchPlanningEvent = patch;
    this.planningEventDialogVisible = !this.planningEventDialogVisible;
  }

  createPlanningEvent(
    fromDate: string,
    toDate: string,
    reason: PlanningEventReasons,
    patch: boolean,
    emailNotify: boolean
  ) {
    const body = {
      dateDebut: fromDate,
      dateFin: toDate,
      raison: reason,
    };

    if (body.raison === 'STUDIO' && body.dateFin !== body.dateDebut) {
      body.dateFin = body.dateDebut
    }

    this.isLoading = true;

    if (patch) {
      this.atsappService
        .patchPlanningEvent(body, this.currentPlanningEvent.id, emailNotify)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          error: (error) => {
            this.isLoading = false;
            this.toastService.show(
              "L'événement n'a pas été modifié pour la raison suivante' : " +
                error.error.message,
              'danger'
            );
          },
          complete: () => {
            this.isLoading = false;
            this.getPlanningEventsByComedienne();
            this.planningEventDialogVisible = false;
            if (
              this.userService.userData &&
              !this.userService.userData.isAdmin
            ) {
              this.userService.getSpeaker(this.userService.userData.login);
            }
            this.toastService.show("L'événement a bien été modifié", 'success');
          },
        });
      return;
    }

    this.atsappService
      .createPlanningEvent(body, this.speakerId, emailNotify)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.isLoading = false;
          this.toastService.show(
            "L'événement n'a pas été ajouté au planning pour la raison suivante' : " +
              error.error.message,
            'danger'
          );
        },
        complete: () => {
          this.isLoading = false;
          this.getPlanningEventsByComedienne();
          this.planningEventDialogVisible = false;
          this.toastService.show(
            "L'événement a bien été ajouté au planning",
            'success'
          );
        },
      });
  }

  deletePlanningEvent() {
    this.isLoading = true;
    this.atsappService
      .deletePlanningEvent(this.currentPlanningEvent.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.isLoading = false;
          this.toastService.show(
            "L'événement n'a pas été supprimé du planning pour la raison suivante' : " +
              error.error.message,
            'danger'
          );
        },
        complete: () => {
          this.isLoading = false;
          this.getPlanningEventsByComedienne();
          if (this.userService.userData && !this.userService.userData.isAdmin) {
            this.userService.getSpeaker(this.userService.userData.login);
          }
          this.toastService.show(
            "L'événement a bien été supprimé du planning",
            'success'
          );
          this.selectDay(this.selectedDay);
        },
      });
  }

  autoDeletePlanningEvent(id: number) {
    this.isLoading = true;
    this.atsappService
      .deletePlanningEvent(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.isLoading = false;
          this.toastService.show(
            "L'événement n'a pas été remplacé pour la raison suivante' : " +
              error.error.message,
            'danger'
          );
        },
        complete: () => {
          this.isLoading = false;
          this.getPlanningEventsByComedienne();
          this.toastService.show("L'événement a bien été remplacé", 'success');
        },
      });
  }
}
