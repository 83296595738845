<div
  *ngIf="!this.isPosting && !this.isPostingOver && !this.postError"
  class="poststates"
>
  {{ this.readyMessage }}
</div>

<div
  *ngIf="this.isPosting && !this.isPostingOver && !this.postError"
  class="text-center"
>
  <div *ngIf="!this.definedProgress" class="poststates__loader">
    <app-loader />
  </div>
  <div *ngIf="this.definedProgress" class="mb-3">
    <ngb-progressbar class="mb-3" type="success" [value]="this.progress" [striped]="true" [showValue]="true" />
  </div>
  <div>{{ this.postingMessage }}</div>
</div>

<div class="poststates__postingerror" *ngIf="this.postError && !this.isPosting">
  <div class="poststates__postingerror__crossmark">
    <i class="fa-solid fa-circle-exclamation"></i>
  </div>
  <div>
    {{ this.postError.message }}
  </div>
</div>

<div *ngIf="this.isPostingOver" class="poststates__postingover">
  <div class="poststates__postingover__checkmark">
    <i class="fa-solid fa-circle-check"></i>
  </div>
  <div>
    {{ completeMessage }}
  </div>
</div>

