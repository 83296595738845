<div class="speakercreate page container" [formGroup]="this.createSpeakerForm">
  <div class="row mb-5">
    <div class="col-7">
      <div class="row mb-3">
        <div class="col-12">
          <h6>Coordonnées</h6>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-2">
          <div class="form-floating">
            <select
              name="create-speaker-gender"
              id="create-speaker-gender"
              class="form-select"
              formControlName="genre"
            >
              <option value=""></option>
              <option value="002">F</option>
              <option value="001">M</option>
            </select>
            <label class="form-label" for="create-speaker-gender">Genre</label>
          </div>
        </div>
        <div class="col-5">
          <div class="form-floating">
            <input
              #prenomInput
              type="text"
              class="form-control"
              id="create-speaker-prenom"
              placeholder=""
              formControlName="prenom"
              (keyup)="
                this.firstNameForValidation = prenomInput.value;
                this.removeTrigrammeValidator()
              "
              (blur)="this.prefillTrigramme()"
            />
            <label class="form-label" for="create-speaker-prenom">Prénom</label>
          </div>
        </div>
        <div class="col-5">
          <div class="form-floating">
            <input
              #nomInput
              type="text"
              class="form-control"
              id="create-speaker-nom"
              placeholder=""
              formControlName="nom"
              (blur)="this.prefillTrigramme()"
              (keyup)="
                this.lastNameForValidation = nomInput.value;
                this.removeTrigrammeValidator()
              "
            />
            <label class="form-label" for="create-speaker-nom">Nom</label>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="create-speaker-adresse1"
              placeholder=""
              formControlName="adresse1"
            />
            <label class="form-label" for="create-speaker-adresse1"
              >Adresse</label
            >
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="create-speaker-adresse2"
              placeholder=""
              formControlName="adresse2"
            />
            <label class="form-label" for="create-speaker-adresse2"
              >Complément d'adresse</label
            >
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="create-speaker-codepostal"
              placeholder=""
              formControlName="codepostal"
            />
            <label class="form-label" for="create-speaker-codepostal"
              >Code postal</label
            >
          </div>
        </div>
        <div class="col-8">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="create-speaker-ville"
              placeholder=""
              formControlName="ville"
            />
            <label class="form-label" for="create-speaker-ville">Ville</label>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="create-speaker-telephone"
              placeholder=""
              formControlName="telephone"
            />
            <label class="form-label" for="create-speaker-telephone"
              >Téléphone</label
            >
          </div>
        </div>
        <div class="col-6">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="create-speaker-mobilespeak"
              placeholder=""
              formControlName="mobilespeak"
            />
            <label class="form-label" for="create-speaker-mobilespeak"
              >Téléphone portable</label
            >
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="create-speaker-email"
              placeholder=""
              formControlName="email"
            />
            <label class="form-label" for="create-speaker-email">Email</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="row mb-3">
        <div class="col-12">
          <h6>Présentation vocale</h6>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <input
            class="form-control"
            type="file"
            accept="audio/x-wav, audio/mpeg"
            name="create-speaker-medley"
            id="create-speaker-medley"
            (change)="this.saveMedleyFile($event)"
          />
          <!-- <label for="create-speaker-medley">msldkf</label> -->
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <h6>Photo</h6>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <div>
            <image-cropper
              [imageChangedEvent]="this.imageChangedEvent"
              [maintainAspectRatio]="true"
              [containWithinAspectRatio]="false"
              [aspectRatio]="1 / 1"
              [resizeToWidth]="145"
              [cropperMinWidth]="75"
              (imageCropped)="this.imageCropped($event)"
              format="jpeg"
            />
            <div class="d-flex justify-content-center">
              <div class="speakercreate__photo">
                <img [src]="this.croppedImage" />
              </div>
            </div>
          </div>
          <input
            class="form-control"
            type="file"
            name="create-speaker-photo"
            id="create-speaker-photo"
            accept="image/png, image/jpeg"
            (change)="this.fileChangeEvent($event)"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-7">
      <h6>Biographie</h6>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-7">
      <div class="form-floating">
        <textarea
          class="form-control"
          formControlName="biographie"
          name="create-speaker-biographie"
          id="create-speaker-biographie"
        ></textarea>
        <label class="form-label" for="create-speaker-biographie"
          >Biographie</label
        >
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-4">
      <h6>Compte</h6>
    </div>
    <div class="col-8">
      <h6>Domaine</h6>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-2">
      <div class="form-floating">
        <input
          #trigrammeInput
          class="form-control"
          type="text"
          name="create-speaker-trigramme"
          id="create-speaker-trigramme"
          placeholder=""
          formControlName="trigramme"
          (keyup)="this.updateTrigrammeValidator()"
        />
        <label for="create-speaker-trigramme">Trigramme</label>
      </div>
      <div
        class="input-invalid-message"
        *ngIf="this.trigramme.errors?.['availability']"
      >
        Ce trigramme n'est pas disponible
      </div>
      <div
        class="input-invalid-message"
        *ngIf="this.trigramme.errors?.['trigramme']"
      >
        Les deux premières lettres du trigramme doivent être celles du prénom et
        du nom
      </div>
      <div
        class="input-invalid-message"
        *ngIf="this.trigramme.errors?.['minlength'] || this.trigramme.errors?.['maxlength']"
      >
        Le trigramme doit comporter 3 caractères
      </div>
    </div>
    <div class="col-2">
      <div class="form-floating">
        <input
          #codeSpeakInput
          class="form-control"
          type="text"
          name="create-speaker-codespeak"
          id="create-speaker-codespeak"
          placeholder=""
          formControlName="codespeak"
        />
        <label for="create-speaker-codespeak">Code</label>
      </div>
      <div
        class="input-invalid-message"
        *ngIf="this.codespeak.errors?.['availability']"
      >
        Ce code n'est pas disponible
      </div>
      <div
        class="input-invalid-message"
        *ngIf="this.codespeak.errors?.['minlength'] || this.codespeak.errors?.['maxlength']"
      >
        Le code doit comporter 3 chiffres
      </div>
    </div>
    <div class="col-8 d-flex align-items-center flex-wrap">
      <div *ngFor="let domain of this.zds" class="form-check me-3 mb-2">
        <input
          class="form-check-input"
          type="checkbox"
          name="create-speaker-domain-{{ this.normalize(domain.cc_abrege) }}"
          id="create-speaker-domain-{{ this.normalize(domain.cc_abrege) }}"
          [value]="domain.cc_code"
          (change)="this.saveMultiCheck($event, this.domaine, 'domaine')"
        />
        <label for="" class="form-check-label">{{ domain.cc_libelle }}</label>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-7">
      <h6>Jours d'enregistrement par défaut</h6>
    </div>
    <div class="col-5">
      <h6>Langues</h6>
    </div>
  </div>

  <div class="row mb-5">
    <div class="row">
      <div class="col-7">
        <div class="speakercreate__recorddays">
          <div class="speakercreate__recorddays__day">
            <label
              class="form-check-label d-block"
              for="create-speaker-record-mon"
              >Lundi</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              name="create-speaker-record-mon"
              id="create-speaker-record-mon"
              value="Mon"
              (change)="
                this.saveMultiCheck($event, this.recordDays, 'disponibilite')
              "
            />
          </div>
          <div class="speakercreate__recorddays__day">
            <label
              class="form-check-label d-block"
              for="create-speaker-record-tue"
              >Mardi</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              name="create-speaker-record-tue"
              id="create-speaker-record-tue"
              value="Tue"
              (change)="
                this.saveMultiCheck($event, this.recordDays, 'disponibilite')
              "
            />
          </div>
          <div class="speakercreate__recorddays__day">
            <label
              class="form-check-label d-block"
              for="create-speaker-record-wed"
              >Mercredi</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              name="create-speaker-record-wed"
              id="create-speaker-record-wed"
              value="Wed"
              (change)="
                this.saveMultiCheck($event, this.recordDays, 'disponibilite')
              "
            />
          </div>
          <div class="speakercreate__recorddays__day">
            <label
              class="form-check-label d-block"
              for="create-speaker-record-thu"
              >Jeudi</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              name="create-speaker-record-thu"
              id="create-speaker-record-thu"
              value="Thu"
              (change)="
                this.saveMultiCheck($event, this.recordDays, 'disponibilite')
              "
            />
          </div>
          <div class="speakercreate__recorddays__day">
            <label
              class="form-check-label d-block"
              for="create-speaker-record-fri"
              >Vendredi</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              name="create-speaker-record-fri"
              id="create-speaker-record-fri"
              value="Fri"
              (change)="
                this.saveMultiCheck($event, this.recordDays, 'disponibilite')
              "
            />
          </div>
          <div class="speakercreate__recorddays__day">
            <label
              class="form-check-label d-block"
              for="create-speaker-record-sat"
              >Samedi</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              name="create-speaker-record-sat"
              id="create-speaker-record-sat"
              value="Sat"
              (change)="
                this.saveMultiCheck($event, this.recordDays, 'disponibilite')
              "
            />
          </div>
          <div class="speakercreate__recorddays__day">
            <label
              class="form-check-label d-block"
              for="create-speaker-record-sun"
              >Dimanche</label
            >
            <input
              class="form-check-input"
              type="checkbox"
              name="create-speaker-record-sun"
              id="create-speaker-record-sun"
              value="Sun"
              (change)="
                this.saveMultiCheck($event, this.recordDays, 'disponibilite')
              "
            />
          </div>
        </div>
      </div>
      <div class="col-5 d-flex">
        <div class="form-floating me-3">
          <select
            name="langue1"
            id="langue1"
            formControlName="langue1"
            class="form-select"
          >
            <option value="">Choix</option>
            <option *ngFor="let langue of this.lgu" [value]="langue.cc_code">
              {{ langue.cc_libelle }}
            </option>
          </select>
          <label for="form-label">Langue 1</label>
        </div>
        <div class="form-floating">
          <select
            name="langue2"
            id="langue2"
            formControlName="langue2"
            class="form-select"
          >
            <option value="">Choix</option>
            <option *ngFor="let langue of this.lgu" [value]="langue.cc_code">
              {{ langue.cc_libelle }}
            </option>
          </select>
          <label for="form-label">Langue 2</label>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-7">
      <div class="row mb-3">
        <div class="col-12">
          <h6>Limitations</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-floating">
            <input
              class="form-control"
              type="number"
              name="create-speaker-textslimit"
              id="create-speaker-textslimit"
              formControlName="nbtxtpv"
              placeholder=""
            />
            <label for="create-speaker-textslimit"
              >Nombre de textes par séance</label
            >
          </div>
        </div>
        <div class="col-6">
          <div class="form-floating">
            <input
              class="form-control"
              type="number"
              name="create-speaker-seanceslimit"
              id="create-speaker-seanceslimit"
              formControlName="nbpvjour"
              placeholder=""
            />
            <label for="create-speaker-seanceslimit"
              >Nombre de séances par session</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="row mb-3">
        <div class="col-12">
          <h6>Mode On Air</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-between">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              name="create-speaker-onair"
              id="create-speaker-onair"
              formControlName="onair"
            />
            <label class="form-check-label" for="create-speaker-onair"
              >Activé</label
            >
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              name="create-speaker-nbonairjour"
              id="create-speaker-nbonairjour"
              type="number"
              formControlName="nbonairjour"
              placeholder=""
            />
            <label for="create-speaker-nbonairjour">Heures max. par jour</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-8">
      <h6>Interprétation</h6>
    </div>
    <div class="col-4">
      <h6>Traduction</h6>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-8 d-flex flex-wrap">
      <div class="form-check me-3 mb-2" *ngFor="let interpretation of this.zin">
        <input
          class="form-check-input"
          type="checkbox"
          name="create-speaker-interpretation-{{
            this.normalize(interpretation.cc_abrege)
          }}"
          id="create-speaker-interpretation-{{
            this.normalize(interpretation.cc_abrege)
          }}"
          [value]="interpretation.cc_code"
          (change)="
            this.saveMultiCheck($event, this.interpretations, 'interpretation')
          "
        />
        <label
          class="form-check-label"
          for="create-speaker-interpretation-{{
            this.normalize(interpretation.cc_abrege)
          }}"
          >{{ interpretation.cc_libelle }}</label
        >
      </div>
    </div>
    <div class="col-4 d-flex flex-wrap">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          name="create-speaker-traductrice"
          id="create-speaker-traductrice"
          formControlName="traductrice"
        />
        <label class="form-check-label" for="create-speaker-traductrice">Traducteur.trice ?</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <div class="row">
        <div class="col-12">
          <h6>Timbre</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex">
          <div class="form-check me-3" *ngFor="let timbrevoix of this.ztb">
            <input
              class="form-check-input"
              type="checkbox"
              name="create-speaker-timbre-{{
                this.normalize(timbrevoix.cc_abrege)
              }}"
              id="create-speaker-timbre-{{
                this.normalize(timbrevoix.cc_abrege)
              }}"
              [value]="timbrevoix.cc_code"
              (change)="this.saveMultiCheck($event, this.timbre, 'timbrevoix')"
            />
            <label
              class="form-check-label"
              for="create-speaker-timbre-{{
                this.normalize(timbrevoix.cc_abrege)
              }}"
              >{{ timbrevoix.cc_libelle }}</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div class="row">
        <div class="col-12">
          <h6>Âge</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex">
          <div class="form-check me-3" *ngFor="let agevoix of this.zav">
            <input
              class="form-check-input"
              type="checkbox"
              name="create-speaker-agevoix-{{
                this.normalize(agevoix.cc_abrege)
              }}"
              id="create-speaker-agevoix-{{
                this.normalize(agevoix.cc_abrege)
              }}"
              [value]="agevoix.cc_code"
              (change)="this.saveMultiCheck($event, this.voiceAge, 'agevoix')"
            />
            <label
              class="form-check-label"
              for="create-speaker-agevoix-{{
                this.normalize(agevoix.cc_abrege)
              }}"
              >{{ agevoix.cc_libelle }}</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row my-5">
    <div class="col-12">
      <button
        class="btn btn-success"
        [disabled]="this.createSpeakerForm.invalid || this.isLoading"
        (click)="this.saveSpeaker()"
      >
        <span *ngIf="!this.isLoading"> Enregistrer </span>
        <span *ngIf="this.isLoading" class="d-flex">
          Création en cours
          <div class="ms-3">
            <app-loader color="#fff" />
          </div>
        </span>
      </button>
    </div>
  </div>
</div>
