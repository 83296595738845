<div
  class="editpresentation container"
  [formGroup]="this.editSpeakerPresentation"
>
  <div class="row mb-3">
    <div class="col-12">
      <h6>Biographie</h6>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-12">
      <div class="form-floating">
        <textarea
          class="form-control"
          name=""
          id=""
          placeholder=""
          formControlName="biographie"
          (change)="this.updatePristineStatus()"
        ></textarea>
        <label for="" class="form-label">Biographie</label>
      </div>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-6">
      <div class="row mb-3">
        <div class="col-12">
          <h6>Présentation vocale</h6>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <audio
            class="w-100"
            *ngIf="this.medleyObjectURL"
            controls
            [src]="this.medleyObjectURL"
          ></audio>
          <div *ngIf="this.medleyError" class="text-center text-danger">
            {{ this.medleyError }}
          </div>
          <div
            *ngIf="!this.medleyObjectURL && !this.medleyError && this.medleyLoaded"
            class="text-center text-info"
          >
            Aucun medley est associé à ce profil. Vous pouvez en ajouter un
            ci-dessous
          </div>
          <div class="text-center text-info" *ngIf="!this.medleyLoaded && !this.medleyError">
            Medley en cours de chargement
            <app-loader />
          </div>
          <input
            #medleyFileInput
            class="form-control"
            type="file"
            name="create-speaker-medley"
            id="create-speaker-medley"
            [disabled]="this.readOnly"
            (change)="this.updatePristineStatus(); this.saveMedleyFile($event)"
          />
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row mb-3">
        <div class="col-12">
          <h6>Photo</h6>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <div>
            <image-cropper
              [imageChangedEvent]="this.imageChangedEvent"
              [maintainAspectRatio]="true"
              [containWithinAspectRatio]="false"
              [aspectRatio]="1 / 1"
              [resizeToWidth]="145"
              [cropperMinWidth]="75"
              (imageCropped)="this.imageCropped($event)"
              format="jpeg"
            />
            <div class="d-flex justify-content-center">
              <div class="editpresentation__photo">
                <img *ngIf="this.croppedImage" [src]="this.croppedImage" />
                <img
                  *ngIf="
                    !this.croppedImage ||
                    (this.croppedImage === '' && this.photo)
                  "
                  [src]="'assets' + this.photo"
                  alt=""
                />
              </div>
            </div>
          </div>
          <input
            class="form-control"
            type="file"
            name="create-speaker-photo"
            id="create-speaker-photo"
            [disabled]="this.readOnly"
            (change)="this.fileChangeEvent($event); this.updatePristineStatus()"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="!this.editSpeakerPresentation.pristine" class="col-12 text-end">
      <button class="btn btn-danger me-2" (click)="this.resetForm()">
        Annuler les modifications
      </button>
      <button
        class="btn btn-success"
        (click)="this.savePresentation()"
        [disabled]="this.isLoading"
      >
        <span *ngIf="!this.isLoading"> Enregistrer </span>
        <span *ngIf="this.isLoading" class="d-flex">
          Modification en cours
          <div class="ms-3">
            <app-loader color="#fff" />
          </div>
        </span>
      </button>
    </div>
  </div>
</div>
