<div class="page container textsstandby textslist">
  <div #textsListHeader class="textslist__header">
    <div class="row">
      <div class="col-12">
        <app-texts-menu [todo]="this.textsTodo.RECORD" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-texts-list-filters
          [speakersList]="this.speakersList"
          [languesList]="this.languesList"
          [phasesVocalesList]="this.phasesVocalesList"
          [typesMessageList]="this.typesMessageList"
          [showStatusComedienne]="false"
          (resetFiltersEvent)="this.getStandbyPVs($event)"
          (selectSpeakerEvent)="this.selectedSpeaker = $event"
          (search)="this.getStandbyPVs($event)"
          [isLoading]="this.isLoading"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <table class="textslist__table table">
        <thead [style]="{top: this.textsListHeaderHeight}">
          <tr>
            <th>Code PV</th>
            <th>Créateur</th>
            <th>Client</th>
            <th>Date livraison</th>
            <th>Comédien·ne</th>
            <th><i class="fa-solid fa-globe"></i></th>
            <th><i class="fa-solid fa-venus-mars"></i></th>
            <th>Type message</th>
            <th>Guide</th>
            <th>Phase vocale</th>
            <th>LER</th>
            <th><i class="fa-solid fa-language"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="this.isLoading; else loaded">
            <td class="text-center" colspan="10">
              <div class="textslist__table__loader">
                <app-loader />
              </div>
            </td>
          </tr>
          <tr *ngIf="this.standbyPVs?.length === 0 && !this.isLoading">
            <td class="textslist__table__empty" colspan="10">Aucun résultat</td>
          </tr>
          <ng-template #loaded>
            <tr *ngFor="let text of this.standbyPVs; let i = index" (dblclick)="showFocusStandByText(text)">
              <td>
                {{ text.ZMS_PVCODE1 }}
              </td>
              <td> {{ this.getCreatorName(text.ZMS_CREATEUR) }} </td>
              <td [ngbTooltip]="text.LIBELLE_CLIENT_FINAL" container="body">
                {{ text.CLIENT_FINAL }} -
                {{ text.LIBELLE_CLIENT_FINAL | truncate : 10 }}
              </td>
              <td>{{ text.ZMS_DATELIVRAISON | date : "dd/MM/YYYY" }}</td>
              <td>{{ text.PRENOM_COMEDIENNE }} {{ text.NOM_COMEDIENNE }}</td>
              <td>{{ this.getCodes(text.LANGUE, this.languesList) }}</td>
              <td>{{ text.GENRE | truncate : 1 : "" }}</td>
              <td>
                {{
                  this.getLibelles(text.ZMS_TYPEMESSAGE, this.typesMessageList)
                }}
              </td>
              <td ngbTooltip="{{ text.ZMS_GUIDE }}" container="body">
                {{ text.ZMS_GUIDE | truncate : 20 }}
              </td>
              <td>
                {{
                  this.getLibelles(text.ZMS_PHASEVOCALE, this.phasesVocalesList)
                }}
              </td>
              <td>
                <button
                  *ngIf="text.LER !== null"
                  [ngbTooltip]="text.LER"
                  container="body"
                  class="btn-invisible"
                >
                  <i class="fa-solid fa-exclamation-circle"></i>
                </button>
              </td>
              <td>
                <div *ngIf="text.ZMS_PVTRADUCTION1 === 'X'">
                  <div
                    class="textslist__translationstatus"
                    [ngClass]="{
                      'textslist__translationstatus--sent':
                        text.ZMS_PVENVOITRAD1 !== null,
                      'textslist__translationstatus--done':
                        text.ZMS_PVRECEPTIONTRAD1 !== null
                    }"
                  >
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>

  <app-text-focus *ngIf="this.showFocusModalFromStandBy" />


  <app-pagination
    *ngIf="!this.isLoading"
    [page]="this.page"
    [limit]="this.limit"
    [collectionSize]="this.collectionSize"
    (changePageEvent)="this.page = $event; this.getStandbyPVs()"
    (changeLimitEvent)="this.limit = $event; this.getStandbyPVs()"
  />
</div>
