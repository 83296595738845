import { Injectable } from '@angular/core';

export interface ToastInfo {
  body: string;
  type?: 'success' | 'info' | 'warning' | 'danger'
  delay?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor() {}
  toasts: ToastInfo[] = [];

  show(
    body: string,
    type: 'success' | 'info' | 'warning' | 'danger'
  ) {
    this.toasts.push({ body, type });
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter((t) => t != toast);
  }
}
