import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function trigrammeValidator(firstName: string, lastName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value

    if (!value || !firstName || !lastName) {
      return null;
    }

    const correctFirstLetter = firstName.slice(0,1).toUpperCase() === value?.slice(0, 1).toUpperCase();
    const correctSecondLetter = lastName.slice(0,1).toUpperCase() === value?.slice(1,2).toUpperCase();

    const isCorrect = correctFirstLetter && correctSecondLetter

    return !isCorrect ? { trigramme: true } : null;
  }
}