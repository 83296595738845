import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanningEventReasons } from 'projects/speaker-platform/src/app/types/planning-event-reasons.enum';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbCalendar,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbDatepickerModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { DialogComponent } from '../dialog/dialog.component';
import { DateTransformService } from 'projects/speaker-platform/src/app/services/helpers/date-transform.service';
import { LoaderComponent } from '@common/components/loader/loader.component';
import { PlanningEvent } from 'projects/speaker-platform/src/app/types/planning-event';
import {
  add,
  compareAsc,
  sub,
  format,
  eachDayOfInterval,
  areIntervalsOverlapping,
  getOverlappingDaysInIntervals,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { UserService } from '@common/services/user.service';
import { NgbDateCustomParserFormatter } from '@common/services/ngb-date-formatter.service';

@Component({
  selector: 'app-create-planning-event',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    DialogComponent,
    LoaderComponent,
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
  templateUrl: './create-planning-event.component.html',
  styleUrls: ['./create-planning-event.component.scss'],
})
export class CreatePlanningEventComponent implements OnChanges {
  constructor(
    private fb: FormBuilder,
    private dateTransformService: DateTransformService,
    private ngbCalendar: NgbCalendar,
    protected userService: UserService
  ) {}
  planningEventReasons = PlanningEventReasons;
  @Input() reason: PlanningEventReasons = PlanningEventReasons.LEAVE;
  @Input() planningEventDialogVisible: boolean = false;
  @Input() selectedDay: NgbDateStruct = { year: 0, month: 0, day: 0 };
  @Input() patch: boolean = false;
  @Input() fromDate: NgbDateStruct = { year: 0, month: 0, day: 0 };
  @Input() toDate: NgbDateStruct = { year: 0, month: 0, day: 0 };
  @Input() isLoading: boolean = false;
  @Input() planningEvents: PlanningEvent[] = [];
  @Input() currentPlanningEventId: number = -1;
  @Output() createPlanningEventEvent = new EventEmitter();
  @Output() closePlanningEventDialogEvent = new EventEmitter<boolean>();
  @Output() deletePlaningEventEvent = new EventEmitter();

  title: string = '';

  editEventBody: any = null;
  createEventBody: any = null;

  eventsToDelete: any[] = [];
  eventsToPatch: any[] = [];
  patchEventBodies: any[] = [];
  overlapsOnlyOnFirstDay!: number;
  overlapsOnlyOnLastDay!: number;
  eventsAlreadyRegisered: PlanningEvent[] = [];

  today: NgbDateStruct = this.ngbCalendar.getToday();

  hasConflict: boolean = false;
  hasInconsistentDate: boolean = false;

  planningEventForm = this.fb.group({
    planningEventFromDate: [this.patch ? this.fromDate : this.selectedDay],
    planningEventToDate: [this.patch ? this.toDate : this.selectedDay],
    planningEventHour: [{ hour: 0, minute: 0, second: 0 }],
    emailNotify: [false],
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['planningEventDialogVisible']) {
      if (changes['planningEventDialogVisible'].currentValue === true) {
        this.identifyOverlaps();
      } else {
        this.eventsToDelete = [];
        this.eventsToPatch = [];
        this.patchEventBodies = [];
        this.eventsAlreadyRegisered = [];
        this.overlapsOnlyOnFirstDay = 0;
        this.overlapsOnlyOnLastDay = 0;
        this.hasConflict = false;
      }
    }
    if (
      changes['selectedDay'] ||
      changes['fromDate'] ||
      changes['toDate'] ||
      changes['patch'] ||
      changes['reason']
    ) {
      this.planningEventForm.patchValue({
        planningEventFromDate: this.patch ? this.fromDate : this.selectedDay,
        planningEventToDate: this.patch ? this.toDate : this.selectedDay,
        planningEventHour: { hour: 0, minute: 0, second: 0 },
      });
    }

    // Si reason === ON_SITE, fromDate === toDate
    // Ne pas filtrer uniquement sur changes['reason']
    // Sinon, si on déclare deux ON_SITE ou deux SEANCES d'affilé, le planningEventToDate n'est pas set

    switch (this.reason) {
      case this.planningEventReasons.ON_SITE:
        this.title = 'Déclarer une présence au studio';
        this.planningEventForm.patchValue({
          planningEventFromDate: this.patch ? this.fromDate : this.selectedDay,
          planningEventToDate: this.patch ? this.fromDate : this.selectedDay,
          planningEventHour: { hour: 9, minute: 0, second: 0 },
        });
        break;
      case this.planningEventReasons.SEANCE:
        this.title = 'Se rendre disponible';
        break;
      case this.planningEventReasons.NO_SEANCE:
        this.title = 'Se rendre indisponible';
        break;
      case this.planningEventReasons.LEAVE:
        this.title = 'Déclarer une absence';
        break;
      case this.planningEventReasons.SICK_LEAVE:
        this.title = 'Déclarer une absence pour maladie';
        break;
      case this.planningEventReasons.NO_SEANCE:
        this.title = 'Se rendre indisponible';
        break;
    }
  }

  identifyOverlaps() {
    this.patchEventBodies = [];
    this.hasInconsistentDate = false;

    if (this.planningEventFromDate.value && this.planningEventToDate.value) {
      // On récupère et transforme les dates de début / fin à chaque modification de l'une ou l'autre
      const fromDate = this.dateTransformService.transformDate(
        this.planningEventFromDate.value
      );

      let toDate : string = this.dateTransformService.transformDate(
        this.planningEventToDate.value
      );
      if (this.reason === 'STUDIO') {
         toDate = this.dateTransformService.transformDate(
          this.planningEventFromDate.value
        );
      }

      const newEventInterval: Interval = {
        start: new Date(fromDate),
        end: new Date(toDate),
      };

      if (newEventInterval.start > newEventInterval.end) {
        this.hasInconsistentDate = true;
        return
      }

      // On boucle sur les événements déjà présents en BDD
      this.planningEvents.forEach((pe: PlanningEvent) => {
        // Renvoie true si l'interval créé par les dates sélectionnées du formulaire
        // Entre en conflit avec l'interval d'un ou plusieurs événements enregistrés en BDD

        const intervalConflicts = areIntervalsOverlapping (
          newEventInterval,
          {
            start: new Date(pe.date_debut).setHours(0, 0),
            end: new Date(pe.date_fin).setHours(0, 0),
          },
          { inclusive: true }
        );

        // Si des intervales en conflits sont trouvés, on les ajoute dans le tableau
        // A priori on a pas besoin de checker les valeurs en double, le tableau eventsAlreadyRegistered
        // est réinitialisé au début de la fonction

        if (this.patch) {
          if (pe.id !== this.currentPlanningEventId && intervalConflicts) {
            this.eventsAlreadyRegisered.push(pe);
          }
          return;
        }
        intervalConflicts && this.eventsAlreadyRegisered.push(pe);
      });
      this.hasConflict = this.eventsAlreadyRegisered.length > 0;
      this.processingOverlaps(newEventInterval);
    }
  }

  processingOverlaps(newEventInterval: Interval) {
    this.eventsToDelete = [];
    this.eventsToPatch = [];
    this.eventsAlreadyRegisered.forEach((event: PlanningEvent) => {
      const existingEventInterval: Interval = {
        start: new Date(event.date_debut).setHours(0, 0),
        end: new Date(event.date_fin).setHours(0, 0),
      };
      // Nombre de jours qui se superposent à l'événement existant
      const overlappingDays =
        getOverlappingDaysInIntervals(newEventInterval, existingEventInterval) +
        1;
      // Durée en jours de l'événement existant
      const existingEventNumberOfDays = eachDayOfInterval({
        start: new Date(event.date_debut),
        end: new Date(event.date_fin),
      }).length;

      if (overlappingDays >= existingEventNumberOfDays) {
        // Si un événement existant est entièrement couvert par le nouvel événement, il suffit de le supprimer
        // if (!this.patch && event.id !== this.currentPlanningEventId) {
        this.eventsToDelete.push(event);
        // }
      } else {
        // Si un événément existant n'est pas entièrement couvert, il faut déterminer ses nouvelles dates
        // if (!this.patch && this.currentPlanningEventId === event.id) {
        this.eventsToPatch.push(event);
        // }
      }
    });

    this.exec(newEventInterval);
  }

  exec(newEventInterval: Interval) {
    let ev1, ev2;
    this.patchEventBodies = [];
    this.eventsToPatch.forEach((event: PlanningEvent) => {
      // Si l'événement existant doit être coupé en deux

      // Si date_fin < date_début, l'événement déborde seulement sur le premier jour
      // On créé juste le deuxième événement

      // si date_fin > date début, l'événement déborde seulement sur le dernier jour
      // On créé juste le premier événement

      const newDateDebut = format(
        add(new Date(newEventInterval.end).setHours(0, 0), { days: 1 }),
        "yyyy-MM-dd '00:00:00.000'",
        { locale: fr }
      );

      const newDateFin = format(
        sub(new Date(newEventInterval.start).setHours(0, 0), { days: 1 }),
        "yyyy-MM-dd '00:00:00.000'",
        { locale: fr }
      );

      this.overlapsOnlyOnFirstDay = compareAsc(
        new Date(newDateFin).setHours(0, 0),
        new Date(event.date_debut).setHours(0, 0)
      );
      this.overlapsOnlyOnLastDay = compareAsc(
        new Date(event.date_fin).setHours(0, 0),
        new Date(newDateDebut).setHours(0, 0)
      );

      ev1 = {
        planningEventFromDate: event.date_debut,
        planningEventToDate: newDateFin,
        reason: event.raison,
        patch: false,
      };

      ev2 = {
        planningEventFromDate: newDateDebut,
        planningEventToDate: event.date_fin,
        reason: event.raison,
        patch: false,
      };
    });

    if (this.overlapsOnlyOnFirstDay === -1) {
      this.patchEventBodies.push(ev2);
    } else if (this.overlapsOnlyOnLastDay === -1) {
      this.patchEventBodies.push(ev1);
    } else {
      if (ev1 && ev2) {
        this.patchEventBodies.push(ev1, ev2);
      }
    }
  }

  createPlanningEvent() {
    if (this.planningEventFromDate?.value && this.planningEventToDate?.value) {
      if (this.hasConflict) {
        this.eventsToDelete.forEach((event) => {
          this.deletePlaningEventEvent.emit(event.id);
        });

        this.patchEventBodies.forEach((event) => {
          this.createPlanningEventEvent.emit(event);
        });

        // L'événement patché est supprimé, vu qu'il a été remplacé par la création d'1 ou 2 événements
        this.eventsToPatch.forEach((event) => {
          this.deletePlaningEventEvent.emit(event.id);
        });
      }


      if (this.reason === 'STUDIO') {
        this.planningEventForm.controls['planningEventToDate'].setValue(this.planningEventFromDate.value)
      }
      this.createPlanningEventEvent.emit({
        planningEventFromDate: this.dateTransformService.transformDate(
          this.planningEventFromDate.value,
          this.reason === this.planningEventReasons.ON_SITE
            ? this.planningEventHour.value
            : undefined
        ),
        planningEventToDate: this.dateTransformService.transformDate(
          this.planningEventToDate?.value,
          this.reason === this.planningEventReasons.ON_SITE
            ? this.planningEventHour.value
            : undefined
        ),
        reason: this.reason,
        patch: this.patch,
        emailNotify: this.emailNotify.value,
      });
    }
  }

  closePlanningEventDialog() {
    this.planningEventForm.patchValue({
      planningEventFromDate: this.selectedDay,
      planningEventToDate: this.selectedDay,
      emailNotify: false,
    });
    this.closePlanningEventDialogEvent.emit(false);
  }

  get planningEventFromDate() {
    return this.planningEventForm.controls['planningEventFromDate'];
  }

  get planningEventToDate() {
    return this.planningEventForm.controls['planningEventToDate'];
  }

  get planningEventHour() {
    return this.planningEventForm.controls['planningEventHour'];
  }

  get emailNotify() {
    return this.planningEventForm.controls['emailNotify'];
  }
}
