import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbTooltip} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-daily-status',
  standalone: true,
  imports: [CommonModule, NgbTooltip],
  templateUrl: './daily-status.component.html',
  styleUrls: ['./daily-status.component.scss']
})
export class DailyStatusComponent {
  @Input() day: string = '';
  @Input() status!: string;

}
