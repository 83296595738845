<div class="row">
  <div class="col-6">
    <ngb-pagination
      [(page)]="this.page"
      [pageSize]="this.limit"
      [maxSize]="10"
      (pageChange)="this.changePage()"
      [collectionSize]="this.collectionSize"
    ></ngb-pagination>
  </div>
  <div class="col-2 offset-4">
    <div class="form-floating">
      <select
        #pageSizeLimit
        name=""
        id=""
        class="form-select"
        [(ngModel)]="this.limit"
        (change)="this.changeLimit(pageSizeLimit.value)"
      >
        <option value="10" [selected]="this.collectionSize === 10">
          {{ this.collectionSize < 10 ? this.collectionSize : 10 }} /
          {{ this.collectionSize }}
        </option>
        <option *ngIf="this.collectionSize > 10" [selected]="this.collectionSize > 10" value="20">
          {{ this.collectionSize < 20 ? this.collectionSize : 20 }} /
          {{ this.collectionSize }}
        </option>
        <option *ngIf="this.collectionSize > 20" [selected]="this.collectionSize > 20" value="50">
          {{ this.collectionSize < 50 ? this.collectionSize : 50 }} /
          {{ this.collectionSize }}
        </option>
        <option *ngIf="this.collectionSize > 50" [selected]="this.collectionSize > 50" value="100">
          {{ this.collectionSize < 100 ? this.collectionSize : 100 }} /
          {{ this.collectionSize }}
        </option>
        <option *ngIf="this.collectionSize > 100" [selected]="this.collectionSize > 100" [value]="this.collectionSize">
          Voir les {{ this.collectionSize }} résultats
        </option>
      </select>
      <label for="">Nb. de résultats</label>
    </div>
  </div>
</div>
