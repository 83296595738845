/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';

import { AppComponent } from './app/app.component';
import { appRouteList } from './app/app-routing.module';
import { provideRouter, withHashLocation } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { importProvidersFrom } from '@angular/core';
import { setDefaultOptions } from 'date-fns';
import { fr } from 'date-fns/locale';


export function tokenGetter(): string | null {
  return localStorage.getItem('jwt')
}

setDefaultOptions({ locale: fr });

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(appRouteList, withHashLocation()),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: [
            'localhost',
            'atsapp.ats-dev.fr',
            'atsapp.ats-studios.fr',
            'atsapp.ats-preprod.fr',
            'client.ats-preprod.com',
            'client.ats-studios.com'
          ],
        },
      })
    ),
  ],
}).catch((err) => console.error(err));
