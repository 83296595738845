<div class="textslist">
  <div *ngFor="let text of this.textsList; let index = index">
    <app-text-list-item
      [itemIndex]="index"
      [detailsCollapsed]="this.currentItemShowing !== index"
      [textDetails]="text"
      [todo]="this.todo"
      [phasesVocales]="this.phasesVocales"
      [standbyTypes]="this.standbyTypes"
      (showDetailsEvent)="this.showDetails($event)"
      (showCommentDialogEvent)="this.onShowCommentDialog($event)"
      (showStandbyDialogEvent)="this.onShowStandbyDialog($event)"
      (showTranslateDialogEvent)="this.onShowTranslateDialog($event.translation, $event.translatedText)"
    />
  </div>
</div>
