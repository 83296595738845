<div class="userpicture userpicture--{{this.size}}" [ngClass]="{'userpicture--collapsed': this.collapsed}">
    <div *ngIf="this.status" class="userpicture__status">
        <app-status-badge [status]="this.status" [size]="this.size" />
    </div>
    <!-- @TODO A décommenter quand on pourra utiliser les vraies photos -->
    <img *ngIf="this.picture !== '' && this.picture" [src]="'assets' + picture" alt="" class="userpicture__picture">
    <div *ngIf="this.picture === '' || !this.picture" class="userpicture__nopicture">
        <span>
            {{ this.firstName | slice: 0 : 1}}{{ this.lastName | slice: 0 : 1}}
        </span>
    </div>

    <!-- @TODO A supprimer quand on pourra utiliser les vraies photos -->
    <!-- <div class="userpicture__nopicture">
        <span>
            {{ this.firstName | slice: 0 : 1}}{{ this.lastName | slice: 0 : 1}}
        </span>
    </div> -->
</div>
