import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeakersListComponent } from 'projects/speaker-platform/src/app/organisms/speakers-list/speakers-list.component';
import { AtsappService } from '@common/services/atsapp.service';
import { Speaker } from 'projects/speaker-platform/src/app/types/speaker';
import { SpeakerEditContactdetailsComponent } from 'projects/speaker-platform/src/app/organisms/admin/speaker-edit-contactdetails/speaker-edit-contactdetails.component';
import { NgbNavChangeEvent, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SpeakerEditPresentationComponent } from 'projects/speaker-platform/src/app/organisms/admin/speaker-edit-presentation/speaker-edit-presentation.component';
import { SpeakerEditCategoriesComponent } from 'projects/speaker-platform/src/app/organisms/admin/speaker-edit-categories/speaker-edit-categories.component';
import { SpeakerEditParametersComponent } from 'projects/speaker-platform/src/app/organisms/admin/speaker-edit-parameters/speaker-edit-parameters.component';
import { FormsModule } from '@angular/forms';
import { DialogComponent } from 'projects/speaker-platform/src/app/molecules/dialog/dialog.component';
import { ChoixCod } from 'projects/speaker-platform/src/app/types/tablettes';
import { ToastService } from 'projects/speaker-platform/src/app/services/toast.service';
import { PostStatesComponent } from 'projects/speaker-platform/src/app/molecules/post-states/post-states.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from '@common/services/user.service';

@Component({
  selector: 'app-speaker-edit',
  standalone: true,
  imports: [
    CommonModule,
    SpeakersListComponent,
    SpeakerEditContactdetailsComponent,
    SpeakerEditPresentationComponent,
    SpeakerEditCategoriesComponent,
    SpeakerEditParametersComponent,
    DialogComponent,
    NgbNavModule,
    FormsModule,
    PostStatesComponent,
    RouterModule,
  ],
  templateUrl: './speaker-edit.component.html',
  styleUrls: ['./speaker-edit.component.scss'],
})
export class SpeakerEditComponent implements OnInit, OnDestroy {
  constructor(
    private atsappService: AtsappService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}

  @ViewChild('editContactDetails')
  editContactDetails!: SpeakerEditContactdetailsComponent;
  @ViewChild('editPresentation')
  editPresentation!: SpeakerEditPresentationComponent;
  @ViewChild('editCategories')
  editCategories!: SpeakerEditCategoriesComponent;
  @ViewChild('editParameters')
  editParameters!: SpeakerEditParametersComponent;

  isLoading: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  disablePosting: boolean = false;
  disablePostingOver: boolean = false;
  disablePostingError: { errorCode: number; message: string } | null = null;

  speakersList: Speaker[] = [];
  filteredSpeakersList: Speaker[] = [];
  filterSpeakersListQuery: string = '';
  filterSpeakerListGenre: string = '';
  filterSpeakerLang: string = '';

  isCurrentFormPristine: boolean = true;

  currentSpeaker!: Speaker;

  zin: ChoixCod[] = [];
  ztb: ChoixCod[] = [];
  zav: ChoixCod[] = [];
  lgu: ChoixCod[] = [];
  zds: ChoixCod[] = [];

  active: number = 1;
  nextActive: number = 0;

  providedCodeSpeak: string | null = null;

  confirmationDialogVisible: boolean = false;
  disableSpeakerDialogVisible: boolean = false;


  ngOnInit(): void {
    this.getSpeakers(true);

    this.route.queryParams
      .pipe(filter((params) => params['codespeak']))
      .subscribe((params) => {
        this.providedCodeSpeak = params['codespeak'];
      });

    this.atsappService
      .getChoixCod('ZIN')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.zin = res;
        },
        error: () => {
          this.toastService.show(
            'Le chargement des interprétations à échoué',
            'danger'
          );
        },
      });
    this.atsappService
      .getChoixCod('ZTB')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.ztb = res;
        },
        error: () => {
          this.toastService.show('Le chargement des timbres de voix', 'danger');
        },
      });
    this.atsappService
      .getChoixCod('ZAV')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.zav = res;
        },
        error: () => {
          this.toastService.show(
            'Le chargement des âges voix à échoué',
            'danger'
          );
        },
      });
    this.atsappService
      .getChoixCod('LGU')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          // Français / Anglais / Allemand / Italien / Espagnol en premier dans la liste
          const firsts = ['FRF', 'ANG', 'ALL', 'ITA', 'ESP'];

          firsts.forEach((code: string) => {
            const index = res.findIndex((cc: ChoixCod) => cc.cc_code === code);
            this.lgu.push(res[index]);
          });

          // Ajouter le reste des langues non listées
          this.lgu.push(
            ...res.filter((o: ChoixCod) => !firsts.includes(o.cc_code))
          );
        },
        error: (error) => {
          this.toastService.show(
            'Le chargement des langues à échoué',
            'danger'
          );
        },
      });

    this.atsappService
      .getChoixCod('ZDS')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.zds = res;
        },
        error: () =>
          this.toastService.show(
            'Le chargement de la tablette ZDS a échoué',
            'danger'
          ),
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getSpeakers(created: boolean) {
    this.atsappService
      .getSpeakers(false)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.speakersList = res;
          this.filteredSpeakersList = res;
          this.currentSpeaker = res[0];

          if (this.providedCodeSpeak && created) {
            this.currentSpeaker = res.filter(
              (spk: Speaker) => spk.codespeak === this.providedCodeSpeak
            )[0];
          }
        },
      });
  }

  saveCurrentForm() {
    if (this.active === 1) {
      this.editContactDetails.saveContactDetails();
    } else if (this.active === 2) {
      this.editPresentation.savePresentation();
    } else if (this.active === 3) {
      this.editCategories.saveCategories();
    } else if (this.active === 4) {
      this.editParameters.saveParameters();
    }
  }

  resetCurrentForm() {
    if (this.active === 1) {
      this.editContactDetails.resetForm();
    } else if (this.active === 2) {
      this.editPresentation.resetForm();
    } else if (this.active === 3) {
      this.editCategories.resetForm();
    } else if (this.active === 4) {
      this.editParameters.resetForm();
    }
  }

  findSpeaker() {
    this.filteredSpeakersList = this.speakersList.filter((spk: Speaker) => {
      const filterQuery = this.filterSpeakersListQuery.toLowerCase();
      const matchName =
        (spk.nom && spk.nom.toLowerCase().indexOf(filterQuery) > -1) ||
        spk.prenom.toLowerCase().indexOf(filterQuery) > -1;

      // Vérifiez si le filtre de langue correspond
      const matchLanguage =
        !this.filterSpeakerLang ||
        (spk.langue1 &&
          spk.langue1.toLowerCase() === this.filterSpeakerLang.toLowerCase()) ||
        (spk.langue2 &&
          spk.langue2.toLowerCase() === this.filterSpeakerLang.toLowerCase());

      // Vérifiez si le filtre de genre correspond
      const matchGender =
        !this.filterSpeakerListGenre ||
        (spk.genre &&
          spk.genre.toLowerCase() ===
            this.filterSpeakerListGenre.toLowerCase());

      // Retournez true si tous les filtres correspondent
      return matchName && matchLanguage && matchGender;
    });
  }

  selectSpeaker(event: Speaker) {
    this.atsappService
      .getSpeakerById(event.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.currentSpeaker = res;
        },
        complete: () => {
          this.resetCurrentForm();
        },
      });
  }

  patchSpeaker(body: any) {
    this.isLoading = true;
    this.atsappService
      .patchSpeaker(body, this.currentSpeaker.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.isLoading = false;
          this.toastService.show(error.error.message, 'danger');
        },
        complete: () => {
          this.isLoading = false;
          this.toastService.show(
            'Le profil a été modifié avec succès',
            'success'
          );
          this.selectSpeaker(this.currentSpeaker);
        },
      });
  }

  onSaveContactDetails(event: any) {
    this.patchSpeaker(event);
  }

  onSavePresentation(event: any) {
    this.patchSpeaker(event);
  }

  onSaveCategories(event: any) {
    this.patchSpeaker(event);
  }

  onSaveParameters(event: any) {
    this.patchSpeaker(event);
  }

  onTabChange(event: NgbNavChangeEvent) {
    if (!this.isCurrentFormPristine) {
      this.confirmationDialogVisible = true;
      this.nextActive = event.nextId;
      event.preventDefault();
      return;
    }
  }

  onDisableSpeaker() {
    this.disableSpeakerDialogVisible = true;
  }

  onPristineChange(event: boolean) {
    this.isCurrentFormPristine = event;
  }

  disableSpeaker() {
    this.disablePosting = true;
    this.atsappService
      .disableSpeaker(this.currentSpeaker.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.disablePostingError = {
            errorCode: error.error.code,
            message: error.error.message,
          };
          this.disablePosting = false;
        },
        complete: () => {
          this.disablePostingError = null;
          this.disablePosting = false;
          this.disablePostingOver = true;
          this.getSpeakers(false);
          this.filterSpeakersListQuery = '';
          this.active = 1;
        },
      });
  }
}
