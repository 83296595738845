import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from '@common/components/loader/loader.component';
import { environment } from 'projects/speaker-platform/src/environments/environment';
import { Constants } from '@common/config/constants';
import { AtsappService } from '@common/services/atsapp.service';

@Component({
  selector: 'app-speaker-edit-presentation',
  standalone: true,
  imports: [
    CommonModule,
    ImageCropperModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
  ],
  templateUrl: './speaker-edit-presentation.component.html',
  styleUrls: ['./speaker-edit-presentation.component.scss'],
})
export class SpeakerEditPresentationComponent implements OnChanges, OnInit {
  constructor(
    private fb: FormBuilder,
    protected constants: Constants,
    private atsappService: AtsappService
  ) {}

  @ViewChild('medleyFileInput') medleyFileInput!: ElementRef;

  @Output() savePresentationEvent = new EventEmitter();
  @Output() isPristine = new EventEmitter<boolean>();

  @Input() medley: string | null = '';
  @Input() photo: string | null = '';
  @Input() biographie: string | null = '';
  @Input() isLoading: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() codespeak: string = '';

  env = environment;

  imageChangedEvent: any = '';
  croppedImage: any = '';

  medleyFile: any = null;
  medleyObjectURL: any = '';
  medleyError: string | null = null;
  medleyLoaded: boolean = false;

  editSpeakerPresentation = this.fb.group({
    medley: [this.medley],
    photo: [null],
    biographie: [this.biographie],
  });

  ngOnInit(): void {
    if (this.readOnly) {
      this.editSpeakerPresentation.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['codespeak']) {
      this.loadMedley();
    }
    this.croppedImage = null;
    this.imageChangedEvent = null;
    // this.medleyFileInput.nativeElement.value = this.medley;
    this.editSpeakerPresentation.patchValue({
      medley: this.medley,
      photo: null,
      biographie: this.biographie,
    });
  }

  loadMedley() {
    this.medleyError = null;
    this.medleyLoaded = false;
    this.medleyObjectURL = null;
    if (this.medley === '') {
      this.medleyLoaded = true;
      return;
    }
    this.atsappService.getMedley(this.codespeak).subscribe({
      next: (res: any) => {
        this.medleyObjectURL = res;
        this.medleyLoaded = true;
      },
      error: (err) => {
        this.medleyLoaded = false;
        if (err.status === 412) {
          this.medleyError = `Le medley est introuvable. Vous pouvez en ajouter un nouveau ci-dessous`;
        } else if (err.status === 500) {
          this.medleyError = `Il y a eu une erreur lors de la récupération du medley. Essayez de recharger la page ou contactez le service informatique`;
        }
      },
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.editSpeakerPresentation.markAsDirty();
  }

  imageCropped(event: ImageCroppedEvent) {
    const reader = new FileReader();

    if (event.blob) {
      reader.readAsDataURL(event.blob);
      reader.onload = () => {
        this.croppedImage = reader.result;
        if (event.blob) {
          //@ts-expect-error
          this.editSpeakerPresentation.patchValue({ photo: event.blob });
        }
      };
    }
  }

  saveMedleyFile(event: any) {
    const reader = new FileReader();
    const target = event.target as HTMLInputElement;

    if (target.files && target.files.length) {
      const file = target.files[0];
      this.medleyObjectURL = URL.createObjectURL(file);

      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        this.editSpeakerPresentation.patchValue({
          //@ts-expect-error
          medley: new File([reader.result], file.name, { type: file.type }),
        });
      };

      this.editSpeakerPresentation.controls['medley'].markAsDirty();
    }

    this.editSpeakerPresentation.patchValue({ medley: event.target.files[0] });
    this.editSpeakerPresentation.markAsDirty();
  }

  resetForm() {
    this.editSpeakerPresentation.reset({
      medley: this.medley,
      photo: null,
      biographie: this.biographie,
    });
    this.medleyFileInput.nativeElement.value = null;
    this.updatePristineStatus();
  }

  savePresentation() {
    const formData = new FormData();

    for (let [k, v] of Object.entries(this.editSpeakerPresentation.value)) {
      if (v !== null) {
        formData.append(k, v);
      }
    }

    this.savePresentationEvent.emit(formData);
  }

  updatePristineStatus() {
    this.isPristine.emit(this.editSpeakerPresentation.pristine);
  }
}
