import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnChanges {
  @Input() title: string = '';
  @Input() visibility: boolean = false;
  @Input() customWidth: number = 600;
  @Input() largeContent: boolean = false;
  @Output() closeDialogEvent = new EventEmitter<boolean>();
  @ViewChild('dialog') dialog!: ElementRef;
  @ViewChild('dialogFooter') dialogFooter!: ElementRef;
  @ViewChild('dialogWrapper') dialogWrapper!: ElementRef;

  hasFooterContent: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dialogFooter) {
      this.hasFooterContent =
        this.dialogFooter.nativeElement.children.length > 0 ? true : false;
    }
  }

  clickOutsideClose(e: any) {
    e.target.classList.contains('dialog--visible') && this.closeDialog();
  }

  closeDialog(): void {
    this.dialog.nativeElement.classList.add('dialog--out');
    this.closeDialogEvent.emit(false);
    window.setTimeout(() => {
      this.dialog.nativeElement.classList.remove('dialog--out');
    }, 300);
  }
}
