import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AbstractTextComponent} from "../../common/abstract/abstract-texts.component";
import {DateTransformService} from "../../services/helpers/date-transform.service";
import {CcLibelleService} from "../../services/helpers/cc-libelle-service.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AtsappService} from "../../../../../common/services/atsapp.service";
import {CommonModalService} from "../../services/helpers/common-modal.service";

@Component({
  selector: 'app-text-focus',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-focus.component.html',
  styleUrls: ['./text-focus.component.scss']
})
export class TextFocusComponent extends AbstractTextComponent implements AfterViewInit, OnDestroy{

  @ViewChild('testDbClick') templateTextToFocus: TemplateRef<any> | undefined;
  constructor(
    atsappService: AtsappService,
    dateTransformService: DateTransformService,
    ccLibelleService: CcLibelleService,
    private modalService: NgbModal,
    private commomModal: CommonModalService
  ) {
    super(atsappService, dateTransformService, ccLibelleService);
  }

  show : boolean = false;
  textFocusToDisplay: any ="";


  ngAfterViewInit() {
    this.getPhasesVocale()
    this.commomModal.showModalFocus.subscribe((show : any) => {
      this.show = show;
    })
    this.show ? this.openModal() : null;
  }

  ngOnDestroy() {
    this.show = false;
  }

  openModal() {
    this.textFocusToDisplay = this.commomModal.textFocused
    this.modalService.open(this.templateTextToFocus, { size: 'xl', scrollable: true , centered: true}).dismissed.subscribe(() => {
      this.textFocusToDisplay = ""
      this.commomModal.closeFocusText();
    })
  }
}
