import { Injectable } from '@angular/core';
import { Speaker } from 'projects/speaker-platform/src/app/types/speaker';

@Injectable({
  providedIn: 'root',
})
export class SpeakerService {
  constructor() {}

  getSpeakerFromCode(code: string, speakersList: Speaker[]): Speaker | null {
    if (code !== '') {
      return speakersList.filter(
        (spk: Speaker) => spk.codespeak === code
      )[0];
    }

    return null;
  }

  getSpeakerFromTrigramme(trigramme: string, speakersList: Speaker[]): Speaker | null {
    if (trigramme !== '') {
      return speakersList.filter(
        (spk: Speaker) => spk.trigramme === trigramme
      )[0];
    }

    return null;
  }
}
