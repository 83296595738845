<div
  #dialog
  class="dialog"
  [ngClass]="{ 'dialog--visible': this.visibility }"
  [style]="{ alignItems: this.largeContent ? 'flex-start' : 'center' }"
  (click)="this.clickOutsideClose($event)"
>
  <div #dialogWrapper class="dialog__wrapper" [style]="{ width: this.customWidth + 'px' }">
    <div class="dialog__header">
      <h4>{{ this.title }}</h4>
      <button class="btn btn-invisible" (click)="this.closeDialog()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div class="dialog__body">
      <ng-content></ng-content>
    </div>
    <div
      #dialogFooter
      class="dialog__footer"
      [ngClass]="{ 'dialog__footer--hascontent': this.hasFooterContent }"
    >
      <ng-content select="[dialog-footer]"></ng-content>
    </div>
  </div>
</div>
