import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Sizes } from 'projects/speaker-platform/src/app/types/sizes.enum';
import { PlanningEventReasons } from '../../types/planning-event-reasons.enum';

@Component({
  selector: 'app-status-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss']
})

export class StatusBadgeComponent {
  @Input() status!: PlanningEventReasons;
  @Input() size: Sizes = Sizes.DEFAULT;
  planningEventReasons = PlanningEventReasons
}
