<ng-template #testDbClick let-modal>
  <div class="card p-2">
    <div class="d-flex pt-1 justify-content-between align-items-center">
      <h3 class="card-title">Information texte : </h3>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <hr>
    <div class="row mt-3 justify-content-around">
      <div class="col-2 infoText">
        <div class="subtitle">
          <i class="fa-regular fa-file-lines pe-2"></i> Reprise :
        </div>
        <div class="content-main"> {{textFocusToDisplay.ZMS_NUMERO}}</div>
      </div>
      <div class="col-2 infoText">
        <div class="subtitle">
          <i class="fa-solid fa-dolly pe-2"></i>
          Livraison :
        </div>
        <div class="content-main"> {{textFocusToDisplay.ZMS_LIVRAISON}}</div>
      </div>
      <div class="col-2 infoText">
        <div class="subtitle">
          <i class="fa-solid fa-envelope pe-2"></i> Message :
        </div>
        <div class="content-main"> {{textFocusToDisplay.ZMS_MESSAGE}}</div>
      </div>
      <div class="col-3 infoText">
        <div class="subtitle">
          <i class="fa-solid fa-microphone pe-1"></i> Phase vocale :
        </div>
        <div class="content-main"> {{this.getLibelles(textFocusToDisplay.ZMS_PHASEVOCALE, this.phasesVocalesList) }}</div>
      </div>
    </div>

    <div class="row mt-5 justify-content-around">
      <div class="col-5 infoText">
        <div class="subtitle">
          <i class="fa-solid fa-shop pe-2"></i> Client final :
        </div>
        <div class="content-main">
          {{textFocusToDisplay.CLIENT_FINAL}} - {{ textFocusToDisplay.LIBELLE_CLIENT_FINAL}}
        </div>
      </div>
      <div class="col-5 infoText">
        <div class="subtitle">
          <i class="fa-solid fa-user-tie pe-2"></i> Client reprise :
        </div>
        <div class="content-main">
          {{textFocusToDisplay.T_TIERS}} - {{ textFocusToDisplay.T_LIBELLE}}
        </div>
      </div>
    </div>

    <div class="row mt-5 justify-content-around">
      <div class="col-11 infoText">
        <div class="subtitle">
          <i class="fa-regular fa-circle-question pe-2"></i> Guide :
        </div>
        <div class="content-main">{{textFocusToDisplay.ZMS_GUIDE}}</div>
      </div>
    </div>

    <div *ngIf="textFocusToDisplay.ZMS_PVPHONETIQUE !== null && textFocusToDisplay.ZMS_PVPHONETIQUE !== '' && textFocusToDisplay.ZMS_PVPHONETIQUE" class="row mt-5 justify-content-around">
      <div class="col-11 infoText">
        <div class="subtitle">
          <i class="fa-solid fa-headphones pe-2"></i> Aide phonétique :
        </div>
        <div>{{textFocusToDisplay.ZMS_PVPHONETIQUE}}</div>
      </div>
    </div>

    <div class="row mt-5 mb-3 justify-content-around">
      <div class="col-11 infoText">
        <div class="subtitle">
          <i class="fa-regular fa-message pe-2"></i> Texte (#{{textFocusToDisplay.RANGPV}}) :
        </div>
        <div class="content-text">{{textFocusToDisplay.ZMS_PVTEXTE}}</div>
      </div>
    </div>


  </div>
</ng-template>
