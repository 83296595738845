import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [CommonModule, NgbPagination, FormsModule],
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() page: number = 1;
  @Input() limit: number = 10;
  @Input() collectionSize: number = 0;

  @Output() changePageEvent = new EventEmitter<number>();
  @Output() changeLimitEvent = new EventEmitter<number>();

  changePage(): void {
    this.changePageEvent.emit(this.page);
  }

  changeLimit(limit: string): void {
    this.limit = Number(limit);
    this.changeLimitEvent.emit(this.limit);
  }
}
