import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ImageCropperModule, ImageCroppedEvent } from 'ngx-image-cropper';
import { AtsappService } from '@common/services/atsapp.service';
import { ChoixCod } from 'projects/speaker-platform/src/app/types/tablettes';
import { Speaker } from 'projects/speaker-platform/src/app/types/speaker';
import { ToastService } from 'projects/speaker-platform/src/app/services/toast.service';
import { LoaderComponent } from '@common/components/loader/loader.component';
import { availabilityValidator } from 'projects/speaker-platform/src/app/common/validators/availability.validator';
import { trigrammeValidator } from 'projects/speaker-platform/src/app/common/validators/trigramme.validator';
import { Event, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-speaker-create',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    LoaderComponent,
  ],
  templateUrl: './speaker-create.component.html',
  styleUrls: ['./speaker-create.component.scss'],
})
export class SpeakerCreateComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private atsappService: AtsappService,
    private toastService: ToastService,
    private router: Router
  ) {}

  @ViewChild('photoInput') photoInput!: ElementRef;
  photoPreview: string | ArrayBuffer | null = '';

  PASSWORD_LENGTH: number = 12;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  generatedPassword: string = '';

  recordDays: string[] = [];
  interpretations: string[] = [];
  timbre: string[] = [];
  voiceAge: string[] = [];
  domaine: string[] = [];

  zin: ChoixCod[] = [];
  ztb: ChoixCod[] = [];
  zav: ChoixCod[] = [];
  lgu: ChoixCod[] = [];
  zds: ChoixCod[] = [];

  isLoading: boolean = false;
  isSuccess: boolean = false;
  hasError: boolean = false;

  codes: string[] = [];
  trigrammes: string[] = [];

  firstNameForValidation: string = '';
  lastNameForValidation: string = '';

  destroy$: Subject<boolean> = new Subject<boolean>();

  createSpeakerForm = this.fb.group({
    prenom: new FormControl('', Validators.required),
    nom: new FormControl('', Validators.required),
    genre: new FormControl('', Validators.required),
    adresse1: new FormControl(''),
    adresse2: new FormControl(''),
    codepostal: new FormControl(''),
    ville: new FormControl(''),
    telephone: new FormControl(''),
    mobilespeak: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    medley: new FormControl(null),
    photo: new FormControl(null),
    trigramme: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(3),
      availabilityValidator(this.trigrammes),
      trigrammeValidator(
        this.firstNameForValidation,
        this.lastNameForValidation
      ),
    ]),
    disponibilite: new FormControl(''),
    nbtxtpv: new FormControl(null, Validators.required),
    nbpvjour: new FormControl(null, Validators.required),
    traductrice : new FormControl(false),
    onair: new FormControl(false),
    nbonairjour: new FormControl(null),
    interpretation: new FormControl(''),
    timbrevoix: new FormControl(''),
    agevoix: new FormControl(null),
    codespeak: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(3),
      availabilityValidator(this.codes),
    ]),
    langue1: new FormControl('', Validators.required),
    langue2: new FormControl(''),
    domaine: new FormControl(''),
    biographie: new FormControl(''),
  });

  ngOnInit(): void {
    this.atsappService
      .getChoixCod('ZIN')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.zin = res;
        },
        error: () => {
          this.toastService.show(
            'Le chargement des interprétations a échoué',
            'danger'
          );
        },
      });
    this.atsappService
      .getChoixCod('ZTB')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.ztb = res;
        },
        error: () => {
          this.toastService.show(
            'Le chargement des timbres de voix a échoué',
            'danger'
          );
        },
      });
    this.atsappService
      .getChoixCod('ZAV')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.zav = res;
        },
        error: () => {
          this.toastService.show(
            'Le chargement des âges voix a échoué',
            'danger'
          );
        },
      });
    this.atsappService
      .getChoixCod('LGU')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          // Français / Anglais / Allemand / Italien / Espagnol en premier dans la liste
          const firsts = ['FRF', 'ANG', 'ALL', 'ITA', 'ESP'];

          firsts.forEach((code: string) => {
            const index = res.findIndex((cc: ChoixCod) => cc.cc_code === code);
            this.lgu.push(res[index]);
          });

          // Ajouter le reste des langues non listées
          this.lgu.push(
            ...res.filter((o: ChoixCod) => !firsts.includes(o.cc_code))
          );
        },
        error: (error) => {
          this.toastService.show(
            'Le chargement des langues a échoué',
            'danger'
          );
        },
      });

    this.atsappService
      .getChoixCod('SPK')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          res.forEach((spk: ChoixCod) => {
            this.codes.push(spk.cc_code);
            this.trigrammes.push(spk.cc_abrege);
          });
        },
        error: (error) => {
          this.toastService.show(
            'Le chargement de la tablette SPK a échoué',
            'danger'
          );
        },
      });

    this.atsappService
      .getChoixCod('ZDS')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.zds = res;
        },
        error: () =>
          this.toastService.show(
            'Le chargement de la tablette ZDS a échoué',
            'danger'
          ),
      });

    this.atsappService
      .getZappauth()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          res.forEach((zappauth: { utilisateur: string }) => {
            this.trigrammes.push(zappauth.utilisateur);
          });
        },
        error: (error) => {
          this.toastService.show(
            'Le chargement des trigrammes ZAPPAUTH a échoué',
            'danger'
          );
        },
      });

    this.atsappService
      .getSpeakers(true)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          res.forEach((spk: Speaker) => {
            this.codes.push(spk.codespeak);
            this.trigrammes.push(spk.trigramme);
          });
          this.prefillCodespeak();
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  removeTrigrammeValidator() {
    this.trigramme.removeValidators(
      trigrammeValidator(
        this.firstNameForValidation,
        this.lastNameForValidation
      )
    );
    this.trigramme.updateValueAndValidity();
  }

  updateTrigrammeValidator() {
    this.trigramme.addValidators(
      trigrammeValidator(
        this.firstNameForValidation,
        this.lastNameForValidation
      )
    );
    this.trigramme.updateValueAndValidity();
  }

  prefillTrigramme() {
    const prenom = this.prenom.value;
    const nom = this.nom.value;
    const secondLayerKeys: string[] = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];

    // Vérifier si le prénom et le nom existent
    if (prenom && nom) {
      const firstLetter = prenom.slice(0, 1).toUpperCase();
      const secondLetter = nom.slice(0, 1).toUpperCase();

      // Evite les doublons avec la création d'un set
      const trigrammes = [...new Set(this.trigrammes)];
      // Filtrer les trigrammes correspondant aux initiales
      const matchingTrigrammes = trigrammes.filter((trigramme: string) => {
        return (
          trigramme.slice(0, 2).toUpperCase() === firstLetter + secondLetter
        );
      });

      // Extraire les clés numériques des trigrammes existants
      const existingTrigrammeKeys = matchingTrigrammes
        .map((trigramme: string) => {
          return parseInt(trigramme.slice(2), 10);
        })
        .filter((key) => !isNaN(key));

      // Si les clés ne sont pas des numbers
      const existingTrigrammeSecondLayerKeys = matchingTrigrammes
        .map((trigramme: string) => {
          return trigramme.slice(2, 3);
        })
        .filter((key) => isNaN(Number(key)));

      // Trouver la clé numérique la plus grande
      let lastTrigrammeKey = Math.max(...existingTrigrammeKeys, 0);
      let newTrigramme: string = '';

      // Vérifier si la clé est supérieure à 9 pour passer aux second layer keys
      if (lastTrigrammeKey >= 9) {
        // On prend le premier secondLayerKey
        const availableSecondLayerKey =
          secondLayerKeys[
            secondLayerKeys.findIndex(
              (key) => !existingTrigrammeSecondLayerKeys.includes(key)
            )
          ];
        newTrigramme = firstLetter + secondLetter + availableSecondLayerKey;
      } else {
        // Créer le nouveau trigramme en utilisant la clé numérique la plus grande + 1
        newTrigramme = firstLetter + secondLetter + (lastTrigrammeKey + 1);
      }

      // Mettre à jour la valeur du trigramme dans le formulaire
      this.trigramme.patchValue(newTrigramme);
    }
  }

  prefillCodespeak() {
    const numCodes: number[] = [];
    let newCode: string = '';
    // Evite les doublons avec la création d'un set
    const codes = [...new Set(this.codes)];

    codes.forEach((code: string) => {
      if (!isNaN(Number(code))) {
        numCodes.push(Number(code));
      }
    });

    numCodes.sort((a, b) => a - b);

    let nextSlot = -1;
    // Offset pour ne pas avoir un ID = OOO
    let offset = numCodes[0];
    for (let i = 0; i <= numCodes.length; i++) {
      if (numCodes[i] !== offset) {
        nextSlot = offset;
        break;
      }
      offset++;
    }

    if (nextSlot === -1) {
      nextSlot = numCodes[numCodes.length - 1] + 1;
    }

    const nextSlotStr = nextSlot.toString();

    if (nextSlotStr.length < 3) {
      let padding = new Array(3 - nextSlotStr.length + 1).join('0');
      newCode = padding + nextSlotStr;
    } else {
      newCode = nextSlotStr;
    }

    this.codespeak.patchValue(newCode);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  async imageCropped(event: ImageCroppedEvent) {
    const reader = new FileReader();

    if (event.blob) {
      reader.readAsDataURL(event.blob);
      reader.onload = () => {
        this.croppedImage = reader.result;

        // Même condition qu'au dessus pour éviter que Typescript se mette à chialer
        if (event.blob) {
          //@ts-expect-error
          this.createSpeakerForm.patchValue({ photo: event.blob });
        }
      };
    }
  }

  saveMedleyFile(event: any) {
    this.medley.patchValue(event.target.files[0]);
  }

  saveMultiCheck(event: any, dataArray: string[], input: string) {
    if (event.target.checked) {
      dataArray.push(event.target.value);
    } else {
      const index = dataArray.indexOf(event.target.value);
      if (index > -1) {
        dataArray.splice(index, 1);
      }
    }

    this.createSpeakerForm.patchValue({ [input]: dataArray.toString() });
  }

  saveSpeaker() {
    const body = Object.fromEntries(
      Object.entries(this.createSpeakerForm.value).filter(([key, value]) => {
        return !key.includes('__') && value !== null && value !== '';
      })
    );

    // On envoie un FormData pour inclure les fichiers à upload
    const formData = new FormData();

    for (let [k, v] of Object.entries(body)) {
      if (v !== null) {
        //@ts-expect-error
        formData.append(k, v);
      }
    }

    this.isLoading = true;
    this.hasError = false;
    this.isSuccess = false;

    this.atsappService
      .createSpeaker(formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.isLoading = false;
          this.hasError = true;
          this.toastService.show(error.error.message, 'danger');
        },
        complete: () => {
          this.isLoading = false;
          this.hasError = false;
          this.toastService.show('Le profil a été créé avec succès', 'success');

          this.router.navigate([`/admin/modifier-profil`], {
            queryParams: { codespeak: this.codespeak.value },
          });
        },
      });
  }

  normalize(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  get codespeak() {
    return this.createSpeakerForm.controls['codespeak'];
  }

  get trigramme() {
    return this.createSpeakerForm.controls['trigramme'];
  }

  get prenom() {
    return this.createSpeakerForm.controls['prenom'];
  }

  get nom() {
    return this.createSpeakerForm.controls['nom'];
  }

  get medley() {
    return this.createSpeakerForm.controls['medley'];
  }
}
