<div class="cardwheader">
  <div
    class="cardwheader__header cardwheader__header--{{ this.color }}"
    [ngClass]="{ 'cardwheader__header--headeronly': this.headerOnly }"
  >
    {{ this.title }}
  </div>
  <div class="cardwheader__body" *ngIf="!this.headerOnly">
    <ng-content />
  </div>
</div>
