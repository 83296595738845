<div class="editcategories container" [formGroup]="this.editCategories">
  <div class="row mb-3">
    <div class="col-12">
      <h6>Interprétation</h6>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-12 d-flex flex-wrap">
      <div class="form-check me-3 mb-2" *ngFor="let interpretation of this.zin">
        <input
          class="form-check-input"
          type="checkbox"
          name="create-speaker-interpretation-{{
            this.normalize(interpretation.cc_abrege)
          }}"
          id="create-speaker-interpretation-{{
            this.normalize(interpretation.cc_abrege)
          }}"
          [value]="interpretation.cc_code"
          formControlName="__interpretation{{
            this.normalize(
              interpretation.cc_abrege.charAt(0).toUpperCase() +
                interpretation.cc_abrege.slice(1)
            )
          }}"
          (change)="
            this.updatePristineStatus();
            this.saveMultiCheck($event, this.interpretations, 'interpretation')
          "
        />
        <label
          class="form-check-label"
          for="create-speaker-interpretation-{{
            this.normalize(interpretation.cc_abrege)
          }}"
          >{{ interpretation.cc_libelle }}</label
        >
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <h6>Domaines</h6>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-12 d-flex">
      <div *ngFor="let zds of this.zds" class="form-check me-3 mb-2">
        <input
          class="form-check-input"
          type="checkbox"
          name="create-speaker-domain-{{ this.normalize(zds.cc_abrege) }}"
          id="create-speaker-domain-{{ this.normalize(zds.cc_abrege) }}"
          formControlName="__domaine{{
            this.normalize(
              zds.cc_abrege.charAt(0).toUpperCase() +
                zds.cc_abrege.slice(1).replaceAll(' ', '_')
            )
          }}"
          [value]="zds.cc_code"
          (change)="
            this.updatePristineStatus();
            this.saveMultiCheck($event, this.domain, 'domaine')
          "
        />
        <label class="form-check-label" for="__domaineOpo">{{
          zds.cc_libelle
        }}</label>
      </div>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <h6>Timbre</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex">
          <div class="form-check me-3" *ngFor="let timbrevoix of this.ztb">
            <input
              class="form-check-input"
              type="checkbox"
              name="create-speaker-timbre-{{
                this.normalize(timbrevoix.cc_abrege)
              }}"
              id="create-speaker-timbre-{{
                this.normalize(timbrevoix.cc_abrege)
              }}"
              [value]="timbrevoix.cc_code"
              formControlName="__timbre{{
                this.normalize(
                  timbrevoix.cc_abrege.charAt(0).toUpperCase() +
                    timbrevoix.cc_abrege.slice(1)
                )
              }}"
              (change)="
                this.updatePristineStatus();
                this.saveMultiCheck($event, this.timbre, 'timbrevoix')
              "
            />
            <label
              class="form-check-label"
              for="create-speaker-timbre-{{
                this.normalize(timbrevoix.cc_abrege)
              }}"
              >{{ timbrevoix.cc_libelle }}</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <h6>Âge</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex">
          <div class="form-check me-3" *ngFor="let agevoix of this.zav">
            <input
              class="form-check-input"
              type="checkbox"
              name="create-speaker-agevoix-{{
                this.normalize(agevoix.cc_abrege)
              }}"
              id="create-speaker-agevoix-{{
                this.normalize(agevoix.cc_abrege)
              }}"
              formControlName="__agevoix{{
                this.normalize(
                  agevoix.cc_abrege.charAt(0).toUpperCase() +
                    agevoix.cc_abrege.slice(1)
                )
              }}"
              [value]="agevoix.cc_code"
              (change)="this.saveMultiCheck($event, this.voiceAge, 'agevoix')"
            />
            <label
              class="form-check-label"
              for="create-speaker-agevoix-{{
                this.normalize(agevoix.cc_abrege)
              }}"
              >{{ agevoix.cc_libelle }}</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <h6>Langues</h6>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-12 d-flex">
      <div class="form-floating me-3">
        <select
          name="langue1"
          id="langue1"
          formControlName="langue1"
          class="form-select"
        >
          <option value="">Choix</option>
          <option *ngFor="let langue of this.lgu" [value]="langue.cc_code">
            {{ langue.cc_libelle }}
          </option>
        </select>
        <label for="form-label">Langue 1</label>
      </div>
      <div class="form-floating">
        <select
          name="langue2"
          id="langue2"
          formControlName="langue2"
          class="form-select"
        >
          <option value="">Choix</option>
          <option *ngFor="let langue of this.lgu" [value]="langue.cc_code">
            {{ langue.cc_libelle }}
          </option>
        </select>
        <label for="form-label">Langue 2</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="!this.editCategories.pristine" class="col-12 text-end">
      <button class="btn btn-danger me-2" (click)="this.resetForm()">
        Annuler les modifications
      </button>
      <button
        class="btn btn-success"
        (click)="this.saveCategories()"
        [disabled]="this.isLoading"
      >
        <span *ngIf="!this.isLoading"> Enregistrer </span>
        <span *ngIf="this.isLoading" class="d-flex">
          Modification en cours
          <div class="ms-3">
            <app-loader color="#fff" />
          </div>
        </span>
      </button>
    </div>
  </div>
</div>
