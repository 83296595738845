import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Constants } from '@common/config/constants';
import { Router } from '@angular/router';
import { UserService } from '@common/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private constants: Constants,
    private router: Router,
    private userService: UserService
  ) {}

  login(login: string, password: string) {
    return this.http.post(`${this.constants.ATSAPP_AUTH_API_URL}/login`, {
      utilisateur: login,
      password,
    });
  }

  logout() {
    localStorage.removeItem('jwt');
    //@ts-expect-error
    this.userService.user = undefined;
    this.router.navigate(['/login']);
  }

  resetPasswordRequest(mail: string) {
    return this.http.get(`${this.constants.ATSAPP_AUTH_API_URL}/forgotten/password/${mail}`);
  }

  resetForgottenPassword(token: string, password: string, organisation: any) {
    const headers: HttpHeaders = new HttpHeaders({
      'X-ATS-Organization': organisation,
    });
    return this.http.post(`${this.constants.ATSAPP_AUTH_API_URL}/resetpassword/${token}`, {password},
      { observe: 'response', headers } )
  }
}
