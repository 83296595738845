import { Subject, takeUntil } from 'rxjs';
import { AtsappService } from '@common/services/atsapp.service';
import { SeanceStatuses } from 'projects/speaker-platform/src/app/types/seanceStatuses.enum';
import { Seance } from 'projects/speaker-platform/src/app/types/seance';
import { Speaker } from 'projects/speaker-platform/src/app/types/speaker';
import { PriseVoix } from 'projects/speaker-platform/src/app/types/prisesVoix';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export abstract class AbstractSeancesComponent {
  constructor(protected atsappService: AtsappService) {}

  seances: Seance[] = [];
  filteredSeances: Seance[] = [];
  speakers: Speaker[] = [];
  prisesVoix: PriseVoix[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();

  selectedMode: string = 'ALL';
  displayInvisible: boolean = false;
  selectedSpeaker: string = '';
  startDateFilter: NgbDateStruct | null = null;
  endDateFilter: NgbDateStruct | null = null;

  invisibleSeancesFilter: string = '';

  seancesLoaded: boolean = false;
  seancesLoading: boolean = false;

  successSound: HTMLAudioElement = new Audio('assets/success.mp3');
  errorSound: HTMLAudioElement = new Audio('assets/error.mp3');

  playSuccessSound() {
    this.successSound.play();
  }

  playErrorSound() {
    this.errorSound.play();
  }

  getSpeakers() {
    this.atsappService
      .getSpeakers(false)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => (this.speakers = res),
        error: () => {},
        complete: () => {},
      });
  }

  getAllSeances(filters: any, editor: string | null = null) {
    this.seancesLoaded = false;
    this.atsappService
      .getAllSeances(filters)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          if (editor && editor !== '') {
            this.seances = res.filter((seance: Seance) => seance.editeur === editor)
            return;
          }
          this.seances = res
        },
        error: (err) => console.error(err),
        complete: () => (this.seancesLoaded = true),
      });
  }

  getOngoingSeancesBySpeaker(speakerId: number | undefined) {
    if (!speakerId) {
      return;
    }
    this.atsappService.getSeanceBySpeaker(speakerId).subscribe({
      next: (res: any) => {
        const filtered = res.filter(
          (seance: Seance) =>
            seance.statut.includes(SeanceStatuses.SENT)
        );

        this.seances = filtered;
        this.filteredSeances = filtered;
      },
      error: (error) => console.error,
      complete: () => console.info('complete'),
    });
  }

  getPvsBySeance(id: number) {
    this.atsappService
      .getPvsBySeance(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => (this.prisesVoix = res.seancePVs),
        error: (error) => console.error,
        complete: () => console.info('complete'),
      });
  }

  getTranslationsBySeance(id: number) {
    this.atsappService
      .getTranslationsBySeance(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => (this.prisesVoix = res.seancePVs),
        error: (error) => console.error,
        complete: () => console.info('complete'),
      });
  }
}
