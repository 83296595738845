import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TextsTodo } from 'projects/speaker-platform/src/app/types/texts-todo.enum';

@Component({
  selector: 'app-texts-menu',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './texts-menu.component.html',
  styleUrls: ['./texts-menu.component.scss']
})
export class TextsMenuComponent {
  @Input() todo: TextsTodo = TextsTodo.RECORD;
  textsTodo = TextsTodo;
}
