import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeakersListComponent } from 'projects/speaker-platform/src/app/organisms/speakers-list/speakers-list.component';
import { AtsappService } from '@common/services/atsapp.service';
import { Speaker } from 'projects/speaker-platform/src/app/types/speaker';

@Component({
  selector: 'app-messagerie',
  standalone: true,
  imports: [CommonModule, SpeakersListComponent],
  templateUrl: './messagerie.component.html',
  styleUrls: ['./messagerie.component.scss']
})
export class MessagerieComponent implements OnInit {
  constructor(private atsappService: AtsappService) {}
  speakers: any[] = [];

  ngOnInit(): void {
    this.atsappService.getSpeakers(false).subscribe((res: any) => {
      this.speakers = res;
    })
  }

  selectSpeaker(e: Speaker): void {
  }
}
