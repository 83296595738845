<div class="textstosend textslist page container">
  <div #textsListHeader class="textslist__header">
    <div class="row">
      <div class="col-12">
        <app-texts-menu [todo]="this.textsTodo.RECORD" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-texts-list-filters
          [speakersList]="this.speakersList"
          [languesList]="this.languesList"
          [phasesVocalesList]="this.phasesVocalesList"
          [typesMessageList]="this.typesMessageList"
          (resetFiltersEvent)="
            this.getPVsList($event); this.selectedSpeaker = undefined
          "
          (selectSpeakerEvent)="this.onSelectSpeaker($event)"
          [isLoading]="this.isLoading"
          (search)="this.getPVsList($event)"
          (selectLangEvent)="this.onSelectLang($event)"
          [hideFilters]="['ZMS_DATEPVDIST1']"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center mb-2">
          <div *ngIf="this.textsToPatchIdList.length <= 0" >
            <button class="btn btn-disabled" disabled>Prévisualiser les textes</button>
            <button class="btn btn-disabled ms-2" disabled>
              Sélectionnez les textes à envoyer
            </button>
          </div>
          <div *ngIf="this.textsToPatchIdList.length > 0">
            <button class="btn btn-secondary"
            (click)="this.showSelectedText(textsToPreview)">
              <i class="fa-regular fa-eye"></i>
              Prévisualiser les textes
            </button>
            <button
              class="btn ms-2"
              [ngClass]="{
              'btn-disabled': !this.selectedSpeaker,
              'btn-primary': this.selectedSpeaker
            }"
              type="submit"
              (click)="this.openSendTextsDialog()"
              [disabled]="this.selectedSpeaker === undefined || this.isLoading"
            >
              <i class="fa-solid fa-paper-plane"></i>
              <span class="text-capitalize ms-2">Envoyer</span>
              {{ this.textsToPatchIdList.length }} texte{{
                this.textsToPatchIdList.length > 1 ? "s" : ""
              }}
            </button>
          </div>


          <span
            *ngIf="!this.selectedSpeaker || !this.langSelected; else speakerSelected"
            class="textslist__error ms-2"
            >Sélectionnez {{ !this.selectedSpeaker ? "un·e comédien·ne" : "" }}
            {{ !this.selectedSpeaker && !this.langSelected ? "et" : "" }}
            {{ !this.langSelected ? "une langue" : "" }} pour envoyer des
            textes</span
          >

          <ng-template #speakerSelected>
            <div ngbDropdown class="d-inline-block ms-3">
              <button
                type="button"
                class="btn btn-outline-primary"
                id="fillSeanceDropdown"
                ngbDropdownToggle
              >
                Compléter les séances
              </button>

              <div ngbDropdownMenu aria-labelledby="fillSeanceDropdown">
                <ng-container
                  *ngFor="let count of this.speakerCountTodayDetails"
                >
                  <button
                    *ngIf="count.remain > 0"
                    ngbDropdownItem
                    (click)="
                      this.page = 1;
                      this.limit = count.remain;
                      this.getPVsList()
                    "
                  >
                    Afficher {{ count.remain }} texte(s) attribuables
                  </button>
                </ng-container>
              </div>
            </div>


            <span class="ms-2">
              {{ this.speakerCountToday }} textes envoyés aujourd'hui sur un
              total possible de
              {{ this.speakerTextsBySeance * this.speakerSeancesLimit }} (soit
              {{ this.speakerTextsBySeance }} par séance)
              <span
                *ngIf="!this.speakerAvailable && this.selectedSpeaker"
                class="d-block textslist__error"
              >
                <i class="fa-solid fa-exclamation-triangle me-2"></i>
                {{ this.selectedSpeaker.prenom }}
                {{ this.selectedSpeaker.nom }} ne travaille pas aujourd'hui
              </span>
            </span>

          </ng-template>
        </div>
      </div>
<!--      TODO : LES textes groupés-->

      <div class="col-3" ngbDropdown *ngIf="this.selectedSpeaker && this.langSelected;">

        <button type="button" class="btn btn-outline-primary" id="countGroupedText" ngbDropdownToggle>
          Grouper les messages par nombre de texte
        </button>
        <div ngbDropdownMenu aria-labelledby="countGroupedText">
          <ng-container>
            <button ngbDropdownItem (click)="this.groupTexteForCompletion(0)">Sans tri</button>
            <button ngbDropdownItem (click)="this.groupTexteForCompletion(1)">1</button>
            <button ngbDropdownItem (click)="this.groupTexteForCompletion(2)">2</button>
            <button ngbDropdownItem (click)="this.groupTexteForCompletion(3)">3</button>
            <button ngbDropdownItem (click)="this.groupTexteForCompletion(4)">4</button>
            <button ngbDropdownItem (click)="this.groupTexteForCompletion(5)">5</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <table class="textslist__table table">
        <thead [style]="{ top: this.textsListHeaderHeight }">
          <tr>
            <th>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  #selectAllCheckbox
                  (change)="
                    this.selectAllTexts(
                      this.selectAllCheckbox.checked,
                      this.prisesVoixGrouped.slice((this.page-1) * this.limit, this.page * this.limit),
                      this.postObjectKeys
                    )
                  "
                  [disabled]="!this.selectedSpeaker || !this.langSelected"
                  [checked]="
                    this.textsToPatchIdList.length === this.prisesVoix.length &&
                    this.selectedSpeaker &&
                    this.prisesVoix.length > 0
                  "
                />
              </div>
            </th>
            <th>Client</th>
            <th>Date livraison</th>
            <th>Comédien·ne</th>
            <th><i class="fa-solid fa-globe"></i></th>
            <th><i class="fa-solid fa-venus-mars"></i></th>
            <th>Type message</th>
            <th>Guide</th>
            <th>Phase vocale</th>
            <th>LER</th>
            <th><i class="fa-solid fa-language"></i></th>
            <th>Mots</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="this.isLoading; else loaded">
            <td class="text-center" colspan="12">
              <div class="textslist__table__loader">
                <app-loader />
              </div>
            </td>
          </tr>
          <tr *ngIf="this.prisesVoix?.length === 0 && !this.isLoading">
            <td class="textslist__table__empty" colspan="12">Aucun résultat</td>
          </tr>
          <ng-template #loaded>
            <tr *ngFor="let text of this.prisesVoixGrouped | slice: (this.page-1) * this.limit : this.page * this.limit ; let i = index" (dblclick)="testShowModal(text)">
              <!--  -->
              <td
                [ngClass]="{
                  'textslist__table__select--match':
                    (this.smartSelectEnabled &&
                      i < this.prisesVoix.length &&
                      this.prisesVoix[i + 1]?.ZMS_NUMERO +
                        '-' +
                        this.prisesVoix[i + 1]?.CLIENT_FINAL ===
                        text.ZMS_NUMERO + '-' + text.CLIENT_FINAL) ||
                    (this.smartSelectEnabled &&
                      i > 0 &&
                      this.prisesVoix[i - 1]?.ZMS_NUMERO +
                        '-' +
                        this.prisesVoix[i - 1]?.CLIENT_FINAL ===
                        text.ZMS_NUMERO + '-' + text.CLIENT_FINAL)
                }"
                class="textslist__table__select"
              >
                <div class="form-check">
                  <input
                    #selectTextCheckbox
                    class="form-check-input"
                    type="checkbox"
                    [disabled]="!this.selectedSpeaker || !this.langSelected"
                    [value]="
                      text.ZMS_NUMERO +
                      '-' +
                      text.ZMS_LIVRAISON +
                      '-' +
                      text.ZMS_MESSAGE +
                      '-' +
                      text.RANGPV +
                      '-' +
                      text.CLIENT_FINAL
                    "
                    [checked]="
                      this.itemShouldBeChecked(
                        text.ZMS_NUMERO +
                          '-' +
                          text.ZMS_LIVRAISON +
                          '-' +
                          text.ZMS_MESSAGE +
                          '-' +
                          text.RANGPV +
                          '-' +
                          text.CLIENT_FINAL
                      )
                    "
                    (click)="
                      this.addToSendList(
                        this.selectTextCheckbox.checked,
                        this.selectTextCheckbox.value,
                        this.smartSelectEnabled,
                        this.prisesVoix,
                        this.postObjectKeys
                      )
                    "
                  />
                </div>
              </td>
              <td [ngbTooltip]="text.LIBELLE_CLIENT_FINAL" container="body">
                {{ text.CLIENT_FINAL }} -
                {{ text.LIBELLE_CLIENT_FINAL | truncate : 10 }}
              </td>
              <td>{{ text.ZMS_DATELIVRAISON | date : "dd/MM/YYYY" }}</td>
              <td>{{ text.PRENOM_COMEDIENNE }} {{ text.NOM_COMEDIENNE }}</td>
              <td>{{ this.getCodes(text.LANGUE, this.languesList) }}</td>
              <td>{{ text.GENRE | truncate : 1 : "" }}</td>
              <td>
                {{
                  this.getLibelles(text.ZMS_TYPEMESSAGE, this.typesMessageList)
                }}
              </td>
              <td ngbTooltip="{{ text.ZMS_GUIDE }}" container="body">
                {{ text.ZMS_GUIDE | truncate : 20 }}
              </td>
              <td>
                {{
                  this.getLibelles(text.ZMS_PHASEVOCALE, this.phasesVocalesList)
                }}
              </td>
              <td>
                <button
                  *ngIf="text.LER !== null"
                  [ngbTooltip]="text.LER"
                  container="body"
                  class="btn-invisible"
                >
                  <i class="fa-solid fa-exclamation-circle"></i>
                </button>
              </td>
              <td>
                <div *ngIf="text.ZMS_PVTRADUCTION1 === 'X'">
                  <div
                    class="textslist__translationstatus"
                    [ngClass]="{
                      'textslist__translationstatus--sent':
                        text.ZMS_PVENVOITRAD1 !== null && !this.isCegidNullDate(text.ZMS_PVENVOITRAD1),
                      'textslist__translationstatus--done':
                        text.ZMS_PVRECEPTIONTRAD1 !== null && !this.isCegidNullDate(text.ZMS_PVRECEPTIONTRAD1)
                    }"
                  ></div>
                </div>
              </td>
              <td>
                {{ text.ZMS_PVTEXTE ? text.ZMS_PVTEXTE.split(" ").length : 0 }}
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>

  <app-text-focus *ngIf="this.showFocusModalFromToSend" />

<!--  TODO voir implémentation pour template générique -->
<!--  <app-texts-preview *ngIf="this.showModalPreviewTexts" (modalClose)="showModalPreviewTexts" [textsForPreview]="textsForPreview"/>-->
  <ng-template #textsToPreview let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Prévisualisation des textes : </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="row fw-bold sticky-top p-2 m-1" style="box-shadow: 0px 8px 9px 0px rgba(0,0,0,0.1); border-radius: 10px ">
      <div class="col-3">Client : </div>
      <div class="col-3">Phase vocale :</div>
      <div class="col-6">Texte : </div>
    </div>
    <div class="modal-body">
      <div *ngFor="let text of this.textsForPreview">
        <div class="row ">
          <div class="col-3">{{text.CLIENT_FINAL}} - {{ text.LIBELLE_CLIENT_FINAL }}</div>
          <div class="col-3">{{this.getLibelles(text.ZMS_PHASEVOCALE, this.phasesVocalesList) }}</div>
          <div class="col-6">{{ text.ZMS_PVTEXTE}}</div>
        </div>
        <hr class="ms-3 me-3">
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Fin</button>
    </div>
  </ng-template>

  <app-dialog
    #postDialog
    title="Envoyer les textes"
    [visibility]="this.showValidationDialog"
    (closeDialogEvent)="
      this.showValidationDialog = $event;
      this.isPostingOver && this.getPVsList()
    "
  >
    <app-post-states
      *ngIf="this.selectedSpeaker"
      [isPosting]="this.isPosting"
      [isPostingOver]="this.isPostingOver"
      [postError]="this.postError"
      readyMessage="Vous allez envoyer {{
        this.textsToPatchIdList.length
      }} texte(s) à
      {{ this.selectedSpeaker.prenom }} {{ this.selectedSpeaker.nom }}"
      postingMessage="Les textes sont en cours d'attribution"
      completeMessage="Les textes ont été attribués avec succès"
    />
    <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
      <div class="datepicker form-floating mt-3 mb-2 col-8">
        <input
          [(ngModel)]="dateEstimation"
          type="text"
          class="form-control"
          id="dateEstime"
          name="dateEstime"
          placeholder=""
          ngbDatepicker
          #d="ngbDatepicker"
          (click)="d.toggle()"
          (dateSelect)="checkSeance()"
        />
        <label for="dateEstime" class="form-label">Date estimée de découpe</label>
      </div>
    </div>
    <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError && this.seanceDuo">
      <div class="form-check form-switch mt-4">
        <input class="form-check-input" type="checkbox" name="" id="" [(ngModel)]="this.createNewSeance">
        <label class="form-check-label" for="">Créer une nouvelle séance</label>
      </div>
    </div>
    <div dialog-footer>
      <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
        <button class="btn btn-danger" (click)="this.postDialog.closeDialog()">
          Annuler
        </button>
        <button
          *ngIf="this.selectedSpeaker"
          class="btn btn-primary ms-2 me-4"
          (click)="this.sendTexts(true)"
          [disabled]="!this.seanceHasBeenChecked"
        >
          <i class="fa-solid fa-envelope-circle-check"></i>
          Valider et notifier par email
        </button>
        <button
          *ngIf="this.selectedSpeaker"
          class="btn btn-success"
          (click)="this.sendTexts()"
          [disabled]="!this.seanceHasBeenChecked"
        >
          Valider
        </button>
      </div>
      <div *ngIf="this.isPostingOver">
        <button
          class="btn btn-success me-2"
          (click)="this.getPVsList(); this.postDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
      <div *ngIf="this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.postDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
    </div>
  </app-dialog>

  <app-pagination
    *ngIf="!this.isLoading"
    [page]="this.page"
    [limit]="this.limit"
    [collectionSize]="this.collectionSize"
    (changePageEvent)="this.page = $event"
    (changeLimitEvent)="this.limit = $event"
  />
</div>
