<div
  class="textlistitem"
  [ngClass]="{
    'textlistitem--record': this.todo === this.textsTodo.RECORD,
    'textlistitem--translate': this.todo === this.textsTodo.TRANSLATE,
    'textlistitem--standby':
      this.textDetails.ZMS_ETATMESSAGE.replaceAll(' ', '') === '21' &&
      this.textDetails.ZMS_STDBYPV1 === 'X'
  }"
>
  <div class="textlistitem__excerpt">
    <div class="textlistitem__excerpt__ref">
      <h6>Référence</h6>
      {{ this.textDetails.ZMS_PVCODE1 }}
    </div>
    <div class="textlistitem__excerpt__phasevocale">
      <h6>Phase vocale</h6>
      {{
        this.getLibelles(this.textDetails.ZMS_PHASEVOCALE, this.phasesVocales)
      }}
    </div>
    <div class="textlistitem__excerpt__text">
      <h6>Texte</h6>
      {{
        this.textDetails.ZMS_PVTEXTE
          | truncate : 65
      }}
    </div>
  </div>
  <div
    class="textlistitem__details"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="this.detailsCollapsed"
  >
    <div class="textlistitem__detailscontent">
      <div class="textlistitem__detailscontent__text">
        <h6>Texte</h6>
        {{ this.textDetails.ZMS_PVTEXTE }}
      </div>
      <div
        *ngIf="this.todo === this.textsTodo.RECORD"
        class="textlistitem__detailscontent__phonetic"
      >
        <h6>Phonétique / Consignes</h6>

        <div *ngIf="this.textDetails.ZMS_PVPHONETIQUE !== ''; else noPhonetic">
          {{ this.textDetails.ZMS_PVPHONETIQUE }}
        </div>
        <ng-template #noPhonetic>
          <div class="textlistitem__detailscontent__nophonetic">
            Pas d'indication de phonétique ou de consignes
          </div>
        </ng-template>
      </div>
      <div
        *ngIf="this.todo === this.textsTodo.TRANSLATE"
        class="textlistitem__detailscontent__translatezone"
      >
        <h6>Traduire en {{ this.textDetails.LANGUE }}</h6>
        <div class="form-floating">
          <textarea
            [(ngModel)]="this.translatedText"
            class="form-control"
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder=""
          ></textarea>
          <label for="">Traduction</label>
        </div>
      </div>
      <div class="textlistitem__detailscontent__actions">
        <h6>Actions</h6>
        <div class="d-flex">
          <button
            class="btn btn-primary me-3"
            *ngIf="
              this.todo === this.textsTodo.RECORD &&
              this.textDetails.ZMS_ETATMESSAGE.replaceAll(' ', '') !== '21' &&
              this.textDetails.ZMS_STDBYPV1 !== 'X'
            "
            (click)="this.showStandbyDialog(this.textDetails)"
          >
            Mettre en standby
          </button>
          <button
            *ngIf="this.todo === this.textsTodo.TRANSLATE"
            class="btn btn-success"
            (click)="
              this.showTranslateDialog(this.textDetails, this.translatedText)
            "
          >
            Enregistrer la traduction
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="textlistitem__more text-center">
    <button
      type="button"
      aria-controls="textlistitemDetails"
      (click)="this.showDetails()"
      [attr.aria-expanded]="!this.detailsCollapsed"
    >
      <i
        class="fa-solid fa-angle-down"
        [ngClass]="{ 'fa-angle-up': !this.detailsCollapsed }"
      ></i>
    </button>
  </div>
</div>
