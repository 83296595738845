import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.scss'],
})
export class MenuCategoryComponent {
  @Input() label: string = '';
}
