<ul *ngIf="this.todo === this.textsTodo.RECORD" class="nav nav-tabs mb-4">
  <li class="nav-item">
    <a
      routerLink="/admin/gestion-prises-voix/textes-a-envoyer"
      routerLinkActive="active"
      class="nav-link"
      >À envoyer</a
    >
  </li>
  <li class="nav-item">
    <a
      routerLink="/admin/gestion-prises-voix/textes-envoyes"
      routerLinkActive="active"
      class="nav-link"
      >Envoyés</a
    >
  </li>
  <li class="nav-item">
    <a
    routerLink="/admin/gestion-prises-voix/textes-en-standby"
    routerLinkActive="active"
    class="nav-link"
    >En standby</a
    >
  </li>
</ul>

<ul *ngIf="this.todo === this.textsTodo.TRANSLATE" class="nav nav-tabs mb-4">
  <li class="nav-item">
    <a
      routerLink="/admin/gestion-traductions/textes-a-envoyer"
      routerLinkActive="active"
      class="nav-link"
      >À envoyer</a
    >
  </li>
  <li class="nav-item">
    <a
      routerLink="/admin/gestion-traductions/textes-envoyes"
      routerLinkActive="active"
      class="nav-link"
      >Envoyés</a
    >
  </li>
</ul>
