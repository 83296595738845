import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ChoixCod } from 'projects/speaker-platform/src/app/types/tablettes';
import { LoaderComponent } from '@common/components/loader/loader.component';

@Component({
  selector: 'app-speaker-edit-categories',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LoaderComponent],
  templateUrl: './speaker-edit-categories.component.html',
  styleUrls: ['./speaker-edit-categories.component.scss'],
})
export class SpeakerEditCategoriesComponent implements OnChanges, OnInit {
  constructor(private fb: FormBuilder) {}

  @Output() saveCategoriesEvent = new EventEmitter();
  @Output() isPristine = new EventEmitter<boolean>();

  @Input() interpretation: string = '';
  @Input() timbrevoix: string = '';
  @Input() agevoix: string = '';
  @Input() langue1: string = '';
  @Input() langue2: string = '';
  @Input() isLoading: boolean = false;
  @Input() domaine: string = '';

  @Input() zin: ChoixCod[] = [];
  @Input() ztb: ChoixCod[] = [];
  @Input() zav: ChoixCod[] = [];
  @Input() lgu: ChoixCod[] = [];
  @Input() zds: ChoixCod[] = [];

  @Input() readOnly: boolean = false;

  interpretations: string[] = [];
  timbre: string[] = [];
  voiceAge: string[] = [];
  domain: string[] = [];

  editCategories = this.fb.group({
    langue1: new FormControl('', Validators.required),
    langue2: [''],
    __interpretationSouriante: [false],
    __interpretationRassurante: [false],
    __interpretationSerieuse: [false],
    __interpretationConvaincante: [false],
    __interpretationDynamique: [false],
    __interpretationNaturelle: [false],
    __interpretationSensuelle: [false],
    __interpretationPromo: [false],

    __timbreAigu: [false],
    __timbreMedium: [false],
    __timbreGrave: [false],

    __agevoix: [''],
    __agevoixEnfant: [false],
    __agevoixAdulte: [false],
    __agevoixPersonne_agee: [false],

    __domaineMobilite: [false],
    __domainePodcast: [false],
    __domaineSpot_radio: [false],
    __domaineTelephonie: [false],

    domaine: [''],
    interpretation: [''],
    timbrevoix: [''],
    agevoix: [''],
  });

  ngOnInit(): void {
    this.interpretations =
      this.interpretation.length > 0 ? this.interpretation.split(',') : [];
    this.timbre = this.timbrevoix.length > 0 ? this.timbrevoix.split(',') : [];
    this.voiceAge = this.agevoix.length > 0 ? this.agevoix.split(',') : [];
    this.domain = this.domaine.length > 0 ? this.domaine.split(',') : [];

    if (this.readOnly) {
      this.editCategories.disable();
    }

    this.updatePristineStatus();
  }

  ngOnChanges(): void {
    this.editCategories.patchValue({
      langue1: this.langue1,
      langue2: this.langue2,
      interpretation: this.interpretation,
      timbrevoix: this.timbrevoix,
      agevoix: this.agevoix,
      domaine: this.domaine,
      __interpretationSouriante: this.interpretation.includes('001'),
      __interpretationRassurante: this.interpretation.includes('002'),
      __interpretationSerieuse: this.interpretation.includes('003'),
      __interpretationConvaincante: this.interpretation.includes('004'),
      __interpretationDynamique: this.interpretation.includes('005'),
      __interpretationNaturelle: this.interpretation.includes('006'),
      __interpretationSensuelle: this.interpretation.includes('007'),
      __interpretationPromo: this.interpretation.includes('008'),
      __timbreAigu: this.timbrevoix.includes('001'),
      __timbreMedium: this.timbrevoix.includes('005'),
      __timbreGrave: this.timbrevoix.includes('009'),

      __domaineMobilite: this.domaine.includes('MOB'),
      __domainePodcast: this.domaine.includes('POD'),
      __domaineSpot_radio: this.domaine.includes('RAD'),
      __domaineTelephonie: this.domaine.includes('TEL'),

      __agevoix: this.agevoix,
      __agevoixEnfant: this.agevoix ? this.agevoix.includes('002') : null,
      __agevoixAdulte: this.agevoix ? this.agevoix.includes('001') : null,
      __agevoixPersonne_agee: this.agevoix
        ? this.agevoix.includes('003')
        : null,
    });
  }

  normalize(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  saveMultiCheck(event: any, dataArray: string[], input: string) {
    if (event.target.checked) {
      dataArray.push(event.target.value);
    } else {
      const index = dataArray.indexOf(event.target.value);
      if (index > -1) {
        dataArray.splice(index, 1);
      }
    }

    this.editCategories.patchValue({ [input]: dataArray.toString() });
  }

  saveCategories() {
    const body = Object.fromEntries(
      Object.entries(this.editCategories.value).filter(
        ([k]) => !k.includes('__')
      )
    );

    const formData = new FormData();

    for (let [k, v] of Object.entries(body)) {
      if (v !== null) {
        //@ts-expect-error
        formData.append(k, v);
      }
    }

    this.saveCategoriesEvent.emit(formData);
  }

  resetForm() {
    this.editCategories.reset({
      langue1: this.langue1,
      langue2: this.langue2,
      interpretation: this.interpretation,
      timbrevoix: this.timbrevoix,
      agevoix: this.agevoix,
      domaine: this.domaine,
      __interpretationSouriante: this.interpretation.includes('001'),
      __interpretationRassurante: this.interpretation.includes('002'),
      __interpretationSerieuse: this.interpretation.includes('003'),
      __interpretationConvaincante: this.interpretation.includes('004'),
      __interpretationDynamique: this.interpretation.includes('005'),
      __interpretationNaturelle: this.interpretation.includes('006'),
      __interpretationSensuelle: this.interpretation.includes('007'),
      __interpretationPromo: this.interpretation.includes('008'),
      __timbreAigu: this.timbrevoix.includes('001'),
      __timbreMedium: this.timbrevoix.includes('005'),
      __timbreGrave: this.timbrevoix.includes('009'),

      __domaineMobilite: this.domaine.includes('MOB'),
      __domainePodcast: this.domaine.includes('POD'),
      __domaineSpot_radio: this.domaine.includes('RAD'),
      __domaineTelephonie: this.domaine.includes('TEL'),

      __agevoix: this.agevoix,
      __agevoixEnfant: this.agevoix ? this.agevoix.includes('002') : null,
      __agevoixAdulte: this.agevoix ? this.agevoix.includes('001') : null,
      __agevoixPersonne_agee: this.agevoix
        ? this.agevoix.includes('003')
        : null,
    });

    this.updatePristineStatus();
  }

  updatePristineStatus() {
    this.isPristine.emit(this.editCategories.pristine);
  }
}
