import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DailyStatusComponent } from 'projects/speaker-platform/src/app/atoms/daily-status/daily-status.component';
import { format, isWithinInterval } from 'date-fns';
import { PlanningEvent } from '../../types/planning-event';
import { UserService } from '@common/services/user.service';
import { enUS } from 'date-fns/locale';

@Component({
  selector: 'app-weekly-planning-preview',
  standalone: true,
  imports: [CommonModule, DailyStatusComponent],
  templateUrl: './weekly-planning-preview.component.html',
  styleUrls: ['./weekly-planning-preview.component.scss'],
})
export class WeeklyPlanningPreviewComponent implements OnInit {
  constructor(private userService: UserService) {}

  @Input() week: Date[] = [];
  @Input() planningEvents: PlanningEvent[] = [];

  daylyStatuses: any[] = [];

  format = format;

  ngOnInit(): void {
    this.week.forEach((day: Date) => {
      const today: any = {
        day: format(day, 'EEE'),
      };

      const isAvailable = this.userService.userData?.disponibilite?.includes(
        format(day, 'EEE', { locale: enUS })
      );
      const isEventToday = this.planningEvents.some((event) => {
        const eventInterval: Interval = {
          start: new Date(event.date_debut).setHours(0, 0),
          end: new Date(event.date_fin).setHours(0, 0),
        };
        return isWithinInterval(day, eventInterval);
      });

      today.status = isEventToday
        ? this.planningEvents.find((event) =>
            isWithinInterval(day, {
              start: new Date(event.date_debut).setHours(0, 0),
              end: new Date(event.date_fin).setHours(0, 0),
            })
          )?.raison
        : isAvailable
        ? 'SEANCE'
        : 'NO_SEANCE';

      this.daylyStatuses.push(today);
    });
  }
}
