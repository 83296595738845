import { Injectable } from '@angular/core';
import { FiltersProps } from 'projects/speaker-platform/src/app/types/filters';
import { DateTransformService } from './date-transform.service';

@Injectable({
  providedIn: 'root'
})
export class ParseFiltersService {

  constructor(private dateTransformService: DateTransformService) { }

  stringifyFilters(filters: any): string {
    let parsed: string = '';

    for (let [k, v] of Object.entries(filters)) {
      if (v) {
        if (k === 'ZMS_DATELIVRAISON' || k === 'ZMS_DATEPVDIST1') {
          parsed +=
          //@ts-expect-error
            encodeURIComponent(`filters[${k}]`) + `=${encodeURIComponent(this.dateTransformService.transformDate(v))}&`;
            continue;
        }
        //@ts-expect-error
        parsed += encodeURIComponent(`filters[${k}]`) + `=${encodeURIComponent(v)}&`
      }
    }

    if (parsed === '') {
      parsed = 'filters[]='
    }

    return parsed;
  }
}
