import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastService } from 'projects/speaker-platform/src/app/services/toast.service';
import { LoaderComponent } from '@common/components/loader/loader.component';
import { DialogComponent } from 'projects/speaker-platform/src/app/molecules/dialog/dialog.component';
import { ChoixCod } from '../../../types/tablettes';

@Component({
  selector: 'app-speaker-edit-parameters',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    DialogComponent,
  ],
  templateUrl: './speaker-edit-parameters.component.html',
  styleUrls: ['./speaker-edit-parameters.component.scss'],
})
export class SpeakerEditParametersComponent implements OnInit, OnChanges {
  constructor(private fb: FormBuilder, private toastService: ToastService) {}

  @Output() saveParametersEvent = new EventEmitter();
  @Output() disableSpeakerEvent = new EventEmitter();
  @Output() isPristine = new EventEmitter<boolean>();

  @Input() joursenregistrement: string = '';
  @Input() nbtxtpv: number = 0;
  @Input() nbpvjour: number = 0;
  @Input() onair: boolean = false;
  @Input() onairlimit: string = '';
  @Input() traductrice: boolean = false;
  @Input() domaine: string = '';
  @Input() isLoading: boolean = false;
  @Input() zds: ChoixCod[] = [];

  recordDays: string[] = [];
  domain: string[] = [];

  editParameters = this.fb.group({
    __joursenregistrementMon: [false],
    __joursenregistrementTue: [false],
    __joursenregistrementWed: [false],
    __joursenregistrementThu: [false],
    __joursenregistrementFri: [false],
    __joursenregistrementSat: [false],
    __joursenregistrementSun: [false],
    disponibilite: [this.joursenregistrement],
    nbtxtpv: new FormControl(this.nbtxtpv, Validators.required),
    nbpvjour: new FormControl(this.nbpvjour, Validators.required),
    onair: [this.onair],
    onairlimit: [this.onairlimit],
    traductrice : [this.traductrice],
  });

  ngOnInit(): void {
    this.recordDays =
      this.joursenregistrement.length > 0
        ? this.joursenregistrement.split(',')
        : [];
    this.domain = this.domaine.length > 0 ? this.domaine.split(',') : [];
    this.updatePristineStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.recordDays = this.joursenregistrement.split(',');
    this.editParameters.patchValue({
      __joursenregistrementMon: this.joursenregistrement.includes('Mon'),
      __joursenregistrementTue: this.joursenregistrement.includes('Tue'),
      __joursenregistrementWed: this.joursenregistrement.includes('Wed'),
      __joursenregistrementThu: this.joursenregistrement.includes('Thu'),
      __joursenregistrementFri: this.joursenregistrement.includes('Fri'),
      __joursenregistrementSat: this.joursenregistrement.includes('Sat'),
      __joursenregistrementSun: this.joursenregistrement.includes('Sun'),
      disponibilite: this.joursenregistrement,
      nbtxtpv: this.nbtxtpv,
      nbpvjour: this.nbpvjour,
      onair: this.onair,
      onairlimit: this.onairlimit,
      traductrice: this.traductrice,
    });
  }

  normalize(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  saveMultiCheck(event: any, dataArray: string[], input: string) {
    if (event.target.checked) {
      dataArray.push(event.target.value);
    } else {
      const index = dataArray.indexOf(event.target.value);
      if (index > -1) {
        dataArray.splice(index, 1);
      }
    }

    this.editParameters.patchValue({ [input]: dataArray.toString() });
  }

  saveParameters() {
    const body = Object.fromEntries(
      Object.entries(this.editParameters.value).filter(
        ([k]) => !k.includes('__')
      )
    );

    const formData = new FormData();

    for (let [k, v] of Object.entries(body)) {
      if (v !== null) {
        //@ts-expect-error
        formData.append(k, v);
      }
    }

    if (this.editParameters.valid) {
      this.saveParametersEvent.emit(formData);
      return;
    }
    this.toastService.show('Le formulaire est invalide', 'danger');
  }

  resetForm() {
    this.editParameters.reset({
      __joursenregistrementMon: this.joursenregistrement.includes('Mon'),
      __joursenregistrementTue: this.joursenregistrement.includes('Tue'),
      __joursenregistrementWed: this.joursenregistrement.includes('Wed'),
      __joursenregistrementThu: this.joursenregistrement.includes('Thu'),
      __joursenregistrementFri: this.joursenregistrement.includes('Fri'),
      __joursenregistrementSat: this.joursenregistrement.includes('Sat'),
      __joursenregistrementSun: this.joursenregistrement.includes('Sun'),
      disponibilite: this.joursenregistrement,
      nbtxtpv: this.nbtxtpv,
      nbpvjour: this.nbpvjour,
      onair: this.onair,
      onairlimit: this.onairlimit,
      traductrice: this.traductrice,
    });

    this.updatePristineStatus();
  }

  updatePristineStatus() {
    this.isPristine.emit(this.editParameters.pristine);
  }

  disableSpeaker() {
    this.disableSpeakerEvent.emit();
  }
}
