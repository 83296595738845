import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  add,
  eachDayOfInterval,
  endOfWeek,
  format,
  parse,
  startOfToday,
  startOfWeek,
  isWithinInterval,
  parseISO,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import { AtsappService } from '@common/services/atsapp.service';
import { PlanningEventReasons } from 'projects/speaker-platform/src/app/types/planning-event-reasons.enum';
import { SpeakersListComponent } from 'projects/speaker-platform/src/app/organisms/speakers-list/speakers-list.component';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Speaker } from 'projects/speaker-platform/src/app/types/speaker';
import { DialogComponent } from 'projects/speaker-platform/src/app/molecules/dialog/dialog.component';
import { ChoixCod } from 'projects/speaker-platform/src/app/types/tablettes';
import { ToastService } from 'projects/speaker-platform/src/app/services/toast.service';
import { Subject, takeUntil } from 'rxjs';
import {LoaderComponent} from "@common/components/loader/loader.component";


export interface AvailableSpeakerDefault {
  day: string;
  comedienne_id: Speaker;
  speakerLabel: string;
}


@Component({
  selector: 'app-global-planning',
  standalone: true,
  imports: [CommonModule, SpeakersListComponent, FormsModule, DialogComponent, LoaderComponent],
  templateUrl: './global-planning.component.html',
  styleUrls: ['./global-planning.component.scss'],
})
export class GlobalPlanningComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  constructor(
    private atsappService: AtsappService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private toastService: ToastService
  ) {}

  @ViewChild('planningWeek') planningWeek!: ElementRef;
  @ViewChild('defaultAvailableDialog') defaultAvailableDialog!: DialogComponent;

  speakersListHeight: number = 0;
  lgu: ChoixCod[] = [];

  destroy$: Subject<boolean> = new Subject<boolean>();

  format = format;
  parseISO = parseISO;
  planningEventReasons = PlanningEventReasons;
  days: string[] = ['L', 'Ma', 'Me', 'J', 'V', 'S', 'D'];
  today: Date = startOfToday();

  localeEnUs: Locale = enUS;

  planningEvents: any[] = [];

  speakersList: any[] = [];
  filteredSpeakersList: any[] = [];
  filterSpeakersListQuery: string = '';
  filterSpeakerListGenre: string = '';
  filterSpeakerLang: string = '';

  filteredAvailableSpeakersDefault: AvailableSpeakerDefault[] = [];

  filteredPlanningEvents: any[] = [];

  planningLoaded : boolean = false

  currentWeek: string = format(this.today, 'ww');
  firstDayOfWeek: Date = parse(this.currentWeek, 'ww', new Date());

  daysInWeek: Date[] = eachDayOfInterval({
    start: startOfWeek(this.firstDayOfWeek, { weekStartsOn: 1 }),
    end: endOfWeek(this.firstDayOfWeek, {
      weekStartsOn: 1,
    }),
  });

  ngOnInit(): void {
    this.atsappService
      .getSpeakers(false)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.speakersList = res;
          this.filteredSpeakersList = res;
        },
        error: () => {
          this.toastService.show(
            'Le chargement des comédien·ne·s a échoué',
            'danger'
          );
        },
        complete: () => {
          this.getAllPlanningEvents();

        }
      });

    this.atsappService
      .getChoixCod('LGU')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          // Français / Anglais / Allemand / Italien / Espagnol en premier dans la liste
          const firsts = ['FRF', 'ANG', 'ALL', 'ITA', 'ESP'];

          firsts.forEach((code: string) => {
            const index = res.findIndex((cc: ChoixCod) => cc.cc_code === code);
            this.lgu.push(res[index]);
          });

          // Ajouter le reste des langues non listées
          this.lgu.push(
            ...res.filter((o: ChoixCod) => !firsts.includes(o.cc_code))
          );
        },
        error: (error) => {
          this.toastService.show(
            'Le chargement des langues à échoué',
            'danger'
          );
        },
      });
  }

  ngAfterViewChecked(): void {
    this.speakersListHeight =
    this.planningWeek.nativeElement.clientHeight - 130;

    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  updateDaysInWeek() {
    this.daysInWeek = eachDayOfInterval({
      start: startOfWeek(this.firstDayOfWeek, { weekStartsOn: 1 }),
      end: endOfWeek(this.firstDayOfWeek, {
        weekStartsOn: 1,
      }),
    });

    this.getDefaultsAvailabilities();
  }

  getDefaultsAvailabilities() {
    const defaults: AvailableSpeakerDefault[] = [];
    const events: AvailableSpeakerDefault[] = [];

    // Get unique days in the week formatted as 'EE'
    const formattedDays = this.daysInWeek.map((day) =>
      format(day, 'EE', { locale: this.localeEnUs })
    );

    // Filter speakers with availability for each day
    formattedDays.forEach((day) => {
      const availableSpeakers = this.speakersList.filter((speaker) =>
        speaker?.disponibilite?.includes(day)
      );

      availableSpeakers.forEach((speaker) => {
        let msgLng = speaker.langue2 !== "" && speaker.langue2 !== " " && speaker.langue2 !== null ? " / " +speaker.langue2 : "";
        defaults.push({
          day,
          speakerLabel: `${speaker.prenom} ${speaker.nom} (${speaker.langue1}${msgLng === null ? "" : msgLng})`,
          comedienne_id: speaker,
        });
      });
    });

    // Filter planning events for each day
    formattedDays.forEach((day) => {
      const dayDate = this.daysInWeek[formattedDays.indexOf(day)];
      const planningEventsToday = this.filteredPlanningEvents.filter((event) =>
        this.hasPlanningEventToday(event, dayDate)
      );

      planningEventsToday.forEach((event) => {
        let msgLng = event.comedienne_id.langue2 !== "" && event.comedienne_id.langue2 !== " " && event.comedienne_id.langue2 !== null ? " / " +event.comedienne_id.langue2 : "";
        events.push({
          day,
          speakerLabel: `${event.comedienne_id.prenom} ${event.comedienne_id.nom} (${event.comedienne_id.langue1}${msgLng === null ? "" : msgLng})`,
          comedienne_id: event.comedienne_id,
        });
      });
    });
    this.filteredAvailableSpeakersDefault = this.removeDuplicates(
      events,
      defaults
    );
  }

  removeDuplicates(
    table1: AvailableSpeakerDefault[],
    table2: AvailableSpeakerDefault[]
  ): AvailableSpeakerDefault[] {
    // Create a map to store unique events from table 1
    const uniqueEventsMap: { [key: string]: boolean } = {};

    // Populate the map with events from table 1
    for (const event of table1) {
      uniqueEventsMap[`${event.day}-${event.comedienne_id.id}`] = true;
    }

    // Filter out events from table 2 that exist in table 1
    const filteredTable2 = table2.filter((event) => {
      return !uniqueEventsMap[`${event.day}-${event.comedienne_id.id}`];
    });

    this.planningLoaded = true;
    return filteredTable2.sort((a,b) =>  (a.speakerLabel > b .speakerLabel? 1 : -1)) ;
  }

  getAllPlanningEvents() {
    this.atsappService
      .getAllPlanningEvents()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.planningEvents = res;
          this.filteredPlanningEvents = res;
          this.filteredPlanningEvents.sort((a,b) =>  (a.comedienne_id.prenom + a.comedienne_id.nom > b.comedienne_id.prenom + b.comedienne_id.nom ? 1 : -1));
        },
        error: (error) => {
          this.toastService.show(
            'Le chargement des événements à échoué pour la raison suivante : ' +
              error.error.message,
            'danger'
          );
        },
        complete: ()  => {
          this.getDefaultsAvailabilities();
        }
      });
  }

  hasPlanningEventToday(planningEvent: any, day: Date) {
    return isWithinInterval(new Date(day), {
      start: new Date(planningEvent.date_debut).setHours(0, 0),
      end: new Date(planningEvent.date_fin).setHours(0, 0),
    });
  }

  getWeek(direction: number) {
    const firstDayOfNextWeek = add(this.firstDayOfWeek, { weeks: direction });
    this.currentWeek = format(firstDayOfNextWeek, 'ww');
    this.firstDayOfWeek = parse(this.currentWeek, 'ww', new Date());
    this.updateDaysInWeek();
  }

  goToSpeakerPlanning(spk: any) {
    this.router.navigate([`/admin/planning/${spk.id}`]);
  }

  findSpeakerFunc(source: any) {
    const filterQuery = this.filterSpeakersListQuery.toLowerCase();
    const matchName =
      (source.nom && source.nom.toLowerCase().indexOf(filterQuery) > -1) ||
      source.prenom.toLowerCase().indexOf(filterQuery) > -1;

    // Vérifiez si le filtre de langue correspond
    const matchLanguage =
      !this.filterSpeakerLang ||
      (source.langue1 &&
        source.langue1.toLowerCase() ===
          this.filterSpeakerLang.toLowerCase()) ||
      (source.langue2 &&
        source.langue2.toLowerCase() === this.filterSpeakerLang.toLowerCase());

    // Vérifiez si le filtre de genre correspond
    const matchGender =
      !this.filterSpeakerListGenre ||
      (source.genre &&
        source.genre.toLowerCase() ===
          this.filterSpeakerListGenre.toLowerCase());

    // Retournez true si tous les filtres correspondent
    return matchName && matchLanguage && matchGender;
  }

  findSpeaker() {
    this.filteredSpeakersList = this.speakersList.filter((spk: Speaker) =>
      this.findSpeakerFunc(spk)
    );
  }
}
