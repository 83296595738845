import {Component} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LoaderComponent} from "@common/components/loader/loader.component";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, LoaderComponent],
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    protected router: Router,
    private toastService: ToastService,
    private route: ActivatedRoute
  ) {
    this.token = this.route.snapshot.paramMap.get('token')!;
    this.organisation = this.route.snapshot.paramMap.get('organisation')!;


    this.passwordForm = this.fb.group({
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&€#|ç_-])[A-Za-z\\d$@$!%*?&€#|ç_-]{8,}$'),
      ]],
      confirmPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&€#|ç_-])[A-Za-z\\d$@$!%*?&€#|ç_-]{8,}$'),
      ]],
    });
    this.passwordForm.get('newPassword')?.valueChanges.subscribe(() => {
      if (this.passwordForm.get('newPassword')?.value !== '') {
        this.passwordForm.get('newPassword')?.markAsDirty();
      }
    })
  }
  isSuccess: boolean = false;
  token: string;
  organisation: string;
  isSubmitted: boolean = false;
  isLoading: boolean = false;
  passwordForm: FormGroup;

  get newPasswordInput() {
    return this.passwordForm.get('newPassword');
  }
  get confirmPasswordInput() {
    return this.passwordForm.get('confirmPassword');
  }

  resetPassword(): void {
    this.isSubmitted= true;
    if (this.passwordForm.valid) {
      if (this.passwordForm.get('newPassword')?.value !== this.passwordForm.get('confirmPassword')?.value) {
      this.toastService.show('Les deux mots de passe ne correspondent pas.', 'info');
      this.isSubmitted = false;
    }
      else {
        this.authService.resetForgottenPassword(this.token, this.passwordForm.get('newPassword')?.value, this.organisation).subscribe({
          next: (response: any) => {
            if (response.ok) {
              this.toastService.show('Votre mot de passe a bien été modifié !','success');
              setTimeout(() => {
                this.router.navigate(['/login'])
              }, 2000);
            } else {
              this.isSubmitted = false;
            }
          },
          error: error => {
            this.toastService.show(error,'danger');
            this.isSubmitted = false;
          }
        })
      }
   }
  }
}
