import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CommonModalService  {
  showModalFocus = new BehaviorSubject<boolean>(false);
  subs: Subscription = new Subscription()
  textFocused: any[any] = [];
  constructor() { }


  openFocusText(text: any) {
    this.subs.add(this.showModalFocus.subscribe(()=>{
    }))


    this.textFocused = text;
    this.showModalFocus.next(true)
  }
  closeFocusText() {
    this.showModalFocus.next(false);
    this.subs.unsubscribe()
  }
}
