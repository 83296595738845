<div class="textstranslatesent page container">
  <div #textsListHeader class="textslist__header">
    <div class="row">
      <div class="col-12">
        <app-texts-menu [todo]="this.textsTodo.TRANSLATE" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-texts-list-filters
          [speakersList]="this.speakersList"
          [languesList]="this.languesList"
          [phasesVocalesList]="this.phasesVocalesList"
          [typesMessageList]="this.typesMessageList"
          [showStatusComedienne]="false"
          (resetFiltersEvent)="this.getSentTextsToTranslateList($event)"
          (selectSpeakerEvent)="this.selectedSpeaker = $event"
          [taxonomy]="this.textsListTaxonomy.TRANSLATOR"
          [hideFilters]="['genre']"
          [isLoading]="this.isLoading"
          (search)="this.getSentTextsToTranslateList($event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center mb-2">
          <div *ngIf="this.textsToPatchIdList.length <= 0">
            <button class="btn btn-disabled" disabled>
              <i class="fa-regular fa-eye"></i>
              Prévisualiser les textes
            </button>
            <button class="btn btn-disabled ms-2" disabled>
              Sélectionnez les textes à retirer
            </button>
          </div>

          <div *ngIf="this.textsToPatchIdList.length > 0">
            <button class="btn btn-secondary"
                    (click)="this.showSelectedText(textsToPreview)">
              <i class="fa-regular fa-eye"></i>
              Prévisualiser les textes
            </button>
            <button class="btn ms-2" [ngClass]="{
              'btn-disabled': this.selectedSpeaker === undefined,
              'btn-primary': this.selectedSpeaker !== undefined
            }"
              type="submit" (click)="this.openConfirmationDialog()" [disabled]="this.selectedSpeaker === undefined || this.isLoading"
            >
              <span class="text-capitalize">Retirer</span>
              {{ this.textsToPatchIdList.length }} texte{{
                this.textsToPatchIdList.length > 1 ? "s" : ""
              }}
            </button>
          </div>


          <span
            *ngIf="this.selectedSpeaker === undefined"
            class="textslist__error ms-2"
            >Sélectionnez un·e comédien·ne pour retirer des textes</span
          >
        </div>
      </div>
      <!-- <div class="col-4">
        <div class="form-check form-switch">
          <input
            #smartSelectSwitch
            class="form-check-input"
            type="checkbox"
            role="switch"
            [checked]="this.smartSelectEnabled"
            (change)="
              this.onSmartSelectEnable();
              this.smartSelectEnabled = this.smartSelectSwitch.checked
            "
          />
          <label class="form-check-label" for="flexSwitchCheckDefault">
            <i class="fa-solid fa-robot"></i>
            Sélection intelligente
          </label>
        </div>
      </div> -->
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <table class="textslist__table table">
        <thead [style]="{ top: this.textsListHeaderHeight }">
          <tr>
            <th>
              <input
                type="checkbox"
                class="form-check-input"
                #selectAllCheckbox
                (change)="
                  this.selectAllTexts(
                    this.selectAllCheckbox.checked,
                    this.sentTextsToTranslateList,
                    this.postObjectKeys
                  )
                "
                [disabled]="!this.selectedSpeaker"
                [checked]="
                  this.textsToPatchIdList.length ===
                    this.sentTextsToTranslateList.length &&
                  this.selectedSpeaker &&
                  this.sentTextsToTranslateList.length > 0
                "
              />
            </th>
            <th>Client</th>
            <th>Date livraison</th>
            <th>Traducteur·rice</th>
            <th><i class="fa-solid fa-globe"></i></th>
            <th>Type message</th>
            <th>Guide</th>
            <th>Phase vocale</th>
            <th>LER</th>
            <th><i class="fa-solid fa-language"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="this.isLoading; else loaded">
            <td class="text-center" colspan="10">
              <div class="textslist__table__loader">
                <app-loader />
              </div>
            </td>
          </tr>
          <tr
            *ngIf="
              this.sentTextsToTranslateList?.length === 0 && !this.isLoading
            "
          >
            <td class="textslist__table__empty" colspan="10">Aucun résultat</td>
          </tr>
          <ng-template #loaded>
            <tr
              *ngFor="let text of this.sentTextsToTranslateList; let i = index" (dblclick)="showFocusTranslatedText(text)"
            >
              <td
                [style]="{
                  backgroundColor:
                    this.smartSelectEnabled &&
                    this.colorFromTiers(-text.CLIENT_FINAL)
                }"
                class="textslist__table__select"
              >
                <div class="form-check">
                  <input
                    #selectTextCheckbox
                    class="form-check-input"
                    type="checkbox"
                    [disabled]="!this.selectedSpeaker"
                    [value]="
                      text.ZMS_NUMERO +
                      '-' +
                      text.ZMS_LIVRAISON +
                      '-' +
                      text.ZMS_MESSAGE +
                      '-' +
                      text.RANGPV +
                      '-' +
                      text.CLIENT_FINAL
                    "
                    [checked]="
                      this.itemShouldBeChecked(
                        text.ZMS_NUMERO +
                          '-' +
                          text.ZMS_LIVRAISON +
                          '-' +
                          text.ZMS_MESSAGE +
                          '-' +
                          text.RANGPV +
                          '-' +
                          text.CLIENT_FINAL
                      )
                    "
                    (click)="
                      this.addToSendList(
                        this.selectTextCheckbox.checked,
                        this.selectTextCheckbox.value,
                        this.smartSelectEnabled,
                        this.sentTextsToTranslateList,
                        this.postObjectKeys
                      )
                    "
                  />
                </div>
              </td>
              <td [ngbTooltip]="text.LIBELLE_CLIENT_FINAL" container="body">
                {{ text.CLIENT_FINAL }} -
                {{ text.LIBELLE_CLIENT_FINAL | truncate : 10 }}
              </td>
              <td>{{ text.ZMS_DATELIVRAISON | date : "dd/MM/YYYY" }}</td>
              <td>
                <ng-container *ngIf="text.ZMS_PVTRAD1">
                  {{
                    this.speakerService.getSpeakerFromCode(
                      text.ZMS_PVTRAD1,
                      this.speakersList
                    )?.prenom
                  }}
                  {{
                    this.speakerService.getSpeakerFromCode(
                      text.ZMS_PVTRAD1,
                      this.speakersList
                    )?.nom
                  }}
                </ng-container>
              </td>
              <td>{{ this.getCodes(text.LANGUE, this.languesList) }}</td>
              <td>
                {{
                  this.getLibelles(text.ZMS_TYPEMESSAGE, this.typesMessageList)
                }}
              </td>
              <td ngbTooltip="{{ text.ZMS_GUIDE }}" container="body">
                {{ text.ZMS_GUIDE | truncate : 20 }}
              </td>
              <td>
                {{
                  this.getLibelles(text.ZMS_PHASEVOCALE, this.phasesVocalesList)
                }}
              </td>
              <td>
                <button
                  *ngIf="text.LER !== null"
                  [ngbTooltip]="text.LER"
                  container="body"
                  class="btn-invisible"
                >
                  <i class="fa-solid fa-exclamation-circle"></i>
                </button>
              </td>
              <td>
                <div *ngIf="text.ZMS_PVTRADUCTION1 === 'X'">
                  <div
                    class="textslist__translationstatus textslist__translationstatus--sent"></div>
                </div>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
  <app-dialog
    #postDialog
    title="Retirer les textes à traduire"
    [visibility]="this.showValidationDialog"
    (closeDialogEvent)="
      this.showValidationDialog = $event;
      this.isPostingOver && this.getSentTextsToTranslateList()
    "
  >
    <app-post-states
      *ngIf="this.selectedSpeaker"
      [isPosting]="this.isPosting"
      [isPostingOver]="this.isPostingOver"
      [postError]="this.postError"
      readyMessage="Vous allez retirer {{
        this.textsToPatchIdList.length
      }} textes à traduire
      à
      {{ this.selectedSpeaker.prenom }} {{ this.selectedSpeaker.nom }}"
      postingMessage="Les textes sont en cours de retrait"
      completeMessage="Les textes ont bien été retirés"
    />
    <div dialog-footer>
      <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
        <button class="btn btn-danger" (click)="this.postDialog.closeDialog()">
          Annuler
        </button>
        <button
          *ngIf="this.selectedSpeaker"
          class="btn btn-primary ms-2 me-4"
          (click)="this.sendTexts(true)"
        >
          <i class="fa-solid fa-envelope-circle-check"></i>
          Valider et notifier par email
        </button>
        <button class="btn btn-success" (click)="this.sendTexts()">
          Valider
        </button>
      </div>
      <div *ngIf="this.isPostingOver">
        <button
          class="btn btn-success me-2"
          (click)="
            this.getSentTextsToTranslateList(); this.postDialog.closeDialog()
          "
        >
          Fermer
        </button>
      </div>
      <div *ngIf="this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.postDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
    </div>
  </app-dialog>
  <app-pagination
    *ngIf="!this.isLoading"
    [page]="this.page"
    [limit]="this.limit"
    [collectionSize]="this.collectionSize"
    (changePageEvent)="this.page = $event; this.getSentTextsToTranslateList()"
    (changeLimitEvent)="this.limit = $event; this.getSentTextsToTranslateList()"
  />

  <app-text-focus *ngIf="this.showFocusModalTranslated" />

  <ng-template #textsToPreview let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Prévisualisation des textes : </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="row fw-bold sticky-top p-2 m-1" style="box-shadow: 0px 8px 9px 0px rgba(0,0,0,0.1); border-radius: 10px ">
      <div class="col-3">Client : </div>
      <div class="col-3">Phase vocale :</div>
      <div class="col-6">Texte : </div>
    </div>
    <div class="modal-body">
      <div *ngFor="let text of this.textsForPreview">
        <div class="row ">
          <div class="col-3">{{text.CLIENT_FINAL}} - {{ text.LIBELLE_CLIENT_FINAL }}</div>
          <div class="col-3">{{this.getLibelles(text.ZMS_PHASEVOCALE, this.phasesVocalesList) }}</div>
          <div class="col-6">{{ text.ZMS_PVTEXTE}}</div>
        </div>
        <hr class="ms-3 me-3">
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Fin</button>
    </div>
  </ng-template>
</div>
