import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastService } from 'projects/speaker-platform/src/app/services/toast.service';
import { LoaderComponent } from '@common/components/loader/loader.component';

@Component({
  selector: 'app-speaker-edit-contactdetails',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LoaderComponent],
  templateUrl: './speaker-edit-contactdetails.component.html',
  styleUrls: ['./speaker-edit-contactdetails.component.scss'],
})
export class SpeakerEditContactdetailsComponent implements OnChanges, OnInit {
  constructor(private fb: FormBuilder, private toastService: ToastService) {}

  @Output() saveContactDetailsEvent = new EventEmitter();
  @Output() isPristine = new EventEmitter<boolean>();

  @Input() prenom: string = '';
  @Input() nom: string = '';
  @Input() genre: string = '';
  @Input() adresse1: string = '';
  @Input() adresse2: string = '';
  @Input() codepostal: string = '';
  @Input() ville: string = '';
  @Input() telephone: string = '';
  @Input() mobilespeak: string = '';
  @Input() email: string = '';
  @Input() codespeak: string = '';
  @Input() trigramme: string = '';
  @Input() readOnly: boolean = false;
  @Input() isLoading: boolean = false;

  editSpeakerContact = this.fb.group({
    prenom: new FormControl(this.prenom, Validators.required),
    nom: new FormControl(this.nom, Validators.required),
    genre: new FormControl(this.genre, Validators.required),
    adresse1: [this.adresse1],
    adresse2: [this.adresse2],
    codepostal: [this.codepostal],
    ville: [this.ville],
    telephone: [this.telephone],
    mobilespeak: [this.mobilespeak],
    email: new FormControl(this.email, [Validators.required, Validators.email])
  });

  ngOnInit(): void {
    if (this.readOnly) {
      this.editSpeakerContact.disable();
    }
    this.updatePristineStatus();
  }

  ngOnChanges(): void {
    this.editSpeakerContact.patchValue({
      prenom: this.prenom,
      nom: this.nom,
      genre: this.genre,
      adresse1: this.adresse1,
      adresse2: this.adresse2,
      codepostal: this.codepostal,
      ville: this.ville,
      telephone: this.telephone,
      mobilespeak: this.mobilespeak,
      email: this.email,
    });
  }

  resetForm() {
    this.editSpeakerContact.reset({
      prenom: this.prenom,
      nom: this.nom,
      genre: this.genre,
      adresse1: this.adresse1,
      adresse2: this.adresse2,
      codepostal: this.codepostal,
      ville: this.ville,
      telephone: this.telephone,
      mobilespeak: this.mobilespeak,
      email: this.email,
    });
    this.updatePristineStatus();
  }

  saveContactDetails() {
    const formData = new FormData();

    for (let [k, v] of Object.entries(this.editSpeakerContact.value)) {
      if (v !== null) {
        formData.append(k, v);
      }
    }

    if (this.editSpeakerContact.valid) {

      this.saveContactDetailsEvent.emit(formData);
      return;
    }
    this.toastService.show('Le formulaire est invalide', 'danger')
  }

  updatePristineStatus() {
    this.isPristine.emit(this.editSpeakerContact.pristine)
  }
}
