<div class="row planning">
  <div class="col-6">
    <div class="row">
      <div class="col-12 d-flex align-items-center mb-4">
        <button class="btn" (click)="this.getPrevMonth()">
          <i class="fa-solid fa-chevron-left"></i>
        </button>
        <div class="mx-3">
          {{ this.currentMonth }}
        </div>
        <button class="btn" (click)="this.getNextMonth()">
          <i class="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </div>
    <div class="planning__daylabels">
      <div *ngFor="let day of this.days" class="text-center">
        {{ day }}
      </div>
    </div>
    <div class="planning__days text-center">
      <div *ngFor="let day of daysInMonth">
        <div
          class="planning__day"
          [ngClass]="{
            'planning__day--seance': this.speakerDefaultSeanceDays.includes(
              format(day, 'EEE', {locale: this.localeEN})
            ) && this.findPlanningEvent(day)?.raison !== this.eventReasons.NO_SEANCE,
            'planning__day--dayoff': this.publicHolidays.has(format(day, 'yyyy-MM-dd')) || this.isWeekend(day),
            'planning__day--unavailable': this.findPlanningEvent(day)?.raison === this.eventReasons.NO_SEANCE,
            'planning__day--seanceextra': this.findPlanningEvent(day)?.raison === this.eventReasons.SEANCE,
            'planning__day--regularabsence':
              this.findPlanningEvent(day)?.raison === this.eventReasons.LEAVE,
            'planning__day--sick':
              this.findPlanningEvent(day)?.raison ===
              this.eventReasons.SICK_LEAVE,
            'planning__day--onsite': this.findPlanningEvent(day)?.raison === this.eventReasons.ON_SITE,
            'planning__day--selected':
              this.selectedDay.getTime() === day.getTime(),
          }"
          (click)="this.selectDay(day)"
        >
          {{ format(day, "d") }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-6 planning__today">
    <div class="row">
      <div class="col-12 planning__today__title">
        Votre journée du
        {{ format(this.selectedDay, "dd MMMM yyyy", { locale: this.locale }) }}
      </div>
    </div>
    <div
      class="row mb-4"
      *ngIf="
        this.findPlanningEvent(this.selectedDay)?.raison !==
          this.eventReasons.NO_SEANCE && this.currentPlanningEvent !== null
      "
    >
      <div class="col-12">
        <div class="col-12 position-relative">
          <div
            class="planning__today__loader"
            *ngIf="this.isPlanningEventsLoading"
          >
            <app-loader />
          </div>
          <div
            class="planning__today__details"
            [class]="{
              'planning__today__details--loading': this.isPlanningEventsLoading
            }"
          >
            <app-card-with-header
              [title]="this.currentPlanningEventParams.title"
              [color]="this.currentPlanningEventParams.color"
              [headerOnly]="
                this.currentPlanningEvent.raison ===
                  this.eventReasons.ON_SITE ||
                this.currentPlanningEvent.raison ===
                  this.eventReasons.PUBLIC_HOLIDAY
              "
            >
              <div
                *ngIf="
                  (this.currentPlanningEvent.raison ===
                    this.eventReasons.LEAVE ||
                    this.currentPlanningEvent.raison ===
                      this.eventReasons.SICK_LEAVE) &&
                  this.canEditPlanning
                "
                class="d-flex align-items-center justify-content-center"
              >
                <button
                  class="btn btn-danger me-2"
                  (click)="this.confirmPlanningEventDeletion = true"
                  *ngIf="!this.confirmPlanningEventDeletion"
                  [disabled]="this.cantEditOrDelete"
                >
                  Annuler {{ this.currentPlanningEventParams.label }}
                </button>
                <button
                  class="btn btn-success me-2"
                  (click)="this.deletePlanningEvent()"
                  [disabled]="this.isLoading"
                  *ngIf="this.confirmPlanningEventDeletion"
                >
                  <span class="d-flex align-items-center">
                    Confirmez l'annulation
                    <app-loader
                      class="ms-2"
                      color="white"
                      *ngIf="this.isLoading"
                    />
                  </span>
                </button>
                <button
                  class="btn btn-warning"
                  [disabled]="this.cantEditOrDelete"
                  (click)="
                    onCreatePlanningEvent(
                      this.currentPlanningEvent.raison,
                      true
                    )
                  "
                >
                  Modifier {{ this.currentPlanningEventParams.label }}
                </button>
              </div>

              <div
                *ngIf="
                  this.currentPlanningEvent.raison === this.eventReasons.SEANCE
                "
                class="planning__today__details__nodetails text-center"
              >
                Les détails de la séance ne sont pas encore disponibles
              </div>
            </app-card-with-header>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 position-relative">
        <div
          class="planning__today__loader"
          *ngIf="this.isPlanningEventsLoading"
        >
          <app-loader />
        </div>
        <div
          *ngIf="this.canEditPlanning"
          class="planning__today__details"
          [class]="{
            'planning__today__details--loading': this.isPlanningEventsLoading
          }"
        >
          <app-card-with-header title="Modifier mon planning" color="primary">
            <div class="row mb-3">
              <div class="col-6 text-center">
                <app-action-button
                  [disabled]="this.cantCreate"
                  icon="umbrella-beach"
                  label="Déclarer une absence"
                  iconColor="#FFC107"
                  (actionEvent)="
                    this.onCreatePlanningEvent(this.eventReasons.LEAVE)
                  "
                />
              </div>
              <div class="col-6 text-center">
                <app-action-button
                  [disabled]="this.cantCreate"
                  icon="kit-medical"
                  iconColor="#FF7474"
                  label="Déclarer un arrêt maladie"
                  (actionEvent)="
                    this.onCreatePlanningEvent(this.eventReasons.SICK_LEAVE)
                  "
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 text-center">
                <app-action-button
                  [disabled]="this.cantCreate"
                  [icon]="
                    this.availableToday ? 'microphone-slash' : 'microphone'
                  "
                  [iconColor]="this.availableToday ? '#ADADAD' : '#99FF74'"
                  [label]="
                    this.availableToday
                      ? 'Se rendre indisponible'
                      : 'Se rendre disponible'
                  "
                  (actionEvent)="
                    this.onCreatePlanningEvent(
                      this.availableToday
                        ? this.eventReasons.NO_SEANCE
                        : this.eventReasons.SEANCE
                    )
                  "
                />
              </div>
              <div class="col-6 text-center">
                <app-action-button
                  [disabled]="this.cantCreate"
                  icon="building-user"
                  iconColor="#74E4FD"
                  label="Aller au studio"
                  (actionEvent)="
                    this.onCreatePlanningEvent(this.eventReasons.ON_SITE)
                  "
                />
              </div>
            </div>
          </app-card-with-header>
        </div>
      </div>
    </div>
    <!-- @TODO Récupérer la séance du jour et/ou séance prévue le jour sélectionné -->
    <!-- <div class="row">
        <div class="col-12">
          <app-card-with-header title="Séance" color="info">
            Coucou
          </app-card-with-header>
        </div>
      </div> -->
  </div>
</div>

<app-create-planning-event
  #cl
  [reason]="this.createPlanningEventReason"
  [planningEventDialogVisible]="this.planningEventDialogVisible"
  [selectedDay]="{
    year: this.selectedDay.getFullYear(),
    month: this.selectedDay.getMonth() + 1,
    day: this.selectedDay.getDate()
  }"
  [patch]="this.patchPlanningEvent"
  [fromDate]="{
    year: this.currentPlanningEvent
      ? parseISO(this.currentPlanningEvent?.date_debut).getFullYear()
      : this.selectedDay.getFullYear(),
    month: this.currentPlanningEvent
      ? parseISO(this.currentPlanningEvent?.date_debut).getMonth() + 1
      : this.selectedDay.getMonth() + 1,
    day: this.currentPlanningEvent
      ? parseISO(this.currentPlanningEvent?.date_debut).getDate()
      : this.selectedDay.getDate()
  }"
  [toDate]="{
    year: this.currentPlanningEvent
      ? parseISO(this.currentPlanningEvent?.date_fin).getFullYear()
      : this.selectedDay.getFullYear(),
    month: this.currentPlanningEvent
      ? parseISO(this.currentPlanningEvent?.date_fin).getMonth() + 1
      : this.selectedDay.getMonth() + 1,
    day: this.currentPlanningEvent
      ? parseISO(this.currentPlanningEvent?.date_fin).getDate()
      : this.selectedDay.getDate()
  }"
  [isLoading]="this.isLoading"
  [planningEvents]="this.planningEvent"
  [currentPlanningEventId]="this.currentPlanningEvent?.id"
  (closePlanningEventDialogEvent)="this.planningEventDialogVisible = $event"
  (deletePlaningEventEvent)="this.autoDeletePlanningEvent($event)"
  (createPlanningEventEvent)="
    this.createPlanningEvent(
      $event.planningEventFromDate,
      $event.planningEventToDate,
      $event.reason,
      $event.patch,
      $event.emailNotify
    )
  "
/>
