import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogComponent } from '../dialog/dialog.component';
import { TruncatePipe } from 'projects/speaker-platform/src/app/pipes/truncate.pipe';
import { TextsTodo } from 'projects/speaker-platform/src/app/types/texts-todo.enum';
import { ChoixCod } from 'projects/speaker-platform/src/app/types/tablettes';
import { CcLibelleService } from 'projects/speaker-platform/src/app/services/helpers/cc-libelle-service.service';
import { LoaderComponent } from '@common/components/loader/loader.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-text-list-item',
  standalone: true,
  imports: [
    CommonModule,
    NgbCollapseModule,
    DialogComponent,
    TruncatePipe,
    LoaderComponent,
    FormsModule
  ],
  templateUrl: './text-list-item.component.html',
  styleUrls: ['./text-list-item.component.scss'],
})
export class TextListItemComponent {
  constructor(
    private ccLibelleService: CcLibelleService,
  ) {}
  @Input() detailsCollapsed: boolean = true;
  @Input() itemIndex: number = 0;
  @Input() textDetails: any;
  @Input() todo: TextsTodo = TextsTodo.RECORD;
  @Input() phasesVocales!: ChoixCod[];
  @Input() standbyTypes: ChoixCod[] = [];

  @Output() showDetailsEvent = new EventEmitter<number>();
  @Output() showStandbyDialogEvent = new EventEmitter();
  @Output() showCommentDialogEvent = new EventEmitter();
  @Output() showTranslateDialogEvent = new EventEmitter();

  postDialog: DialogComponent = new DialogComponent();
  textsTodo = TextsTodo;
  standbyDialogVisible: boolean = false;
  commentDialogVisibility: boolean = false;
  translateDialogVisible: boolean = false;
  translatedText: string = '';

  isPosting: boolean = false;
  isPostingOver: boolean = false;
  postError: { errorCode: number; message: string } | null = null;

  openConfirmationDialog() {
    this.postError = null;
    this.isPosting = false;
    this.isPostingOver = false;
  }

  showStandbyDialog(pv: any): void {
    this.showStandbyDialogEvent.emit(pv);
  }

  showCommentDialog(pv: any): void {
    this.showCommentDialogEvent.emit(pv)
  }

  showTranslateDialog(translation: any, translatedText: string) {
    this.showTranslateDialogEvent.emit({translation, translatedText});
  }

  getLibelles(ccCode: string, ccList: ChoixCod[]): string {
    return this.ccLibelleService.getCcLibelle(ccCode, ccList);
  }

  showDetails() {
    // -1 = no line open
    this.showDetailsEvent.emit(this.detailsCollapsed ? this.itemIndex : -1);
  }
}
