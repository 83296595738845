<div class="speakeredit page container">
  <div class="row">
    <div class="col-4">
      <div class="row mb-3">
        <div class="col-12 pt-2">
          <!-- ROLE_USER_INTERNE | ROLE_COMEDIENNE_STUDIO -->
          <a class="btn btn-primary w-100" routerLink="/admin/creer-profil" routerLinkActive="active">
            <i class="fa-icon fa-solid fa-user-plus"></i>
            Créer un·e comédien·ne
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              placeholder=""
              [(ngModel)]="this.filterSpeakersListQuery"
              (keyup)="this.findSpeaker()"
            />
            <label class="form-label" for="">Recherche par nom / prénom</label>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <div class="form-floating">
            <select
              [(ngModel)]="this.filterSpeakerLang"
              class="form-select"
              name=""
              id=""
              (change)="this.findSpeaker()"
            >
              <option value="">Choix</option>
              <option *ngFor="let langue of this.lgu" [value]="langue.cc_code">
                {{ langue.cc_libelle }}
              </option>
            </select>
            <label class="form-label" for="">Langue</label>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3">
          <div class="form-check">
            <input
              (change)="this.findSpeaker()"
              [(ngModel)]="this.filterSpeakerListGenre"
              type="radio"
              class="form-check-input"
              name="genre-filter-input"
              id="genre-filter-input-all"
              value=""
              checked
            />
            <label for="genre-filter-input-all">Tous</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-check">
            <input
              (change)="this.findSpeaker()"
              [(ngModel)]="this.filterSpeakerListGenre"
              type="radio"
              class="form-check-input"
              name="genre-filter-input"
              id="genre-filter-input-fem"
              value="002"
            />
            <label for="genre-filter-input-fem">Féminin</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-check">
            <input
              (change)="this.findSpeaker()"
              [(ngModel)]="this.filterSpeakerListGenre"
              type="radio"
              class="form-check-input"
              name="genre-filter-input"
              id="genre-filter-input-masc"
              value="001"
            />
            <label for="genre-filter-input-masc">Masculin</label>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12 position-relative">
          <div class="speakeredit__speakerslist">
            <app-speakers-list
              [speakersList]="this.filteredSpeakersList"
              [selected]="this.currentSpeaker ? this.currentSpeaker.id : 0"
              (selectEvent)="this.selectSpeaker($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-8">
      <div class="row">
        <div class="col-12" *ngIf="this.currentSpeaker">
          <ul
            ngbNav
            #nav="ngbNav"
            [(activeId)]="this.active"
            class="nav-tabs"
            (navChange)="this.onTabChange($event)"
          >
            <li [ngbNavItem]="1">
              <button ngbNavLink>Coordonnées</button>
              <ng-template ngbNavContent>
                <app-speaker-edit-contactdetails
                  #editContactDetails
                  [genre]="this.currentSpeaker.genre"
                  [prenom]="this.currentSpeaker.prenom"
                  [nom]="this.currentSpeaker.nom"
                  [adresse1]="this.currentSpeaker.adresse1"
                  [adresse2]="this.currentSpeaker.adresse2"
                  [codepostal]="this.currentSpeaker.codepostal"
                  [ville]="this.currentSpeaker.ville"
                  [telephone]="this.currentSpeaker.telephone"
                  [mobilespeak]="this.currentSpeaker.mobilespeak"
                  [email]="this.currentSpeaker.email"
                  [codespeak]="this.currentSpeaker.codespeak"
                  [trigramme]="this.currentSpeaker.trigramme"
                  [isLoading]="this.isLoading"
                  (saveContactDetailsEvent)="this.onSaveContactDetails($event)"
                  (isPristine)="this.onPristineChange($event)"
                />
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <button ngbNavLink>Présentation</button>
              <ng-template ngbNavContent>
                <app-speaker-edit-presentation
                  #editPresentation
                  [medley]="this.currentSpeaker.medley"
                  [photo]="this.currentSpeaker.photo"
                  [biographie]="this.currentSpeaker.biographie"
                  [codespeak]="this.currentSpeaker.codespeak"
                  [isLoading]="this.isLoading"
                  (savePresentationEvent)="this.onSavePresentation($event)"
                  (isPristine)="this.onPristineChange($event)"
                />
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <button ngbNavLink>Catégories</button>
              <ng-template ngbNavContent>
                <app-speaker-edit-categories
                  #editCategories
                  [zin]="this.zin"
                  [ztb]="this.ztb"
                  [zav]="this.zav"
                  [lgu]="this.lgu"
                  [zds]="this.zds"
                  [domaine]="
                    this.currentSpeaker.domaine
                      ? this.currentSpeaker.domaine
                      : ''
                  "
                  [interpretation]="
                    this.currentSpeaker.interpretation
                      ? this.currentSpeaker.interpretation
                      : ''
                  "
                  [timbrevoix]="
                    this.currentSpeaker.timbrevoix
                      ? this.currentSpeaker.timbrevoix
                      : ''
                  "
                  [agevoix]="
                    this.currentSpeaker.agevoix
                      ? this.currentSpeaker.agevoix
                      : ''
                  "
                  [langue1]="this.currentSpeaker.langue1"
                  [langue2]="this.currentSpeaker.langue2"
                  [isLoading]="this.isLoading"
                  (saveCategoriesEvent)="this.onSaveCategories($event)"
                  (isPristine)="this.onPristineChange($event)"
                />
              </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <button ngbNavLink>Paramètres</button>
              <ng-template ngbNavContent>
                <app-speaker-edit-parameters
                  #editParameters
                  [joursenregistrement]="
                    this.currentSpeaker.disponibilite
                      ? this.currentSpeaker.disponibilite
                      : ''
                  "
                  [nbtxtpv]="this.currentSpeaker.nbtxtpv"
                  [nbpvjour]="this.currentSpeaker.nbpvjour"
                  [onair]="this.currentSpeaker.onair"
                  [isLoading]="this.isLoading"
                  [traductrice]="this.currentSpeaker.traductrice"
                  (disableSpeakerEvent)="this.onDisableSpeaker()"
                  (isPristine)="this.onPristineChange($event)"
                  (saveParametersEvent)="this.onSaveParameters($event)"
                />
              </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="nav" class="mt-2"></div>
          <app-dialog
            #confirmationDialog
            title="Modifications en cours"
            [visibility]="this.confirmationDialogVisible"
            (closeDialogEvent)="this.confirmationDialogVisible = $event"
          >
            Des modifications sont en cours. Voulez-vous les sauvegarder ou les
            annuler ?

            <div dialog-footer>
              <button
                class="btn btn-danger me-2"
                (click)="
                  this.resetCurrentForm();
                  this.confirmationDialog.closeDialog();
                  nav.select(this.nextActive)
                "
              >
                Annuler
              </button>
              <button
                class="btn btn-success"
                (click)="
                  this.saveCurrentForm();
                  this.confirmationDialog.closeDialog();
                  nav.select(this.nextActive)
                "
              >
                Sauvegarder
              </button>
            </div>
          </app-dialog>

          <app-dialog
            #disableSpeakerDialog
            title="Désactivation du profil de {{
              this.currentSpeaker.prenom
            }} {{ this.currentSpeaker.nom }}"
            [visibility]="this.disableSpeakerDialogVisible"
            (closeDialogEvent)="this.disableSpeakerDialogVisible = $event"
          >
            <app-post-states
              [isPosting]="this.disablePosting"
              [isPostingOver]="this.disablePostingOver"
              [postError]="this.disablePostingError"
              readyMessage="Le profil de {{ this.currentSpeaker.prenom }} {{
                this.currentSpeaker.nom
              }} sera désactivé.
            Cette action est irréversible sans intervention du service informatique."
              postingMessage="Désactivation du profil en cours"
              completeMessage="Le profil a bien été désactivé"
            />
            <div dialog-footer>
              <div
                *ngIf="
                  !this.disablePosting &&
                  !this.disablePostingOver &&
                  !this.disablePostingError
                "
              >
                <button
                  class="btn btn-danger me-3"
                  (click)="this.disableSpeakerDialog.closeDialog()"
                >
                  Annuler
                </button>
                <button class="btn btn-success" (click)="this.disableSpeaker()">
                  Confirmer la Désactivation
                </button>
              </div>
              <div *ngIf="this.disablePostingOver || this.disablePostingError">
                <button
                  class="btn btn-{{
                    this.disablePostingError ? 'danger' : 'success'
                  }}"
                  (click)="this.disableSpeakerDialog.closeDialog()"
                >
                  Fermer
                </button>
              </div>
            </div>
          </app-dialog>
        </div>
      </div>
    </div>
  </div>
</div>
