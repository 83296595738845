import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Seance } from 'projects/speaker-platform/src/app/types/seance';
import { DialogComponent } from 'projects/speaker-platform/src/app/molecules/dialog/dialog.component';
import { AtsappService } from '@common/services/atsapp.service';
import { AbstractTextComponent } from 'projects/speaker-platform/src/app/common/abstract/abstract-texts.component';
import { DateTransformService } from 'projects/speaker-platform/src/app/services/helpers/date-transform.service';
import { Speaker } from 'projects/speaker-platform/src/app/types/speaker';
import { PriseVoix } from 'projects/speaker-platform/src/app/types/prisesVoix';
import { takeUntil } from 'rxjs';
import { CcLibelleService } from '../../../services/helpers/cc-libelle-service.service';
import { NgbProgressbarModule, NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from '@common/components/loader/loader.component';
import { TruncatePipe } from '../../../pipes/truncate.pipe';
import { PaginationComponent } from '../../../molecules/pagination/pagination.component';
import { SeanceStatuses } from '../../../types/seanceStatuses.enum';
import { PostStatesComponent } from '../../../molecules/post-states/post-states.component';
import { FormsModule } from '@angular/forms';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { format } from 'date-fns';
import { SpeakerService } from '@common/services/helpers/speaker.service';
import {ClipboardModule} from "@angular/cdk/clipboard";
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-seances-list',
  standalone: true,
  imports: [
    CommonModule,
    DialogComponent,
    NgbTooltip,
    LoaderComponent,
    TruncatePipe,
    PaginationComponent,
    NgbTooltipModule,
    PostStatesComponent,
    FormsModule,
    NgbProgressbarModule,
    ClipboardModule
  ],
  templateUrl: './seances-list.component.html',
  styleUrls: ['./seances-list.component.scss'],
})
export class SeancesListComponent
  extends AbstractTextComponent
  implements OnInit, OnDestroy, OnChanges
{
  constructor(
    atsappService: AtsappService,
    dateTransformService: DateTransformService,
    ccLibelleService: CcLibelleService,
    protected speakerService: SpeakerService
  ) {
    super(atsappService, dateTransformService, ccLibelleService);
  }

  @Input() seances: Seance[] = [];
  @Input() generateLoading: boolean = false;
  @Input() seancesLoaded: boolean = false;
  @Input() editors: any[] = [];
  @Input() uploadProgress: number = 0;
  @Input() collectionSize: number = 0;
  @Input() filterCriteria : string | null = '';
  @Output() reassignEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Output() ungenerateEvent = new EventEmitter();
  @Output() rejectTextMasterEvent = new EventEmitter();
  @Output() validateEvent = new EventEmitter();
  @Output() generateEvent = new EventEmitter();
  @Output() remasteredEvent = new EventEmitter();
  @Output() openSeanceDetailsEvent = new EventEmitter();
  @Output() openAssignEditorEvent: EventEmitter<Seance> = new EventEmitter();
  detailsDialogVisible: boolean = false;
  details: PriseVoix[] = [];
  detailsId: number = -1;
  pdfLoading: boolean = false;
  seanceToPost!: any;
  seanceFile: any;
  selectedSeance!: Seance;

  isPosting: boolean = false;
  isSeanceNoGenerate: boolean = true;
  isSeanceNoUngenerate: boolean = true;
  isPostingOver: boolean = false;
  postError: { errorCode: number; message: string } | null = null;

  seanceStatuses = SeanceStatuses;
  // stateFilter: string|null = null;

  page: number = 1;
  limit: number = 10;

  selectedEditor: string = '';

  ngOnInit(): void {
    this.getSpeakers();
    this.getLangues();
    this.getTypesMessage();
    this.getPhasesVocale();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Fermer les détails à la fin de l'intégration ou à l'échec de l'intégration
    if (
      changes['generateLoading'] &&
      !changes['generateLoading'].firstChange &&
      !changes['generateLoading'].currentValue
    ) {
      this.detailsDialogVisible = false;
    }
  }

  openDetails(id: number, seance: Seance, mode: string) {
    this.detailsId = id;
    this.selectedSeance = seance;
    this.openSeanceDetailsEvent.emit(seance);
    if (mode === 'VOCAL') {
      this.getSeanceDetails(id);
    }

    if (mode === 'TRADUCTION') {
      this.getTranslationSeanceDetails(id);
    }
    this.detailsDialogVisible = true;
  }

  getSpeakerDetails(codespeak: string): Speaker | undefined {
    const speaker = this.speakersList.filter(
      (spk: Speaker) => spk.codespeak === codespeak
    )[0];

    return speaker;
  }

  getTranslationSeanceDetails(id: number) {
    this.details = [];
    this.isLoading = true;
    this.atsappService
      .getTranslationsBySeance(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          this.details = res.seanceTranslations;
        },
        error: (error) => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  getSeanceDetails(id: number, generatePdf: boolean = false) {
    this.details = [];
    this.detailsId = id;
    this.isLoading = true;
    if (generatePdf) {
      this.pdfLoading = true;
    }
    this.atsappService
      .getPvsBySeance(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          switch (this.filterCriteria) {
            case 'ENVOYEE' : {
              this.details = res.seancePVs.filter((pv:any) => pv.ZMS_PVDATERETOUR1 == "1900-01-01 00:00:00.000" ||
                pv.ZMS_PVDATERETOUR1 === null ||  pv.ZMS_PVDATERETOUR1 === '')

              this.details = this.details.filter((pv:any) => pv.ZMS_PVDATE1 == "1900-01-01 00:00:00.000" ||
                pv.ZMS_PVDATE1 === null ||  pv.ZMS_PVDATE1 === '')
              break;
            }
            case 'A DECOUPER' :
            case 'MASTERING' :
            case 'EN COURS DE DECOUPE' : {
              this.details = res.seancePVs.filter((pv:any) => pv.ZMS_PVDATERETOUR1 != "1900-01-01 00:00:00.000" &&
                pv.ZMS_PVDATERETOUR1 !== null &&  pv.ZMS_PVDATERETOUR1 !== '')

              this.details = this.details.filter((pv:any) => pv.ZMS_PVDATE1 == "1900-01-01 00:00:00.000" ||
                pv.ZMS_PVDATE1 === null ||  pv.ZMS_PVDATE1 === '')
              break;
            }
            case 'INTEGREE': {
              this.details = res.seancePVs.filter((pv:any) => pv.ZMS_PVDATE1 != "1900-01-01 00:00:00.000" &&
                pv.ZMS_PVDATE1 !== null &&  pv.ZMS_PVDATE1 !== '')
            }
          }

          res.seancePVs.length > 0 && this.buildPostObject(this.details);
          if (generatePdf) {
            setTimeout(() => {
              this.getPdf();
            });
          }
        },
        error: (error) => {
          console.error(error);
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.pdfLoading = false;
        },
      });
  }

  getPdf() {
    let dd: any = {
      header: {
        text:
          'Séance ' +
          this.speakerService.getSpeakerFromCode(
            this.selectedSeance.speak,
            this.speakersList
          )?.prenom +
          ' ' +
          this.selectedSeance.langue  +
          ' du ' +
          format(new Date(this.selectedSeance.date_creation), 'dd/MM/yyyy'),
        style: 'pageHeader',
        margin: [16, 16],
      },

      footer: function (currentPage: number, pageCount: number) {
        return 'Page ' + currentPage.toString() + ' sur ' + pageCount;
      },
      content: [],

      styles: {
        subHead: {
          bold: true,
          background: 'yellow',
        },
        pageHeader: {
          bold: true,
          fontSize: 18,
        },
      },
    };

    this.details.forEach((pv: PriseVoix) => {
      dd.content.push(
        {
          table: {
            widths: ['*', '*'],
            headerRows: 1,
            pageBreak: 'after',
            dontBreakRows: true,
            unbreakable: true,
            keepWithHeaderRows: 1,
            body: [
              [
                {
                  text: 'Référence : ' + pv.ZMS_PVCODE1,
                  fillColor: '#FFED00',
                  border: [false, false, false, false],
                },
                {
                  text:
                    'Phase vocale : ' +
                    this.ccLibelleService.getCcLibelle(
                      pv.ZMS_PHASEVOCALE,
                      this.phasesVocalesList
                    ),
                  fillColor: '#FFED00',
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: 'Texte : \n\n' + pv.ZMS_PVTEXTE,
                  border: [false, false, false, false],
                  colSpan: 2,
                  margin: [0, 3],
                },
                {},
              ],
              [
                {
                  text: 'Phonétique / consignes : \n' + pv.ZMS_PVPHONETIQUE,
                  fillColor: '#e0d6d0',
                  colSpan: 2,
                  border: [false, false, false, true],
                  margin: [0, 0, 0, 8],
                },
                {},
              ],
            ],
          },
        },
        { text: '\n' }
      );
    });
    // def filename dans download()
    pdfMake.createPdf(dd).download(`PV_${this.speakerService.getSpeakerFromCode(this.selectedSeance.speak, this.speakersList)?.prenom}_${this.selectedSeance.langue}_${format(new Date(this.selectedSeance.date_creation), 'dd-MM-yyyy')}.pdf`);
  }

  selectAllPV(stateSelection: boolean) {
    const checksbox :any = document.getElementsByName('selectPVForAction')
    this.seanceToPost.PRISES_VOIX = []

    for (let i =0 ; i < checksbox.length ; i++) {
      checksbox[i].checked = stateSelection
    }
    if (stateSelection) {
      this.details.forEach((pv) => {
        this.seanceToPost.PRISES_VOIX.push({
          RANGPV: pv.RANGPV,
          ZMS_NUMERO: pv.ZMS_NUMERO,
          ZMS_LIVRAISON: pv.ZMS_LIVRAISON,
          ZMS_MESSAGE: pv.ZMS_MESSAGE,
          ZMS_DATEPVDIST1: pv.ZMS_DATEPVDIST1,
          ZMS_PVTEXTE: pv.ZMS_PVTEXTE,
          ZMS_PVCODE1: pv.ZMS_PVCODE1,
          ZMS_PVDATERETOUR1: pv.ZMS_PVDATERETOUR1
        });
      })
    }
  }

  switchPVReception(pv: PriseVoix, received: boolean) {
    if (!received) {
      const indexToRemove = this.seanceToPost.PRISES_VOIX.findIndex(
        (item: any) =>
          item.ZMS_LIVRAISON === pv.ZMS_LIVRAISON &&
          item.ZMS_NUMERO === pv.ZMS_NUMERO &&
          item.ZMS_MESSAGE === pv.ZMS_MESSAGE
      );

      if (indexToRemove !== -1) {
        this.seanceToPost.PRISES_VOIX.splice(indexToRemove, 1);
      }
    } else {
      this.seanceToPost.PRISES_VOIX.push({
        RANGPV: pv.RANGPV,
        ZMS_NUMERO: pv.ZMS_NUMERO,
        ZMS_LIVRAISON: pv.ZMS_LIVRAISON,
        ZMS_MESSAGE: pv.ZMS_MESSAGE,
        ZMS_DATEPVDIST1: pv.ZMS_DATEPVDIST1,
        ZMS_PVTEXTE: pv.ZMS_PVTEXTE,
        ZMS_PVCODE1: pv.ZMS_PVCODE1,
        ZMS_PVDATERETOUR1: pv.ZMS_PVDATERETOUR1
      });
    }
  }

  buildPostObject(priseVoix: PriseVoix[]) {
    const pvArray = [];
    for (let pv of priseVoix) {
      pvArray.push({
        RANGPV: pv.RANGPV,
        ZMS_NUMERO: pv.ZMS_NUMERO,
        ZMS_LIVRAISON: pv.ZMS_LIVRAISON,
        ZMS_MESSAGE: pv.ZMS_MESSAGE,
        ZMS_DATEPVDIST1: pv.ZMS_DATEPVDIST1,
        ZMS_PVTEXTE: pv.ZMS_PVTEXTE,
        ZMS_PVCODE1: pv.ZMS_PVCODE1,
        ZMS_PVDATERETOUR1: pv.ZMS_PVDATERETOUR1
      });
    }

    const body = {
      CODE_COMEDIENNE: priseVoix[0].CODE_COMEDIENNE,
      PRISES_VOIX: [...pvArray],
    };

    this.seanceToPost = body;
  }

  getSeanceFile(event: any) {
    this.seanceFile = event.target.files[0];
  }

  cancelSeance(id: number) {
    this.cancelEvent.emit(id);
  }

  reassignSeance(id: number) {
    this.reassignEvent.emit(id);
  }

  validRemasterSeance(id: number) {
      this.remasteredEvent.emit(id);
  }

  ungenerateSeance() {
    this.ungenerateEvent.emit(this.seanceToPost);
  }

  rejectMaster() {
    this.rejectTextMasterEvent.emit(this.seanceToPost);
  }

  validateSeance() {
    this.validateEvent.emit(this.seanceToPost);
  }

  openAssignEditor(seance: Seance) {
    this.openAssignEditorEvent.emit(seance);
  }

  getEditorFullName(trigramme: string) {
    return this.editors.find((ed: any) => ed.TRIGRAMME === trigramme)?.NOM_EDITEUR;
  }

  generateSeance(id: number, notify: boolean = false) {
    const formData = new FormData();
    this.seanceToPost.email = notify;
    formData.append('data', JSON.stringify(this.seanceToPost));
    formData.append('file', this.seanceFile as File);
    this.generateEvent.emit({ formData, id });
  }
}
