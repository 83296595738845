


<ng-template #rt let-r="result" let-t="term">
  <app-speaker-list-item *ngIf="showStatus" [name]="r.prenom + ' ' + r.nom" (selectEvent)="this.onSelectSpeaker($event)" [speaker]="r" [status]="this.getStatus(r)" />
  <app-speaker-list-item *ngIf="!showStatus" [name]="r.prenom + ' ' + r.nom" (selectEvent)="this.onSelectSpeaker($event)" [speaker]="r" />
</ng-template>

<div class="form-floating">
    <input
      id="typeahead-template"
      type="text"
      class="form-control col"
      [(ngModel)]="model"
      [ngbTypeahead]="search"
      [resultTemplate]="rt"
      placeholder=""
      [disabled]="this.disabled"
      (selectItem)="this.onSelectSpeaker($event.item)"
    />
    <label class="form-label" for="">{{label}}</label>
</div>
