export const environment = {
  production: false,
  apiBaseUrl: 'https://client.ats-preprod.com',
  // Inutile en DMZ ?
  interneUrl: 'https://productions.ats-preprod.fr',
  speakerPlatformBaseUrl: 'https://comediennes.ats-preprod.com',
  publicQrcodeBaseURL: 'https://productions.ats-preprod.com',
  publicQrcodeURL: 'https://qrcode.ats-preprod.com/',
  publicQrcodeCollectionURL: 'https://qrcode.ats-preprod.com/#/c',
  dashboardUrl: 'https://productions.ats-preprod.fr/plateforme-comedienne/#/admin/dashboard',
  planningUrl: 'https://productions.ats-preprod.fr/plateforme-comedienne/#/admin/planning',
};
