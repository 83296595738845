<div class="speakerslist">
  <div *ngFor="let spk of this.speakersList">
    <app-speaker-list-item
      *ngIf="this.planningEventsLoaded && !spk.generique"
      [selected]="this.selected === spk.id"
      [name]="spk.prenom + ' ' + spk.nom"
      [speaker]="spk"
      [status]="this.getStatus(spk)"
      (selectEvent)="this.onSelect(spk)"
    />
  </div>
</div>
