import { Injectable } from '@angular/core';
import { ChoixCod } from 'projects/speaker-platform/src/app/types/tablettes';

@Injectable({
  providedIn: 'root'
})
export class CcLibelleService {

  constructor() { }

  getCcLibelle(cc_code: string, ccArray: ChoixCod[]): string {
    const f = ccArray.filter((item: ChoixCod) => item.cc_code === cc_code);

    if (f.length > 0) {
      return f[0].cc_libelle;
    }

    return '';
  }

  getCcCode(cc_libelle: string, ccArray: ChoixCod[]): string {
    const f = ccArray.filter((item: ChoixCod) => item.cc_libelle === cc_libelle);

    if (f.length > 0) {
      return f[0].cc_code;
    }

    return '';
  }
}
