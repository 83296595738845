<div class="admindashboard container">
  <div class="row mt-3">
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <h2>Disponibilités</h2>
          <div class="papercard">
            <div class="admindashboard__stat">
              <div class="admindashboard__stat__item">
                {{ this.defaultAvailable + this.available - this.unavailable }}
                <span>Disponibles</span>
              </div>
              <div class="admindashboard__stat__item">
                {{ this.onSite }}
                <span>Au studio</span>
              </div>
              <div class="admindashboard__stat__item">
                {{ this.leave }}
                <span>Absent·e·s</span>
              </div>
              <div class="admindashboard__stat__item">
                {{ this.sickLeave }}
                <span>Malades</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h2>Prises voix</h2>
          <div class="papercard">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Langue</th>
                  <th>Féminin</th>
                  <th>Masculin</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of this.pvStats | keyvalue">
                  <td>{{ $any(item.value).Langue }}</td>
                  <td>{{ $any(item.value)["Féminin"] }}</td>
                  <td>{{ $any(item.value).Masculin }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h2>État des séances vocales</h2>
          <div class="papercard">
            <div *ngIf="this.seancesPV" class="admindashboard__stat">
              <div class="admindashboard__stat__item">
                {{ this.seancesPV.EnAttente }}
                <span>En attente</span>
              </div>
              <div class="admindashboard__stat__item">
                {{ this.seancesPV.AAttribuer }}
                <span>A attribuer</span>
              </div>
              <div class="admindashboard__stat__item">
                {{ this.seancesPV.EnCoursDecoupe }}
                <span>En-cours de découpe</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <h2>Au studio aujourd'hui</h2>
          <div class="papercard">
            <ul *ngIf="this.onSiteDetails.length > 0; else nobodyOnSite" class="admindashboard__onsitetoday">
              <li *ngFor="let spk of this.onSiteDetails">
                {{ spk.speaker }}

                <span class="badge text-bg-info">
                  {{ format(parseISO(spk.hour), "HH'h'mm") }}
                </span>
              </li>
            </ul>
            <ng-template #nobodyOnSite>
              <div class="text-center fst-italic text-secondary">Personne ne sera présent au studio aujourd'hui</div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h2>Traductions</h2>
          <div class="papercard">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Langue</th>
                  <th>Nombre</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of this.translationStats | keyvalue">
                  <td>{{ $any(item.value).Langue }}</td>
                  <td>{{ $any(item.value).Nombre }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h2>État des séances de traduction</h2>
          <div class="papercard">
            <div *ngIf="this.seancesPV" class="admindashboard__stat">
              <div class="admindashboard__stat__item">
                {{ this.seancesTranslation.EnAttente }}
                <span>En attente</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
