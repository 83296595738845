import {Component, EventEmitter, Input, Output, OnInit, OnDestroy} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { Speaker } from 'projects/speaker-platform/src/app/types/speaker';
import {Observable, OperatorFunction, debounceTime, map, takeUntil, Subject} from 'rxjs';
import { FormsModule } from '@angular/forms';
import { SpeakerListItemComponent } from '../../speaker-list-item/speaker-list-item.component';
import {AtsappService} from "@common/services/atsapp.service";
import {PlanningEvent} from "../../../types/planning-event";
import {format, isWithinInterval, startOfToday} from "date-fns";
import {PlanningEventReasons} from "../../../types/planning-event-reasons.enum";
import {enUS} from "date-fns/locale";

@Component({
  selector: 'app-speaker-selector',
  standalone: true,
  imports: [
    CommonModule,
    NgbTypeaheadModule,
    FormsModule,
    SpeakerListItemComponent,
  ],
  templateUrl: './speaker-selector.component.html',
  styleUrls: ['./speaker-selector.component.scss'],
})
export class SpeakerSelectorComponent implements OnInit, OnDestroy {
  @Input() speakers: Speaker[] = [];
  @Input() disabled: boolean = false;
  @Input() resetListener = new EventEmitter();
  @Input() label: string = 'Comédien·ne';
  @Input() showStatus: boolean = true;
  @Output() selectSpeakerEvent = new EventEmitter();

  model: any = '';

  destroy$: Subject<boolean> = new Subject<boolean>();
  today = startOfToday();
  planningEventReasons = PlanningEventReasons;
  todayEvents: any[] = [];


  constructor(private atsappService: AtsappService) {}

  ngOnInit(): void {
    if (this.showStatus) {
      this.atsappService.getAllPlanningEvents()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res: any) => {
            const todayEvents = res.filter((event: PlanningEvent) =>
              isWithinInterval(this.today, {
                start: new Date(event.date_debut).setHours(0, 0),
                end: new Date(event.date_fin).setHours(0, 0),
              })
            );

            todayEvents.forEach((event: PlanningEvent) => {
              this.todayEvents.push({
                speakerId: Number(event.comedienne_id.id),
                statut: event.raison,
              });
            });
          },
        });
    }


    this.resetListener.subscribe(() => {
      this.model = '';
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  search: OperatorFunction<string, readonly Speaker[]> = (
    text$: Observable<string>
  ) => {
    return text$.pipe(
      debounceTime(200),
      map((term) =>
        term === ''
          ? []
          : this.speakers
              .filter(
                (v) =>
                  (v.nom &&
                    v?.nom.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
                  v?.prenom.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      )
    );
  };

  getStatus(spk: Speaker): PlanningEventReasons {
    const f = this.todayEvents.findIndex(
      (event: any) => event.speakerId === spk.id
    );
    if (f !== -1) {
      return this.todayEvents[f].statut;
    }

    if (
      spk.disponibilite && !spk.disponibilite.includes(format(this.today, 'EEE', { locale: enUS }))
    ) {
      return this.planningEventReasons.NO_SEANCE;
    }

    if (!spk.disponibilite) return this.planningEventReasons.NO_SEANCE;

    return this.planningEventReasons.SEANCE;
  }

  onSelectSpeaker(speaker: Speaker) {
    window.setTimeout(() => {
      this.model = speaker.prenom + ' ' + speaker.nom;
    });
    this.selectSpeakerEvent.emit(speaker);
  }
}
