<div class="container" [formGroup]="this.editSpeakerContact">
  <div class="row mb-3">
    <div class="col-2">
      <div class="form-floating">
        <select
          name="create-speaker-gender"
          id="create-speaker-gender"
          class="form-select"
          formControlName="genre"
          (change)="this.updatePristineStatus()"
        >
          <option value="">Choix</option>
          <option value="002">F</option>
          <option value="001">M</option>
        </select>
        <label class="form-label" for="create-speaker-gender">Genre</label>
      </div>
    </div>
    <div class="col-5">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="create-speaker-prenom"
          placeholder=""
          formControlName="prenom"
          (change)="this.updatePristineStatus()"
        />
        <label class="form-label" for="create-speaker-prenom">Prénom</label>
      </div>
    </div>
    <div class="col-5">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="create-speaker-nom"
          placeholder=""
          formControlName="nom"
          (change)="this.updatePristineStatus()"
        />
        <label class="form-label" for="create-speaker-nom">Nom</label>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="create-speaker-adresse1"
          placeholder=""
          formControlName="adresse1"
          (change)="this.updatePristineStatus()"
        />
        <label class="form-label" for="create-speaker-adresse1">Adresse</label>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="create-speaker-adresse2"
          placeholder=""
          formControlName="adresse2"
          (change)="this.updatePristineStatus()"
        />
        <label class="form-label" for="create-speaker-adresse2"
          >Complément d'adresse</label
        >
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-4">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="create-speaker-codepostal"
          placeholder=""
          formControlName="codepostal"
          (change)="this.updatePristineStatus()"
        />
        <label class="form-label" for="create-speaker-codepostal"
          >Code postal</label
        >
      </div>
    </div>
    <div class="col-8">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="create-speaker-ville"
          placeholder=""
          formControlName="ville"
          (change)="this.updatePristineStatus()"
        />
        <label class="form-label" for="create-speaker-ville">Ville</label>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-6">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="create-speaker-telephone"
          placeholder=""
          formControlName="telephone"
          (change)="this.updatePristineStatus()"
        />
        <label class="form-label" for="create-speaker-telephone"
          >Téléphone</label
        >
      </div>
    </div>
    <div class="col-6">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="create-speaker-mobilespeak"
          placeholder=""
          formControlName="mobilespeak"
          (change)="this.updatePristineStatus()"
        />
        <label class="form-label" for="create-speaker-mobilespeak"
          >Téléphone portable</label
        >
      </div>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-6">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="create-speaker-email"
          placeholder=""
          formControlName="email"
          (change)="this.updatePristineStatus()"
        />
        <label class="form-label" for="create-speaker-email">Email</label>
      </div>
    </div>
    <div class="col-3">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="create-speaker-codespeak"
          placeholder=""
          [value]="this.codespeak"
          disabled
        />
        <label class="form-label" for="create-speaker-codespeak">Code</label>
      </div>
    </div>
    <div class="col-3">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="create-speaker-trigramme"
          placeholder=""
          [value]="this.trigramme"
          disabled
        />
        <label class="form-label" for="create-speaker-trigramme"
          >Trigramme</label
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="!this.editSpeakerContact.pristine" class="col-12 text-end">
      <button class="btn btn-danger me-2" (click)="this.resetForm()">
        Annuler les modifications
      </button>
      <button
        class="btn btn-success"
        (click)="this.saveContactDetails()"
        [disabled]="this.isLoading"
      >
        <span *ngIf="!this.isLoading"> Enregistrer </span>
        <span *ngIf="this.isLoading" class="d-flex">
          Modification en cours
          <div class="ms-3">
            <app-loader color="#fff" />
          </div>
        </span>
      </button>
    </div>
  </div>
</div>
