<button
  class="actionbutton"
  (click)="this.onActionEvent()"
  [disabled]="this.disabled"
>
  <span class="actionbutton__icon">
    <i class="fa-solid fa-{{ this.icon }}" [ngStyle]="{color: this.iconColor}"></i>
  </span>
  <span class="actionbutton__label">{{ this.label }}</span>
</button>
