import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { StatusBadgeComponent } from '../status-badge/status-badge.component';
import { Sizes } from 'projects/speaker-platform/src/app/types/sizes.enum';
import { PlanningEventReasons } from '../../types/planning-event-reasons.enum';
import { Constants } from '@common/config/constants';

@Component({
  standalone: true,
  imports: [CommonModule, StatusBadgeComponent],
  selector: 'app-user-picture',
  templateUrl: './user-picture.component.html',
  styleUrls: ['./user-picture.component.scss'],
})
export class UserPictureComponent {
  constructor(protected constants: Constants){}
  displayStatus: boolean = true;
  @Input() picture: string = '';
  @Input() collapsed: boolean = false;
  @Input() status!: PlanningEventReasons;
  @Input() size: Sizes = Sizes.DEFAULT;
  @Input() firstName: string = '';
  @Input() lastName: string = '';
  @Input() codespeak: string = '';
}
