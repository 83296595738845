import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeakerListItemComponent } from 'projects/speaker-platform/src/app/molecules/speaker-list-item/speaker-list-item.component';
import { Speaker } from 'projects/speaker-platform/src/app/types/speaker';
import { PlanningEventReasons } from '../../types/planning-event-reasons.enum';
import { AtsappService } from '@common/services/atsapp.service';
import { format, isWithinInterval, startOfToday } from 'date-fns';
import { PlanningEvent } from '../../types/planning-event';
import { enUS } from 'date-fns/locale';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-speakers-list',
  standalone: true,
  imports: [CommonModule, SpeakerListItemComponent],
  templateUrl: './speakers-list.component.html',
  styleUrls: ['./speakers-list.component.scss'],
})
export class SpeakersListComponent implements OnInit, OnDestroy {
  constructor(private atsappService: AtsappService) {}
  planningEventReasons = PlanningEventReasons;
  todayEvents: any[] = [];

  planningEventsLoaded: boolean = false;

  today = startOfToday();

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() speakersList: any[] = [];
  @Input() selected: number = 0;
  @Output() selectEvent = new EventEmitter<any>();

  filteredSpeakersList: any[] = [];

  ngOnInit(): void {
    this.atsappService.getAllPlanningEvents()
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (res: any) => {
        const todayEvents = res.filter((event: PlanningEvent) =>
          isWithinInterval(this.today, {
            start: new Date(event.date_debut).setHours(0, 0),
            end: new Date(event.date_fin).setHours(0, 0),
          })
        );

        todayEvents.forEach((event: PlanningEvent) => {
          this.todayEvents.push({
            speakerId: Number(event.comedienne_id.id),
            statut: event.raison,
          });
        });
      },
      complete: () => (this.planningEventsLoaded = true),
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onSelect(spk: Speaker) {
    this.selectEvent.emit(spk);
  }

  getStatus(spk: Speaker): PlanningEventReasons {
    const f = this.todayEvents.findIndex(
      (event: any) => event.speakerId === spk.id
    );
    if (f !== -1) {
      return this.todayEvents[f].statut;
    }
    if (
      spk.disponibilite && !spk.disponibilite.includes(format(this.today, 'EEE', { locale: enUS }))
    ) {
      return this.planningEventReasons.NO_SEANCE;
    }

    if (!spk.disponibilite) return this.planningEventReasons.NO_SEANCE;

    return this.planningEventReasons.SEANCE;
  }
}
