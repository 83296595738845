import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TextsToSendComponent } from './pages/admin/texts-to-send/texts-to-send.component';
import { AdminDashboardComponent } from './pages/admin/admin-dashboard/admin-dashboard.component';
import { TextsSentComponent } from './pages/admin/texts-sent/texts-sent.component';
import { LoginComponent } from './pages/login/login.component';
import { TextsToTranslateComponent as AdminTextsToTranslateComponent } from './pages/admin/texts-to-translate/texts-to-translate.component';
import { TextsStandbyComponent } from './pages/admin/texts-standby/texts-standby.component';
import { TextsToRecordComponent } from './pages/texts-to-record/texts-to-record.component';
import { TextsToTranslateComponent } from './pages/texts-to-translate/texts-to-translate.component';
import { MessagerieComponent } from './pages/admin/messagerie/messagerie.component';
import { TextsTranslateSentComponent } from './pages/admin/texts-translate-sent/texts-translate-sent.component';
import { SeancesOperationComponent } from './pages/admin/seances-operation/seances-operation.component';
import { SeancesUploadComponent } from './pages/seances-upload/seances-upload.component';
import { SpeakerPlanningComponent } from './pages/speaker-planning/speaker-planning.component';
import { GlobalPlanningComponent } from './pages/admin/global-planning/global-planning.component';
import { SpeakerCreateComponent } from './pages/admin/speaker-create/speaker-create.component';
import { SpeakerEditComponent } from './pages/admin/speaker-edit/speaker-edit.component';
import { PasswordResetRequestComponent } from './pages/password-reset-request/password-reset-request.component';
import {PasswordResetComponent} from "./pages/password-reset/password-reset.component";

export const appRouteList: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'LOGIN', fullPage: true },
  },
  {
    path: 'reinitialiser-mdp',
    component: PasswordResetRequestComponent,
    data: {title: 'LOGIN', fullPage: true}
  },
  {
    path: 'reset-password/:token/:organisation',
    component: PasswordResetComponent,
    data: {title: 'LOGIN', fullPage: true}
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { title: 'Dashboard', fullPage: false },
  },
  {
    path: 'textes-a-enregistrer',
    component: TextsToRecordComponent,
    data: { title: 'Mes textes à enregistrer', fullPage: false },
  },
  {
    path: 'textes-a-traduire',
    component: TextsToTranslateComponent,
    data: { title: 'Mes textes à traduire', fullPage: false },
  },
  {
    path: 'livrer-des-enregistrements',
    component: SeancesUploadComponent,
    data: { title: 'Livrer des enregistrements', fullPage: false },
  },
  {
    path: 'planning',
    component: SpeakerPlanningComponent,
    data: { title: 'Mon planning', fullPage: false },
  },
  {
    path: 'admin',
    children: [
      {
        path: 'dashboard',
        component: AdminDashboardComponent,
        data: { title: 'Dashboard', fullPage: false },
      },
      {
        path: 'messagerie',
        component: MessagerieComponent,
        data: { title: 'Messagerie', fullPage: false },
      },
      {
        path: 'planning',
        component: GlobalPlanningComponent,
        data: { title: 'Planning général', fullPage: true },
      },
      {
        path: 'planning/:id',
        component: SpeakerPlanningComponent,
        data: { title: 'Planning personnel', fullPage: false },
      },
      {
        path: 'gestion-prises-voix',
        data: { title: 'Textes à envoyer', fullPage: true },
        children: [
          {
            path: 'textes-a-envoyer',
            component: TextsToSendComponent,
            data: { title: 'Textes à envoyer', fullPage: true },
          },
          {
            path: 'textes-envoyes',
            component: TextsSentComponent,
            data: { title: 'Textes envoyés', fullPage: true },
          },
          {
            path: 'textes-en-standby',
            component: TextsStandbyComponent,
            data: { title: 'Textes en standby', fullPage: true },
          },
        ],
      },
      {
        path: 'gestion-seances',
        data: { title: 'Gestion des séances', fullPage: false },
        component: SeancesOperationComponent,
      },
      {
        path: 'gestion-traductions',
        data: { title: 'Gestion des traductions', fullPage: true },
        children: [
          {
            path: 'textes-a-envoyer',
            component: AdminTextsToTranslateComponent,
            data: { title: 'Textes à traduire', fullPage: true },
          },
          {
            path: 'textes-envoyes',
            component: TextsTranslateSentComponent,
            data: { title: 'Textes à traduire envoyés', fullPage: true },
          },
        ],
      },
      {
        path: 'creer-profil',
        component: SpeakerCreateComponent,
        data: { title: 'Création comédien·ne', fullPage: false },
      },
      {
        path: 'modifier-profil',
        component: SpeakerEditComponent,
        data: { title: 'Gestion des comédien·ne·s', fullPage: false },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRouteList)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
