<div class="page container textstorecord">
  <div
    *ngIf="this.seances.length > 0; else noSeances"
    class="d-flex align-items-center justify-content-between mb-4"
  >
    <div class="d-flex align-items-center">
      <div class="form-floating">
        <select
          #seanceSelect
          class="select form-select"
          placeholder=""
          (change)="this.getPvsBySeance(parseInt(this.seanceSelect.value))"
        >
          <option *ngFor="let seance of this.seances" [value]="seance.id">
            Séance {{ seance.langue }} du
            {{ seance.date_creation | date : "dd/MM/YYYY" }}
          </option>
        </select>
        <label for="">Séance</label>
      </div>

      <div class="ms-3">
        <div class="text-info">
          {{ this.selectedSeance.compteur_done }} texte{{
            this.selectedSeance.compteur_done > 1 ? "s" : ""
          }}
          livré{{ this.selectedSeance.compteur_done > 1 ? "s" : "" }} sur
          {{ this.selectedSeance.compteur_todo }}
        </div>
        <div *ngIf="this.standByPV.length > 0" class="text-danger">
          {{ this.standByPV.length }} texte{{ this.standByPV.length > 1 ? "s" : "" }} en
          standby
        </div>
      </div>
    </div>
    <div>
      <button
        class="btn btn-primary me-3"
        (click)="this.readerDialogVisible = true"
      >
        <i class="fa-solid fa-glasses"></i>
        Mode lecture
      </button>
      <button class="btn btn-primary" (click)="this.savePDF()">
        <i class="fa-solid fa-file-pdf"></i> Télécharger le PDF la séance
      </button>
    </div>
  </div>
  <ng-template #noSeances>
    <div *ngIf="!this.isFetching">Aucun texte à enregistrer pour le moment</div>
  </ng-template>

  <div *ngIf="this.isFetching" class="textstorecord__loader">
    <app-loader />
  </div>
  <app-texts-list
    *ngIf="!this.isFetching"
    [textsList]="this.assignedPVs"
    [todo]="this.textsTodo.RECORD"
    [phasesVocales]="this.phasesVocalesList"
    [standbyTypes]="this.standbyTypes"
    (showCommentDialogEvent)="this.onShowCommentDialog($event)"
    (showStandbyDialogEvent)="this.onShowStandbyDialog($event)"
  />

  <app-dialog
    #standbyDialog
    title="Mettre en standby"
    [visibility]="this.standbyDialogVisible"
    (closeDialogEvent)="this.standbyDialogVisible = $event"
  >
    <div
      *ngIf="!this.isPosting && !this.isPostingOver && !this.postError"
      [formGroup]="this.standbyForm"
    >
      <div class="form-floating mb-2">
        <select
          [ngClass]="{ 'is-invalid': this.standbyTypeInput?.invalid }"
          name=""
          id=""
          class="form-select"
          formControlName="standbyTypeInput"
        >
          <option value="">Sélectionnez un motif</option>
          <option [value]="zby.cc_code" *ngFor="let zby of this.standbyTypes">
            {{ zby.cc_libelle }}
          </option>
        </select>
        <label for="" class="form-label">Motif</label>
      </div>
      <div class="form-floating">
        <textarea
          formControlName="standbyMessageInput"
          name=""
          id=""
          placeholder=""
          rows="10"
          class="form-control"
        ></textarea>
        <label for="" class="form-label">Commentaire</label>
      </div>
    </div>

    <app-post-states
      [isPosting]="this.isPosting"
      [isPostingOver]="this.isPostingOver"
      [postError]="this.postError"
      postingMessage="Le texte est en cours de modification"
      completeMessage="Le texte a bien été mis en standby"
    />
    <div dialog-footer>
      <div *ngIf="!this.isPosting && !this.isPostingOver && !this.postError">
        <button
          class="btn btn-success me-2"
          (click)="this.standby()"
          [disabled]="this.standbyTypeInput?.invalid || this.isPosting"
        >
          Valider
        </button>
        <button
          class="btn btn-danger"
          (click)="this.standbyDialog.closeDialog()"
        >
          Annuler
        </button>
      </div>
      <div *ngIf="this.postError">
        <button
          class="btn btn-danger me-2"
          (click)="this.standbyDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
      <div *ngIf="this.isPostingOver">
        <button
          class="btn btn-success me-2"
          (click)="this.standbyDialog.closeDialog()"
        >
          Fermer
        </button>
      </div>
    </div>
  </app-dialog>

  <app-dialog
    #commentDialog
    title="Commentaire après enregistrement"
    [visibility]="this.commentDialogVisible"
    (closeDialogEvent)="this.commentDialogVisible = $event"
  >
    <div class="form-floating">
      <textarea name="" id="" placeholder="" class="form-control"></textarea>
      <label for="">Commentaire </label>
    </div>
    <div dialog-footer>
      <button class="btn btn-success me-2">Valider</button>
      <button class="btn btn-danger" (click)="this.commentDialog.closeDialog()">
        Annuler
      </button>
    </div>
  </app-dialog>

  <app-dialog
    *ngIf="this.selectedSeance"
    title="Séance {{ this.selectedSeance.id }} du
        {{ this.selectedSeance.date_creation | date : 'dd/MM/YYYY' }}"
    [visibility]="this.readerDialogVisible"
    (closeDialogEvent)="this.readerDialogVisible = $event"
    [largeContent]="true"
    [customWidth]="1100"
  >
    <div *ngFor="let text of this.assignedPVs" class="mb-4">
      <div class="textstorecord__readermode">
        <div class="textstorecord__readermode__text">
          <h6>{{ text.ZMS_PVCODE1 }}</h6>
          {{ text.ZMS_PVTEXTE }}
        </div>
        <div class="textstorecord__readermode__phonetic">
          <h6>Phonétique / Consignes</h6>

          <div *ngIf="text.ZMS_PVPHONETIQUE !== ''; else noPhonetic">
            {{ text.ZMS_PVPHONETIQUE }}
          </div>
          <ng-template #noPhonetic>
            <div class="textstorecord__readermode__nophonetic">
              Pas d'indication de phonétique ou de consignes
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </app-dialog>
</div>
