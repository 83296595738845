<div
  *ngIf="this.pageTitle !== 'LOGIN'"
  class="app"
  [ngClass]="{ 'app--full-page': this.fullPage }"
>
  <div
    class="app__sidebar"
    [ngClass]="{ 'app__sidebar--expanded': this.sidebarCollapsed }"
    *ngIf="this.userService.user"
  >
    <app-sidebar
      [collapsed]="this.fullPage"
      [fullPage]="this.fullPage"
      [pageTitle]="this.pageTitle"
      [userData]="this.userService.user"
      [userStatus]="this.userService.userStatus"
      [userRoles]="this.userService.userRoles"
      (toggleCollapse)="this.sidebarCollapsed = $event"
    />
  </div>
  <div class="app__pagetitle">
    <h1>{{ this.pageTitle }}</h1>
  </div>
  <div
    class="app__content"
    [ngClass]="{ 'app__content--full-page': this.fullPage }"
  >
    <router-outlet *ngIf="this.userService.user"></router-outlet>
  </div>
</div>

<div class="applogin" *ngIf="this.pageTitle === 'LOGIN'">
  <router-outlet></router-outlet>
</div>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
