<ul class="seanceslist">
  <li
    class="seanceslist__item"
    [ngClass]="{
      'seanceslist__item--selected': seance.id === this.selectedSeance
    }"
    *ngFor="let seance of this.seancesVocal"
    (click)="this.onSelectSeance(seance)"
  >
    <span>
      {{ seance.langue }} - {{ seance.date_creation | date : "dd/MM/yyyy" }}
    </span>
    <span class="seanceslist__item__count">
      <i class="fa-solid fa-file-text"></i>
      {{ seance.compteur_todo - seance.compteur_done }}
    </span>
  </li>
</ul>
