import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextsListFiltersComponent } from 'projects/speaker-platform/src/app/organisms/admin/texts-list-filters/texts-list-filters.component';
import { PaginationComponent } from 'projects/speaker-platform/src/app/molecules/pagination/pagination.component';
import { TextsMenuComponent } from 'projects/speaker-platform/src/app/molecules/texts-menu/texts-menu.component';
import { TextsTodo } from 'projects/speaker-platform/src/app/types/texts-todo.enum';
import { AbstractTextComponent } from '../../../common/abstract/abstract-texts.component';
import { AtsappService } from '@common/services/atsapp.service';
import { DateTransformService } from 'projects/speaker-platform/src/app/services/helpers/date-transform.service';
import { FiltersProps } from 'projects/speaker-platform/src/app/types/filters';
import { takeUntil } from 'rxjs';
import { TextsActions } from 'projects/speaker-platform/src/app/types/texts-actions.enum';
import { Speaker } from '../../../types/speaker';
import { CcLibelleService } from '../../../services/helpers/cc-libelle-service.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TruncatePipe } from '../../../pipes/truncate.pipe';
import { LoaderComponent } from '@common/components/loader/loader.component';
import {CommonModalService} from "../../../services/helpers/common-modal.service";
import {TextFocusComponent} from "../../../organisms/text-focus/text-focus.component";

@Component({
  selector: 'app-texts-standby',
  standalone: true,
  imports: [
    CommonModule,
    TextsListFiltersComponent,
    PaginationComponent,
    TextsMenuComponent,
    NgbTooltip,
    TruncatePipe,
    LoaderComponent,
    TextFocusComponent,
  ],
  templateUrl: './texts-standby.component.html',
  styleUrls: ['../../../organisms/admin/texts-list/texts-list.component.scss'],
})
export class TextsStandbyComponent
  extends AbstractTextComponent
  implements OnInit, AfterViewChecked
{
  constructor(
    atsappService: AtsappService,
    dateTransformService: DateTransformService,
    ccLibelleService: CcLibelleService,
    private cdr: ChangeDetectorRef,
    private commonModalService: CommonModalService
  ) {
    super(atsappService, dateTransformService, ccLibelleService);
  }

  @ViewChild('textsListHeader') textsListHeader!: ElementRef;

  smartSelectEnabled: boolean = false;
  textsTodo = TextsTodo;
  selectedSpeaker!: Speaker;
  page: number = 1;
  limit: number = 10;
  collectionSize: number = 0;
  actions = TextsActions;
  standbyPVs: any = [];
  utilisats: any[] = [];

  filters: any[] = [];

  postObjectKeys = [];

  textsListHeaderHeight: string = '';

  showFocusModalFromStandBy: boolean = false;

  ngOnInit(): void {
    this.getPhasesVocale();
    this.getTypesMessage();
    this.getLangues();
    this.getSpeakers();

    this.atsappService.getUtilisats().subscribe({
      next: (res: any) => (this.utilisats = res),
      error: (error) => console.error(error),
      complete: () => console.info('complete'),
    });
  }

  ngAfterViewChecked(): void {
    this.textsListHeaderHeight =
      this.textsListHeader.nativeElement.clientHeight + 'px';
    this.cdr.detectChanges();
  }

  getStandbyPVs(filters: any = this.filters) {
    this.isLoading = true;
    this.filters = filters;
    this.atsappService
      .getStandbyPVs(this.filters, this.page, this.limit)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.standbyPVs = res.standby_PVs;
        this.collectionSize = parseInt(res.total);
        this.isLoading = false;
      });
  }


  showFocusStandByText(text: any) {
    this.commonModalService.showModalFocus.subscribe((show) => {
      this.showFocusModalFromStandBy = show;
    })
    this.commonModalService.openFocusText(text)
  }

  getCreatorName(trigramme: string) {
    return this.utilisats.filter((u: any) => u.us_utilisateur === trigramme)[0]
      .us_libelle;
  }
}
