import {Injectable} from '@angular/core';
import {HttpHeaders, HttpErrorResponse} from '@angular/common/http';

import {Router} from '@angular/router';
import {ToastService} from "../../speaker-platform/src/app/services/toast.service";
import {ms} from "date-fns/locale";

@Injectable({
  providedIn: 'root'
})

export abstract class AbstractService {

  protected constructor(
    private toastService: ToastService,
    protected router: Router,
  ) {
  }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  handleError(error: HttpErrorResponse): Array<any> {
    console.log(error)
    let msg: string = '';

    if (error.status === 412) {
      if (Object.getPrototypeOf(error.error).constructor.name == 'Blob') {
        error.error.text().then((json: string) => {
          const err = JSON.parse(json);
          msg = err.message;
          this.toastService.show(msg, 'danger');
        });
      } else {
        msg = error.error.message;
        this.toastService.show(msg, 'danger');
      }

      // console.clear();
    } else if (error.status === 401) {
      msg = 'Merci de vous authentifier';
      console.log(msg)
      this.toastService.show(msg, 'danger');
      localStorage.removeItem('jwt')
      this.router.navigate(['/login']);
    } else if (error.status === 403) {
      msg = 'Vous n\'avez pas les droits nécessaires pour cette page';
      this.toastService.show(msg, 'danger');
    } else {
      if (error.error instanceof ErrorEvent) {
        //erreur client ou réseau (selon doc angular)
        msg = error.error.message
        this.toastService.show(msg, 'danger');
      } else {
        //erreur côté serveur
        msg = (!!error.error && !!error.error.message) ? error.error.message : error.message;
        this.toastService.show(msg, 'danger');

      }
    }
    return [{status: error.status, statusText: error.statusText, errorMessage: msg}];
  }

}





















