import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class DateTransformService {
  constructor() {}

  transformDate(date: NgbDateStruct, hour: NgbTimeStruct | null = {hour: 0, minute: 0, second: 0}): string {
    const dateObj =
      date && new Date(Date.UTC(date.year, date.month - 1, date.day, hour?.hour, hour?.minute, hour?.second));
    const formatedDate = dateObj
      ? dateObj.toISOString().replace('T', ' ').replace('Z', '')
      : '';
    return formatedDate;
  }
}
