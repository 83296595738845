import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeancesListComponent } from 'projects/speaker-platform/src/app/organisms/admin/seances-list/seances-list.component';
import { AtsappService } from '@common/services/atsapp.service';
import { AbstractSeancesComponent } from 'projects/speaker-platform/src/app/common/abstract/abstract-seances.component';
import { SeanceStatuses } from 'projects/speaker-platform/src/app/types/seanceStatuses.enum';
import { DialogComponent } from 'projects/speaker-platform/src/app/molecules/dialog/dialog.component';
import { LoaderComponent } from '@common/components/loader/loader.component';
import { PostStatesComponent } from 'projects/speaker-platform/src/app/molecules/post-states/post-states.component';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { takeUntil } from 'rxjs';
import { ToastService } from '../../../services/toast.service';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { Seance } from '../../../types/seance';
import { NgbDateCustomParserFormatter } from '@common/services/ngb-date-formatter.service';
import { format, subBusinessDays } from 'date-fns';
import { SpeakerSelectorComponent } from '../../../molecules/admin/speaker-selector/speaker-selector.component';

@Component({
  selector: 'app-seances-operation',
  standalone: true,
  imports: [
    CommonModule,
    SeancesListComponent,
    DialogComponent,
    LoaderComponent,
    PostStatesComponent,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    SpeakerSelectorComponent,
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
  templateUrl: './seances-operation.component.html',
  styleUrls: ['./seances-operation.component.scss'],
})
export class SeancesOperationComponent
  extends AbstractSeancesComponent
  implements OnInit, OnDestroy
{
  constructor(
    atsappService: AtsappService,
    private fb: FormBuilder,
    private toastService: ToastService
  ) {
    super(atsappService);
  }

  seanceStatuses = SeanceStatuses;
  reassignDialogVisible: boolean = false;
  cancelDialogVisible: boolean = false;
  validateDialogVisible: boolean = false;
  ungenerateDialogVisible: boolean = false;
  editorDialogVisible: boolean = false;
  remasterValidDialogVisible: boolean = false;
  remasterRejectDialogVisible: boolean = false;
  seanceToPost!: number;
  ungenerateReason: string = '';
  selectedSeance!: Seance;

  stateFilter: string |null = null;

  uploadCurrentSizeUploaded: number = 0;

  newSpeaker: string = '';

  selectedEditor: string = '';
  editors: any[] = [];

  resetFilterEvent: EventEmitter<any> = new EventEmitter<any>();

  isPosting: boolean = false;
  isPostingOver: boolean = false;
  postError: { errorCode: number; message: string } | null = null;

  mode: string = 'ALL';
  startDate: NgbDateStruct | null = null;
  endDate: NgbDateStruct | null = null;

  postBody: any = {
    CODE_COMEDIENNE: 'XXX',
    PRISES_VOIX: new Array(),
  };

  filters = this.fb.group({
    speak: new FormControl<string | null>(null),
    dateCreationMin: new FormControl<NgbDateStruct | null>(null),
    dateCreationMax: new FormControl<NgbDateStruct | null>(null),
    mode: new FormControl<string | null>('VOCAL'),
    statut: new FormControl<string | null>(this.seanceStatuses.AWAITING_CUTOUT),
    etat: new FormControl<string | null>(null),
    editeur: new FormControl<string | null>(''),
  });

  ngOnInit(): void {
    // this.filters.controls['dateCreationMin'].setValue(
    //   this.getLastBusinessDay()
    // );
    this.search();
    this.getSpeakers();
    this.invisibleSeancesFilter = 'ANNULE';

    this.atsappService.getEditeurs().subscribe({
      next: (res: any) => {
        this.editors = res;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  formatDateFilter(date: NgbDateStruct | null): string | null {
    if (date) {
      return format(
        new Date(`${date.year}-${date.month}-${date.day}`),
        'yyyy-MM-dd'
      );
    }
    return null;
  }

  getLastBusinessDay(): NgbDateStruct {
    const lastBusinessDay = subBusinessDays(new Date(), 1);
    return {
      year: lastBusinessDay.getFullYear(),
      month: lastBusinessDay.getMonth() + 1,
      day: lastBusinessDay.getDate(),
    };
  }

  onReassignSeance(id: number) {
    this.postError = null;
    this.isPosting = false;
    this.isPostingOver = false;
    this.reassignDialogVisible = true;
    this.seanceToPost = id;
  }

  onCancelSeance(id: number) {
    this.postError = null;
    this.isPosting = false;
    this.isPostingOver = false;
    this.cancelDialogVisible = true;
    this.seanceToPost = id;
  }

  onUngenerateSeance(body: any) {
    this.postError = null;
    this.isPosting = false;
    this.isPostingOver = false;
    this.ungenerateReason = '';
    this.postBody = body;
    this.ungenerateDialogVisible = true;
  }

  onValidateSeance(body: any) {
    this.postError = null;
    this.isPosting = false;
    this.isPostingOver = false;
    this.postBody = body;
    this.validateDialogVisible = true;
  }

  onGenerateSeance(event: any) {
    this.uploadCurrentSizeUploaded = 0;
    this.isPosting = true;
    this.atsappService
      .generatePV(event.formData, event.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (event) => {
          if (event?.type === 1 && event.total) {
            this.uploadCurrentSizeUploaded = (event.loaded * 100) / event.total;
          }
        },
        error: (error) => {
          this.playErrorSound();
          this.postError = {
            errorCode: error.error.code,
            message: error.error.message,
          };
          this.isPosting = false;
          this.toastService.show(
            "Il y a eu une erreur lors de l'intégration de la séance",
            'danger'
          );
        },
        complete: () => {
          this.postError = null;
          this.isPostingOver = true;
          this.isPosting = false;
          this.playSuccessSound();
          this.search();
          this.toastService.show(
            'La séance à été intégrée avec succès',
            'success'
          );
        },
      });
  }

  isAllReasonsFilled(): boolean {
    return this.postBody.PRISES_VOIX.every((pv: { MOTIF_REJET: any; }) => pv.MOTIF_REJET);
  }

  reassignSeance() {
    this.isPosting = true;
    this.isPostingOver = false;
    this.atsappService
      .reassignSeance(this.seanceToPost, this.newSpeaker)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.postError = {
            errorCode: error.error.code,
            message: error.error.message,
          };
          this.isPosting = false;
          this.playErrorSound();
        },
        complete: () => {
          this.postError = null;
          this.isPosting = false;
          this.isPostingOver = true;
          this.search();
          this.playSuccessSound();
        },
      });
  }

  cancelSeance() {
    this.isPosting = true;
    this.isPostingOver = false;
    this.atsappService
      .cancelSeance(this.seanceToPost)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.postError = {
            errorCode: error.error.code,
            message: error.error.message,
          };
          this.isPosting = false;
          this.playErrorSound();
        },
        complete: () => {
          this.postError = null;
          this.isPosting = false;
          this.isPostingOver = true;
          this.search();
          this.playSuccessSound();
        },
      });
  }

  ungenerateSeance(notify: boolean = false) {
    this.isPosting = true;
    this.isPostingOver = false;
    this.postBody.email = notify;

    this.atsappService
      .ungeneratePV(this.postBody, this.selectedSeance.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.postError = {
            errorCode: error.error.code,
            message: error.error.message,
          };
          this.isPosting = false;
          this.playErrorSound();
        },
        complete: () => {
          this.postError = null;
          this.isPosting = false;
          this.isPostingOver = true;
          this.playSuccessSound();
          this.search();
        },
      });
  }

  validateSeance(notify: boolean = false) {
    this.isPosting = true;
    this.isPostingOver = false;
    this.postBody.email = notify;
    this.atsappService
      .validatePV(this.postBody, this.selectedSeance.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          this.postError = {
            errorCode: error.error.code,
            message: error.error.message,
          };
          this.isPosting = false;
          this.playErrorSound();
        },
        complete: () => {
          this.postError = null;
          this.isPosting = false;
          this.isPostingOver = true;
          this.playSuccessSound();
          this.search();
        },
      });
  }

  onOpenAssignEditor(seance: any) {
    this.selectedEditor = '';
    this.selectedSeance = seance;
    this.editorDialogVisible = true;
    this.isPostingOver = false;
    this.postError = null
  }

  assignEditor(editor: string, seanceId: number) {
    this.isPosting = true;
    this.isPostingOver = false;
    this.atsappService.assignEditorToSeance(seanceId, editor).subscribe({
      complete: () => {
        this.postError = null;
        this.isPostingOver = true;
        this.isPosting = false;
        this.playSuccessSound();
        this.search();
      },
      error: (error) => {
        this.postError = {
          errorCode: error.error.code,
          message: error.error.message,
        };
        this.isPosting = false;
      },
    });
  }

  onOpenRemaster(id: number) {
    this.selectedEditor = '';
    this.remasterValidDialogVisible = true;
    this.isPostingOver = false;
    this.seanceToPost = id;
  }

  onRejectMasteringText(body: any) {
    this.postError = null;
    this.isPosting = false;
    this.isPostingOver = false;
    this.remasterRejectDialogVisible = true;
    this.postBody = body;
  }

  remasterSeance() {
    this.isPosting = true;
    this.isPostingOver = false;
    this.atsappService.validateRemasterSeance(this.seanceToPost).subscribe({
      complete: () => {
        this.postError = null;
        this.isPostingOver = true;
        this.isPosting = false;
        this.playSuccessSound();
        this.search();
      },
      error: (error) => {
        this.postError = {
          errorCode: error.error.code,
          message: error.error.message,
        };
        this.isPosting = false;
      },
    });
  }

  resetIfNull(formControl: string) {
    if (this.filters.get(formControl)?.getRawValue() === 'null') {
      this.filters.get(formControl)?.reset();
    }
  }

  reset() {
    this.filters.reset();
    this.resetFilterEvent.emit();
  }

  search() {
    const body: any = { ...this.filters.value };
    if (body.editeur === "" || this.filters.controls['statut'].value !== this.seanceStatuses.ONGOING_CUTOUT) {
      body.editeur = null;
    }
    this.stateFilter = this.filters.controls['statut'].value
    body['dateCreationMin'] = this.formatDateFilter(
      this.filters.controls['dateCreationMin']?.value
    );

    body['dateCreationMax'] = this.formatDateFilter(
      this.filters.controls['dateCreationMax']?.value
    );

    this.getAllSeances(
      body,
      this.filters.controls['statut'].value ===
        this.seanceStatuses.ONGOING_CUTOUT && this.filters.controls['editeur'].value !== ''
        ? this.filters.controls['editeur'].value
        : null
    );
  }
}
